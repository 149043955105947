import React, { useState, MouseEvent } from "react";
import ReactMarkdown from 'react-markdown';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { PRIVACY_POLICY_V1, USER_AGREEMENT_V1 } from "../../configs/UserPolicies";

interface PolicyProps {
  isOpen: boolean;
  title: string;
  content: string;
}

const UserPolicy: React.FC = () => {
  const [policyModal, setPolicyModal] = useState<PolicyProps>({
    isOpen: false,
    title: "",
    content: "",
  });

  const handleModalOpen = (event: MouseEvent<HTMLAnchorElement>, policyType: string): void => {
    event.preventDefault();

    const policies: Record<string, PolicyProps> = {
      user_agreement: {
        isOpen: true,
        title: "User Agreement",
        content: USER_AGREEMENT_V1,
      },
      privacy_policy: {
        isOpen: true,
        title: "Privacy Policy",
        content: PRIVACY_POLICY_V1,
      },
    };

    setPolicyModal(policies[policyType] || { isOpen: false, title: "", content: "" });
  };

  const closeModal = (): void => {
    setPolicyModal({ isOpen: false, title: "", content: "" });
  };

  return (
    <>
      <IonItem lines="none" className={"ion-no-border ion-no-margin"}>
        <IonLabel className="ion-text-center" style={{ fontSize: "small"}}>
          By continuing, you agree to our{" "}
          <a href="/" onClick={(e) => handleModalOpen(e, "user_agreement")}>
            User Agreement
          </a>{" "}
          and{" "}
          <a href="/" onClick={(e) => handleModalOpen(e, "privacy_policy")}>
            Privacy Policy
          </a>
          .
        </IonLabel>
      </IonItem>
      <IonModal isOpen={policyModal.isOpen} onDidDismiss={closeModal}>
        <IonToolbar>
          <IonButtons slot="end" onClick={closeModal}>
            <IonButton>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding">
          <ReactMarkdown>{policyModal.content}</ReactMarkdown>
        </IonContent>
      </IonModal>
    </>
  );
};

export default UserPolicy;
