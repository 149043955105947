import React from "react";
import { HexColorPicker } from "react-colorful";

import "./ColorPicker.css";

interface ColorPickerFields {
  color: string;
  onChange: (color: string) => void;
  presetColors?: string[];
  hideFreeformColorPicker?: boolean;
}

export const ColorPicker = ({ color, onChange, presetColors, hideFreeformColorPicker }: ColorPickerFields) => {
  return (
    <div className="picker">
      {!hideFreeformColorPicker && (
        <HexColorPicker color={color} onChange={onChange} />
      )}
      <div className="picker__swatches">
        {presetColors!.map((presetColor) => (
          <button
            key={presetColor}
            className="picker__swatch"
            style={{ background: presetColor }}
            onClick={() => onChange(presetColor)}
          />
        ))}
      </div>
    </div>
  );
};
