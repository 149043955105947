import "./Selector.css";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonThumbnail,
  IonToolbar
} from "@ionic/react";
import { useGetUserMedia } from "../../../hooks/MediaHooks";
import React, { useEffect, useState } from "react";
import { FlowNode } from "../../../types/FlowTypes";
import { useGetUserFlows, useSetFlowNodes } from "../../../hooks/FlowHooks";
import { buildResourceList } from "../../../helpers/ResourceHelpers";
import { ResourceItem } from "../../../types/ResourceTypes";
import { DBMedia } from "../../../types/MediaType";
import { useReactFlow } from "reactflow";
import { getCoordinatesForNewNode } from "../../../utils/FlowChartUtils";
import { useAuthContext } from "../../../providers/AuthProvider";

interface ImageSelectorProps {
  flowId?: string;
  dismiss: CallableFunction;
}

const ImageSelector = ({flowId, dismiss}: ImageSelectorProps) => {
  const {authUser} = useAuthContext();

  const mediaQuery = useGetUserMedia(authUser?.$id);
  const flowsQuery = useGetUserFlows(authUser?.$id);
  const setFlowNodes = useSetFlowNodes();

  const {getViewport} = useReactFlow();

  const [imageItems, setImageItems] = useState<ResourceItem[]>([]);
  const [flowNodeIds, setFlowNodeIds] = useState<Set<string>>(new Set());

  // Search Bar
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchText("");
  }, [])

  useEffect(() => {
    if (flowsQuery.isSuccess && flowsQuery.data) {
      const flow = flowsQuery.data.find((f) => f.$id === flowId);
      if (flow) {
        const flowNodes = flow.nodes.map((n) => JSON.parse(n) as FlowNode).filter((fn) => fn.type === "image");
        setFlowNodeIds(new Set(flowNodes.map((n) => n.targetId)));
      }
    }
  }, [flowsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (mediaQuery.isSuccess) {
      setImageItems(buildResourceList([], [], mediaQuery.data.filter((v: DBMedia) => v.type === "image") ?? []));
    }
  }, [mediaQuery.dataUpdatedAt]);

  const onOptionClick = (item: ResourceItem) => {
    flowNodeIds.add(item.id);
    addNewImage(item);
  }

  const addNewImage = (item: ResourceItem) => {
    const selectedMediaId = item.id;
    if (mediaQuery.isSuccess && mediaQuery.data) {
      const mediaId = selectedMediaId.split("-")[0];

      const {x: canvasX, y: canvasY} = getCoordinatesForNewNode(getViewport())

      const newFlowNode = {
        type: "image",
        targetId: mediaId,
        x: canvasX,
        y: canvasY,
        creationTime: new Date().toISOString(),
      }

      if (flowsQuery.isSuccess && flowsQuery.data) {
        console.debug(newFlowNode);

        const currentFlowNodes: FlowNode[] = flowsQuery.data.find((f) => f.$id === flowId)!.nodes.map((n) => JSON.parse(n) as FlowNode);

        setFlowNodes.mutate({flowId: flowId!, flowNodes: [...currentFlowNodes, newFlowNode]}, {
          onSuccess: (v) => dismiss(),
        });
      }
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar mode={"ios"} placeholder={"Search Image Descriptions"} value={searchText}
                        onIonInput={(e) => setSearchText(e.detail.value!)}
                        style={{paddingTop: "15px"}}/>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollY={true}>
        <IonList>
          {imageItems
            .filter((item: ResourceItem) => !flowNodeIds.has(item.id)) // don't include it if it's already in the flow
            .filter((item: ResourceItem) => {
              if (searchText && searchText !== "") {
                return item.metadata?.includes(searchText.toLowerCase());
              } else {
                return true;
              }
            })
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map((item: ResourceItem) => (
              <IonItem key={item.id} onClick={() => onOptionClick(item)}>
                <IonThumbnail slot="start" className="centered-thumbnail small-thumbnail">
                  <picture>
                    {item.thumbnail?.webp_url &&
                        <source srcSet={`${item.thumbnail?.webp_url}`} type="image/webp"/>
                    }
                    <img src={`${item.thumbnail?.url}`} alt={item.title}/>
                  </picture>
                  {/*<IonIcon icon={item.icon} size={"large"}/> */}
                </IonThumbnail>
                <div className="label-container">
                  <IonLabel style={{fontSize: "x-small"}}>{new Date(item.createdAt).toLocaleDateString()}</IonLabel>
                  <IonLabel class="ion-text-wrap">{item.subtitle}</IonLabel>
                </div>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </>
  )
}

export default ImageSelector;