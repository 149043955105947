import { Edge, Node } from "reactflow";
import {Models} from "appwrite";

// Handling Subflow: https://reactflow.dev/docs/guides/sub-flows/
export interface FlowParent {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface FlowNode {
  type: string; // note, image, youtube, video, flow
  targetId: string;
  x: number;
  y: number;
  creationTime?: string;
  comments?: string; // add comments to the node; we should wait for relationships for this
  color?: string; // hex code to accent the flow
  label?: string; // custom label for the node
  thumbnailId?: string; // override default thumbnail
  parentId?: string; // handle subflows
  isExpanded?: boolean; // expands the card to show more details in notes
}

export interface FlowConnection {
  id: string;
  source: string;
  target: string;
  creationTime?: string;
  label?: string;
  color?: string;
}

export interface FlowFields {
  name: string;
  description?: string;
  ownerId: string;
  nodes: Array<string>; // Stringified JSON of FlowNode
  connectionData: Array<string> // Stringified JSON of FlowConnection
  tags: Array<string>; // #xxxxxx_name
  parentNodes?: Array<string>; // Stringified JSON of FlowParent
}

export type DBFlow = FlowFields & Models.Document;

export const mapNodeToFlowNode = (n: Node): FlowNode => {
  // TODO: Handle other Node Types
  // Convention is to use ${noteId}-${noteSection} in the node since
  // IDs have to be unique. So, we need to strip this out of the ID
  let nodeId = n.id;
  /*
  if (noteId.includes("-")) {
    noteId = noteId.split("-")[0];
  }
  */

  let type = "note";
  if (n.type === "flowNote") {
    type = "note";
  } else if (n.type === "flowImage") {
    type = "image";
  } else if (n.type === "flowYoutube") {
    type = "youtube";
  } else if (n.type === "flowFlow") {
    type = "flow"
  } else if (n.type === "flowVideo") {
    type = "video"
  } else if (n.type === "flowText") {
    type = "text";
  }

  const flowNode: FlowNode = {
    targetId: nodeId,
    type: type,
    x: n.positionAbsolute !== undefined ? n.positionAbsolute.x : n.position.x,
    y: n.positionAbsolute !== undefined ? n.positionAbsolute.y : n.position.y,
    creationTime: n.data.creationTime ? n.data.creationTime : undefined,
  };

  // Iterate through the fields in the Node object and include them in FlowNode
  for (const field in n.data) {
    // Exclude fields that are already handled in the initial mapping
    if (!["id", "type", "positionAbsolute", "position"].includes(field)) {
      // @ts-ignore
      flowNode[field] = n.data[field];
    }
  }

  return flowNode;
};

export const mapEdgeToFlowConnection = (edge: Edge): FlowConnection => {
  return {
    id: edge.id,
    source: edge.source,
    target: edge.target,
    label: edge.data.label ? edge.data.label.toString() : undefined,
    color: edge.style?.stroke ? edge.style.stroke : undefined,
    creationTime: edge.data.creationTime ? edge.data.creationTime : undefined,
  }
}