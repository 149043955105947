import React from "react";
import {useQueries} from "react-query";
import {DEFAULT_NOTES_STALE_TIME} from "../configs/Constants";
import {getUserFlows, getUserNotes} from "../helpers/AppwriteDatabaseHelper";
import {GroupedTag, TaggableType, TaggedResource, unmarshalTag, UNTAGGED_TAG} from "../types/TagTypes";
import {useArrayMemo} from "../utils/HookUtils";


export const useGetTags = (userId: string) => {
  const results = useQueries([
    {
      queryKey: ["notes"],
      queryFn: () => getUserNotes(userId),
      staleTime: DEFAULT_NOTES_STALE_TIME,
    },
    {
      queryKey: ["flows"],
      queryFn: () => getUserFlows(userId),
      staleTime: DEFAULT_NOTES_STALE_TIME,
    },
  ]);

  const dataSets = useArrayMemo(
    results.map((result) => result.data)
  );

  const decoratedData = React.useMemo(() => {
    //console.log(results);
    // @ts-ignore
    const tagMap: Map<string, GroupedTag> = new Map();
    tagMap.set("Untagged", {tag: UNTAGGED_TAG, targets: []});

    const correlatedTypes = [TaggableType.Note, TaggableType.Flowchart];

    /* Dear future self, I'm so sorry. This is some of the ugliest code I've ever written. 
     * We're essentially taking x arrays of data from useQueries, and grouping them by tags.
     * One horrible part is that we are relying on the tags in string form as the hash key.
     */
    if (results) {
      for (let i = 0; i < results.length; i++) {
        const dataSet = results[i].data;
        if (dataSet) {
          for (let j = 0; j < dataSet?.length; j++) {
            // Resource has tags
            if (dataSet[j].tags && dataSet[j].tags.length > 0) {
              for (let k = 0; k < dataSet[j].tags.length; k++) {
                const taggedResource: TaggedResource = {
                  id: dataSet[j].$id,
                  name: dataSet[j].name,
                  type: correlatedTypes[i],
                };
                const tagData = unmarshalTag(dataSet[j].tags[k]);
                // Special handler because Appwrite stores empty strings
                if (dataSet[j].tags[k] === "") {
                  // @ts-ignore
                  tagMap.get("Untagged").targets.push(taggedResource);
                }

                // Create the tag if it doesn't exist
                else if (tagMap.get(tagData.name) === undefined) {
                  tagMap.set(tagData.name, {tag: tagData, targets: [taggedResource]});
                } else {
                  tagMap.get(tagData.name)?.targets.push(taggedResource);
                }
              }
            }
            // Resource has no tags
            else {
              const taggedResource: TaggedResource = {
                id: dataSet[j].$id,
                name: dataSet[j].name,
                type: correlatedTypes[i],
              };
              // @ts-ignore
              tagMap.get("Untagged").targets.push(taggedResource);
            }
          }
        }
      }
    }

    // console.log(tagMap);
    return tagMap;
  }, [dataSets]);

  return decoratedData;
};
