import React, { useEffect, useState } from "react";
import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonToast,
  IonToolbar
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useDeleteImage, useDeleteYoutubeVideo, useGetUserMedia, useUpdateImage } from "../hooks/MediaHooks";
import { DBMedia } from "../types/MediaType";
import { chevronBack, ellipsisHorizontal } from "ionicons/icons";
import { useHistory } from "react-router";
import ImageViewer from "../components/media/ImageViewer";
import { MAX_MEDIA_DESCRIPTION_LENGTH } from "../configs/Constants";
import YouTubeViewer from "../components/media/YouTubeViewer";
import { useAuthContext } from "../providers/AuthProvider";

const MediaDetail: React.FC = () => {
  const {authUser} = useAuthContext();
  const {id: mediaId} = useParams<{
    id: string;
  }>();

  const history = useHistory();

  const userMedia = useGetUserMedia(authUser?.$id);
  const [mediaData, setMediaData] = useState<DBMedia>();

  // User Feedback
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feedbackToast, setFeedbackToast] = useState({isOpen: false, message: "", color: ""});
  const [resetKey, setResetKey] = useState<number>(0);

  // Deletion
  const deleteMediaQuery = useDeleteImage();
  const deleteYouTubeQuery = useDeleteYoutubeVideo()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  // Update
  const updateMediaQuery = useUpdateImage();
  const [showEditDescription, setShowEditDescription] = useState<boolean>(false);
  const [mediaDescription, setMediaDescription] = useState<string>("");

  useEffect(() => {
    setResetKey(-1);
    setIsLoading(false);

    if (userMedia.isSuccess) {
      const umd = userMedia.data?.find((media) => media.$id === mediaId);
      setMediaData(umd);
      setMediaDescription(umd?.description ?? "");
    }
  }, [mediaId, userMedia.isSuccess]);

  const getDeletionConfirmText = () => {
    if (mediaData?.type === "image") {
      return `Permanently delete this image?`;
    } else if (mediaData?.type === "youtube") {
      return `This video's bookmarks will be permanently deleted!`;
    }
  };

  const handleDeleteMedia = () => {
    if (mediaData) {
      setIsLoading(true);

      const onDeleteSuccess = () => {
        console.debug(`Deleted ${mediaData?.$id}`);
        history.back();
      };

      const onDeleteSettled = () => {
        setIsLoading(false);
      };

      if (mediaData.type === "image") {
        deleteMediaQuery.mutate(mediaData, {
          onSuccess: onDeleteSuccess,
          onSettled: onDeleteSettled,
        });
      } else if (mediaData.type === "youtube") {
        deleteYouTubeQuery.mutate(mediaData, {
          onSuccess: onDeleteSuccess,
          onSettled: onDeleteSettled,
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleUpdateDescription = (newDescription: string) => {
    if (newDescription.length > MAX_MEDIA_DESCRIPTION_LENGTH) {
      // raise an error
      console.log(newDescription.length);
      setFeedbackToast({
        color: "danger",
        isOpen: true,
        message: `Description is ${newDescription.length} characters, max is ${MAX_MEDIA_DESCRIPTION_LENGTH} characters`,
      })

      return;
    }

    setIsLoading(true);
    updateMediaQuery.mutate(
      {
        mediaId: mediaData!.$id,
        mediaUpdateFields: {
          description: newDescription,
        },
      },
      {
        onSuccess: (v) => {
          setMediaData(v);
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }

  return (
    <IonPage>
      {/* We can possibly use lazy deletions to make this a smoother for the user */}
      <IonLoading isOpen={isLoading}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonButton onClick={() => history.back()}><IonIcon icon={chevronBack}/></IonButton>
          </IonButtons>
          <IonButtons slot={"end"}>
            <IonButton id={"open-media-settings-sheet"}><IonIcon icon={ellipsisHorizontal}/></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {mediaData !== undefined && mediaData.type === "image" &&
          <ImageViewer mediaData={mediaData}/>
      }
      {mediaData !== undefined && mediaData.type === "youtube" &&
          <YouTubeViewer mediaData={mediaData}/>
      }
      <IonAlert
        isOpen={showDeleteConfirm}
        header="Delete Confirmation"
        subHeader={getDeletionConfirmText()}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              handleDeleteMedia();
            },
          },
        ]}
        onDidDismiss={() => setShowDeleteConfirm(false)}
      />
      <IonAlert
        key={resetKey}
        isOpen={showEditDescription}
        header="Edit Description"
        subHeader={`(${MAX_MEDIA_DESCRIPTION_LENGTH} character limit)`}
        inputs={[
          {
            name: "mediaDescription",
            type: "textarea",
            placeholder: "Description",
            value: mediaDescription,
            max: MAX_MEDIA_DESCRIPTION_LENGTH,
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Save",
            role: "confirm",
            handler: (data: any) => {
              handleUpdateDescription(data.mediaDescription as string);
            },
          },
        ]}
        onDidDismiss={() => setShowEditDescription(false)}
      />
      <IonActionSheet
        mode={"ios"}
        trigger="open-media-settings-sheet"
        buttons={[
          {
            text: mediaData?.type === "image" ? 'Delete Image' : 'Delete Video',
            role: 'destructive',
            data: {
              action: 'delete',
            },
          },
          {
            text: 'Edit Description',
            data: {
              action: 'edit',
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
        onIonActionSheetDidDismiss={(e) => {
          if (e.detail.data?.action === "delete") {
            setShowDeleteConfirm(true);
          } else if (e.detail.data?.action === "edit") {
            setShowEditDescription(true);
          }
        }}
      ></IonActionSheet>
      <IonToast
        color={feedbackToast.color}
        isOpen={feedbackToast.isOpen}
        message={feedbackToast.message}
        onDidDismiss={() => setFeedbackToast({isOpen: false, message: "", color: ""})}
        duration={5000}
      ></IonToast>
    </IonPage>
  )
}

export default MediaDetail;