import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "reactflow";
import { IonAlert, IonButton, IonCard, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import { textOutline, trashOutline } from "ionicons/icons";
import useFlowActions from "../../../hooks/FlowActionHooks";
import { useParams } from "react-router-dom";
import { useUpdateCard } from "../../../hooks/FlowCardHooks";

const CardConnector = ({
                         id,
                         sourceX,
                         sourceY,
                         targetX,
                         targetY,
                         sourcePosition,
                         targetPosition,
                         data,
                         markerEnd,
                         style = {},
                         selected = false,
                       }: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {flowId} = useParams<{ flowId: string; }>();
  const {getZoom, getEdge} = useReactFlow();
  const {removeEdge} = useFlowActions();
  const {updateConnectionLabel} = useUpdateCard();

  const [labelInputAlertOpen, setLabelInputAlertOpen] = React.useState<boolean>(false);

  const [deleteConfirmationAlertOpen, setDeleteConfirmationAlertOpen] = useState<boolean>(false);

  const setLabelInput = (label: string) => {
    data.label = label;

    updateConnectionLabel(id, flowId, label);
  }

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={style}/>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {data.label && data?.label !== "" && (
              <div style={{
                background: '#ffcc00',
                padding: 10,
                borderRadius: 5,
                fontSize: 36,
                fontWeight: 500,
                display: 'inline-block',
              }}>
                {data.label}
              </div>
            )}
            {selected &&
                <div style={{
                  position: "absolute",
                  transform: `scale(${1 / getZoom()})`,
                  paddingTop: "15px",
                }}>
                    <IonCard style={{zIndex: 99999999, border: "1px solid #ccc", display: "flex", marginTop: "25px"}}>
                        <IonButton size={"small"} fill={"clear"} onClick={() => {
                          setDeleteConfirmationAlertOpen(true);
                        }}><IonIcon icon={trashOutline}
                                    style={{color: "black"}}/></IonButton>
                        <IonButton size={"small"} fill={"clear"} onClick={() => {
                          setLabelInputAlertOpen(true);
                        }}><IonIcon icon={textOutline}
                                    style={{color: "black"}}/></IonButton>
                    </IonCard>
                </div>
            }
          </div>
        </div>
        <IonAlert
          isOpen={labelInputAlertOpen}
          onDidDismiss={() => setLabelInputAlertOpen(false)}
          header={"Describe this Connection"}
          inputs={[
            {
              name: "connectionDescription",
              value: data?.label,
              placeholder: "Connection Description",
              max: 100,
            }
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
              },
            },
            {
              text: "OK",
              handler: (data: any) => {
                setLabelInput(data.connectionDescription);
              },
            }
          ]}
        />
        <IonAlert
          isOpen={deleteConfirmationAlertOpen}
          onDidDismiss={() => setDeleteConfirmationAlertOpen(false)}
          header={"Delete this connection?"}
          subHeader={"This action is permanent and cannot be reversed"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
              },
            },
            {
              text: "OK",
              handler: (data: any) => {
                removeEdge(getEdge(id)!, flowId)
              },
            }
          ]}
        />
      </EdgeLabelRenderer>
    </>
  );
}

export default CardConnector;
