import { FlowConnection, FlowNode } from "../types/FlowTypes";
import { Note } from "../types/NoteTypes";
import { DBMedia } from "../types/MediaType";

import { Edge, Node, Position } from "reactflow";
import { DEFAULT_CONNECTION_STYLE } from "../configs/Constants";

export const buildFlowChartNodes = (flowNodes: FlowNode[], notes: Note[], media: DBMedia[]): Node[] => {

  // @ts-ignore
  const noteMap: Map<string, Note> = notes.reduce((map, item) => map.set(item.$id, item), new Map<string, Note>());
  // @ts-ignore
  const mediaMap: Map<string, DBMedia> = media.reduce((map, item) => map.set(item.$id, item), new Map<string, DBMedia>());

  const nodes: Node[] = [];

  flowNodes.forEach((n) => {
    if (n.type === "note") {
      const targetId = n.targetId.split("-")[0]; // We bake in notion for sub-notes so we need to parse it

      const noteData = noteMap.get(targetId);
      if (noteData) {
        nodes.push({
          id: `${noteData.$id}-0`,
          type: "flowNote",
          data: {label: noteData.name, noteSection: 0, toolbarPosition: Position.Bottom, color: n.color, isExpanded: n.isExpanded, creationTime: n.creationTime},
          position: {x: n.x!, y: n.y!},
          positionAbsolute: {x: n.x!, y: n.y!},
          deletable: false, // prevent backspace deletions
          connectable: false, // manage our own connections via proximity
        });
      }
    } else if (n.type === "image") {
      const targetId = n.targetId
      const mediaData = mediaMap.get(targetId);

      console.debug(n);
      console.debug(mediaData);

      if (mediaData) {
        nodes.push({
          id: `${mediaData.$id}`,
          type: "flowImage",
          data: {
            thumbnailUrl: mediaData.thumbnailUrl,
            url: mediaData.url,
            title: mediaData.name,
            description: mediaData.description,
            toolbarPosition: Position.Bottom,
            color: n.color,
            label: n.label,
            creationTime: n.creationTime,
          },
          position: {x: n.x!, y: n.y!},
          positionAbsolute: {x: n.x!, y: n.y!},
          deletable: false, // prevent backspace deletions
          connectable: false, // manage our own connections via proximity
        });
      }
    } else if (n.type === "youtube") {
      const splitId = n.targetId.split("-");
      const targetId = splitId[0];
      const subId = splitId[1];

      const mediaData = mediaMap.get(targetId);

      if (mediaData) {
        nodes.push({
          id: `${mediaData.$id}-${subId}`,
          type: "flowYoutube",
          data: {
            videoOffset: (subId !== "" && subId !== undefined) ? parseInt(subId) : undefined,
            thumbnailUrl: mediaData.thumbnailUrl,
            url: mediaData.url,
            title: mediaData.name,
            description: mediaData.description,
            toolbarPosition: Position.Bottom,
            color: n.color,
            label: n.label,
            creationTime: n.creationTime,
          },
          position: {x: n.x!, y: n.y!},
          positionAbsolute: {x: n.x!, y: n.y!},
          deletable: false, // prevent backspace deletions
          connectable: false, // manage our own connections via proximity
        });
      }
      console.debug(nodes);
    } else if (n.type === "flow") {
      const targetId = n.targetId;
      nodes.push({
        id: `${targetId}`,
        type: "flowFlow",
        data: {
          label: n.label,
          color: n.color,
          toolbarPosition: Position.Bottom,
          creationTime: n.creationTime,
        },
        position: {x: n.x!, y: n.y!},
        positionAbsolute: {x: n.x!, y: n.y!},
        deletable: false, // prevent backspace deletions
        connectable: false, // manage our own connections via proximity
      });
    } else if (n.type === "text") {
      const targetId = n.targetId;
      nodes.push({
        id: `${targetId}`,
        type: "flowText",
        data: {
          label: n.label,
          color: n.color,
          toolbarPosition: Position.Bottom,
          creationTime: n.creationTime,
        },
        position: {x: n.x!, y: n.y!},
        positionAbsolute: {x: n.x!, y: n.y!},
        deletable: false, // prevent backspace deletions
        connectable: false, // manage our own connections via proximity
      });
    }
  });

  return nodes;
}

export const buildFlowChartEdges = (connections: FlowConnection[]): Edge[] =>
  connections.map((c) => ({
    id: c.id,
    source: c.source,
    target: c.target,
    data: {label: c.label || undefined, creationTime: c.creationTime || undefined,}, // Use a conditional (ternary) operator for clarity
    ...DEFAULT_CONNECTION_STYLE,
  }));
