import {Note, NoteSection} from "../types/NoteTypes";


export const setYouTubeDimensions = (content: string): string => {
  if (!content) {
    return "";
  }
  if (content.includes("data-youtube-video")) {

    const {height, width} = calculateEmbedDimensions();
    const regex = /(<iframe[^>]*)(width="\d+")([^>]*)(height="\d+")([^>]*>)/g;
    // This implementation follows the assumption that we have 1 iframe element and that's for YouTube embeds
    return content.replace(regex, `$1width="${width}"$3height="${height}"$5`);
  } else {
    return content;
  }
}
export const calculateEmbedDimensions = () => {
  // This helps us get around the fact that YouTube video embeds are static and the height and width are also stored
  // in the HTML content and can't be set dynamically.
  const currentWidth = window.innerWidth;

  const width = currentWidth - 32;
  const height = width * 0.75;

  const maxWidth = 640;
  const maxHeight = 480;

  return {
    width: Math.floor(width > maxWidth ? maxWidth : width),
    height: Math.floor(height > maxHeight ? maxHeight : height),
  }
}

export const mapNoteContentToNoteSection = (note: Note, parser?: DOMParser): NoteSection[] => {
  const titleNote: NoteSection = {
    level: 0,
    title: note.name,
    id: "0",
  };

  if (note.content) {
    // Allow parser to be passed in to reduce overhead
    if (!parser) {
      parser = new DOMParser();
    }

    const virtualDoc = parser.parseFromString(note.content, "text/html");
    const nodes = virtualDoc.querySelectorAll("h1,h2");

    const result = [titleNote];

    for (let i = 0; i < nodes.length; i++) {
      let level;
      if (nodes[i].tagName === "H1") {
        level = 1;
      } else if (nodes[i].tagName === "H2") {
        level = 2;
      } else {
        level = 3;
      }

      let text = undefined;
      if (nodes[i].textContent) {
        text = nodes[i].textContent;
      }

      result.push({
        level: level,
        title: text!,
        id: result.length.toString(),
      });
    }
    return result;
  } else {
    return [titleNote];
  }
};

export const populateSectionContent = (noteSections: NoteSection[], note: Note) => {
  // Parses individual sections into the text that fall under the section

  if (!note.content) {
    return;
  }

  let start = 0
  let end = undefined;
  for (let i = 0; i < noteSections.length; i++) {
    if (noteSections[i].level === 1 || noteSections[i].level === 2) {
      const startToken = `<h${noteSections[i].level}>${noteSections[i].title}</h${noteSections[i].level}>`;

      // Default the last element's end token to undefined
      let endToken;
      let contentEnd = note.content!.length;
      if (i + 1 < noteSections.length) {
        endToken = `<h${noteSections[i + 1].level}>${noteSections[i + 1].title}</h${noteSections[i + 1].level}>`
        contentEnd = note.content!.indexOf(endToken, start);
      }

      const contentStart = note.content!.indexOf(startToken, start) + startToken.length;

      noteSections[i].contentStart = contentStart;
      noteSections[i].contentEnd = contentEnd;

      // Shift the cursor
      if (endToken !== undefined) {
        start = contentEnd;
      }
    }
  }

  console.log(noteSections)
  for (let i: number = noteSections.length - 1; i >= 0; i--) {
    if (noteSections[i].level === 1 || noteSections[i].level === 2) {
      const token = `<h${noteSections[i].level}>${noteSections[i].title}</h${noteSections[i].level}>`;

      noteSections[i].contentStart = note.content!.lastIndexOf(token, end);
      noteSections[i].contentEnd = end ? end : noteSections.length - 1;
    }
  }
};
