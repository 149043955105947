import { Functions } from "appwrite";
import getConfig, { client } from "../configs/AppwriteConfigs";

const config = getConfig();
const functions = new Functions(client);

export const bootstrapUser = async (userId: string): Promise<void> => {
  try {
    await functions.createExecution(
      config.privateApiFunctionId,
      JSON.stringify({userId: userId}),
      false,
      "/bootstrap",
      "POST",
      {"Content-Type": "application/json"}
    );
  } catch (error) {
    throw error;
  }
}
