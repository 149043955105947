export enum NavSource {
  CREATE = "create",
  LIBRARY = "library",
  HOME = "home",
  MENU = "menu",
  FLOW = "flow",
  HOME_CARD = "home-card",
  SEARCH = "search",
}

export enum FlowSelection {
  CANVAS = "canvas",
  NODE = "node",
  EDGE = "edge",
}