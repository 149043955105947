import { DBFlow, FlowConnection, FlowNode } from "../types/FlowTypes";
import { useGetUserFlows, useSetFlowConnections, useSetFlowNodes } from "./FlowHooks";
import { useAuthContext } from "../providers/AuthProvider";

export const useUpdateCard = () => {
  const {authUser} = useAuthContext();

  const setFlowNodesQuery = useSetFlowNodes();
  const setFlowConnectionsQuery = useSetFlowConnections();
  const {data: userFlowsData} = useGetUserFlows(authUser?.$id);

  const updateNodeColor = (
    node: FlowNode,
    flowId: string,
    selectedColor: string
  ) => {
    if (!node || !flowId) return;

    const updatedNode: FlowNode = {
      ...node,
      color: selectedColor,
    };

    const flowNodes: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];

    const updatedFlowNodes: FlowNode[] = flowNodes.map((n) =>
      n.targetId === node.targetId ? updatedNode : n
    );

    setFlowNodesQuery.mutate({flowId, flowNodes: updatedFlowNodes}, {
      onSuccess: (resp, args) => {
        // Handle success
      }
    });
  };

  const updateNodeLabel = (
    node: FlowNode,
    flowId: string,
    labelValue: string,
  ) => {
    if (!node || !flowId) return;

    const updatedNode: FlowNode = {
      ...node,
      label: labelValue,
    };

    const flowNodes: FlowNode[] = userFlowsData?.find((flow: DBFlow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];

    const updatedFlowNodes: FlowNode[] = flowNodes.map((n) =>
      n.targetId === node.targetId ? updatedNode : n
    );

    setFlowNodesQuery.mutate({flowId, flowNodes: updatedFlowNodes}, {
      onSuccess: (resp, args) => {
        // Handle success
      }
    });
  };

  const updateConnectionLabel = (
    connectionId: string,
    flowId: string,
    labelValue: string,
  ) => {

    const flowConnection: FlowConnection[] = userFlowsData?.find((flow) => flow.$id === flowId)?.connectionData?.map((fc) => JSON.parse(fc)) as FlowConnection[];

    const connection = flowConnection.find((fc) => fc.id === connectionId);

    if (!connection) {
      return;
    }

    const updatedConnection: FlowConnection = {
      ...connection,
      label: labelValue,
    };

    const updatedFlowConnections: FlowConnection[] = flowConnection.map((fc) =>
      fc.id === connection.id ? updatedConnection : fc
    );

    setFlowConnectionsQuery.mutate({flowId, flowConnections: updatedFlowConnections}, {
      onSuccess: (resp, args) => {
        // Handle success
      }
    })
  }

  return {
    updateNodeColor,
    updateNodeLabel,
    updateConnectionLabel,
  };
};