import { Account, Models, ID } from 'appwrite';
import { client } from "../configs/AppwriteConfigs";

export const account = new Account(client);

export const createAccount = async (email: string, password: string, name: string): Promise<Models.Token> => {
  try {
    await account.create(ID.unique(), email, password);
    await account.createEmailSession(email, password);
    await account.updateName(name);

    return account.createVerification(`${window.location.origin}/verify`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendVerificationEmail = async (): Promise<void> => {
  // Only use this while there's an active session
  try {
    if (await getCurrentUser() === null) {
      return;
    }
    await account.createVerification(`${window.location.origin}/verify`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const signInUser = async (email: string, password: string): Promise<Models.Session> => {
  try {
    return await account.createEmailSession(email, password);
  } catch (error) {
    throw error;
  }
};

export const signInUserWithGoogle = async (): Promise<void> => {
  try {
    const response = await account.createOAuth2Session(
      'google',
      `${window.location.origin}/login`,
      `${window.location.origin}/failure`,
    );

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const verifyUser = async (userId: string, secret: string): Promise<void> => {
  try {
    await account.updateVerification(userId, secret);
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = async (): Promise<Models.Preferences | null> => {
  try {
    return await account.get();
  } catch (error) {
    console.log("Not authorized", error);
    return null
  }
};

export const getSession = async (): Promise<Models.Session> => {
  try {
    return await account.getSession('current');
  } catch (error) {
    throw error;
  }
}

export const signOutUser = async (): Promise<void> => {
  try {
    await account.deleteSession('current');
  } catch (error) {
    throw error;
  }
};

export const createPasswordRecovery = async (email: string): Promise<Models.Token> => {
  return account.createRecovery(email, `${window.location.origin}/reset-password`);
}

export const setPasswordRecovery = async (userId: string, secret: string, password: string): Promise<Models.Token> => {
  return account.updateRecovery(userId, secret, password, password);
}
