import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { DBMedia, VideoAdditionalData, VideoBookmark, } from "../../types/MediaType";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useUpdateVideoData, } from "../../hooks/MediaHooks";
import { formatVideoDuration } from "../../utils/YouTubeUtils";
import { pricetag, removeCircleSharp } from "ionicons/icons";

import "./MediaViewer.css"
import { format } from "date-fns";

interface YouTubeViewerFields {
  mediaData: DBMedia;
  dismiss?: () => void;
  editMode?: boolean;
}

const YouTubeViewer: React.FC<YouTubeViewerFields> = ({
                                                        mediaData
                                                      }: YouTubeViewerFields) => {
  const updateVideoQuery = useUpdateVideoData();

  const mediaRef = useRef<ReactPlayer | null>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);

  const [videoTimestampSeconds, setVideoTimestampSeconds] =
    useState<number>(0);
  const [videoBookmarks, setVideoBookmarks] = useState<VideoBookmark[]>([]);

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

  // This is needed to reset the contents of the IonAlert since the input field persists
  const [alertResetKey, setAlertResetKey] = useState<number>(0);

  useEffect(() => {
    if (mediaData.type === "youtube" && mediaData.additionalData !== undefined) {
      const additionalData = JSON.parse(
        mediaData.additionalData
      ) as VideoAdditionalData;
      if (additionalData?.videoBookmarks) {
        setVideoBookmarks(additionalData.videoBookmarks);
      } else {
        setVideoBookmarks([]);
      }
    } else {
      setVideoBookmarks([]);
    }
  }, []);

  const addVideoBookmark = (bookmarkDescription: string) => {
    // Check if a bookmark already exists at the current timestamp

    const timestampExists = videoBookmarks.some((bookmark) => {
      return bookmark.seconds === videoTimestampSeconds;
    });
    if (timestampExists) {
      setIsToastOpen(true);
      return;
    }

    const updatedBookmarks = [
      ...videoBookmarks,
      {
        description: bookmarkDescription,
        seconds: videoTimestampSeconds ? videoTimestampSeconds : 0,
      },
    ];

    updateVideoQuery.mutate(
      {
        mediaId: mediaData.$id,
        videoUpdateFields: {
          videoBookmarks: updatedBookmarks,
        },
      },
      {
        onSuccess: (v) => {
        },
      }
    );

    setVideoBookmarks(updatedBookmarks);
  };

  const removeVideoBookmark = (bookmark: VideoBookmark) => {
    const updatedBookmarks = videoBookmarks.filter((b) => b !== bookmark);

    updateVideoQuery.mutate(
      {
        mediaId: mediaData.$id,
        videoUpdateFields: {
          videoBookmarks: updatedBookmarks,
        },
      },
      {
        onSuccess: (v) => {
        },
      }
    );

    setVideoBookmarks(updatedBookmarks);
  }

  return (
    <>

      <ReactPlayer
        url={mediaData.url}
        controls={true}
        width="100%"
        ref={mediaRef}
        playing={videoIsPlaying}
        light={true}
        stopOnUnmount={true}
      />
      <IonToolbar mode={"ios"} className={"ion-no-border"}>
        {/*
        <IonButtons slot={"end"}>
          <IonButton><IonIcon icon={pricetag}/></IonButton>
        </IonButtons>
        */}
      </IonToolbar>
      <IonContent>
        <IonItem lines={"none"} style={{marginBottom: "-15px"}}>
          <IonLabel style={{fontSize: "small"}}>
            {format(new Date(mediaData.$createdAt), 'MMMM d, yyyy')}
          </IonLabel>
        </IonItem>
        <IonItem lines={"none"}>
          <IonLabel className="ion-text-wrap">
            {mediaData.description}
          </IonLabel>
        </IonItem>
        <IonList inset={false}>
          <IonListHeader>
            <IonLabel>Video Bookmarks</IonLabel>
            <IonButton id={"present-media-bookmark-creation"} onClick={() => {
              setVideoIsPlaying(false);
              setVideoTimestampSeconds(
                Math.floor(mediaRef.current!.getCurrentTime())
              );
            }}>Add Bookmark</IonButton>
          </IonListHeader>
          {videoBookmarks
            .sort((a, b) => a.seconds - b.seconds)
            .map((bookmark, index) => (
              <IonItem key={index}>
                <IonChip
                  onClick={() => {
                    mediaRef.current!.seekTo(
                      bookmark.seconds,
                      "seconds"
                    );
                    setVideoIsPlaying(true);
                  }}
                >
                  {formatVideoDuration(bookmark.seconds)}
                </IonChip>
                {bookmark.description && (
                  <IonLabel style={{paddingLeft: "10px"}}>
                    {bookmark.description}
                  </IonLabel>
                )}
                <div slot="end">
                  <IonButton fill="clear" onClick={() => removeVideoBookmark(bookmark)}>
                    <IonIcon
                      style={{color: "gray"}}
                      icon={removeCircleSharp}
                    />
                  </IonButton>
                </div>
              </IonItem>
            ))}
        </IonList>
        <IonAlert
          key={alertResetKey}
          onDidDismiss={() => setAlertResetKey(alertResetKey + 1)}
          header="Create Bookmark"
          trigger="present-media-bookmark-creation"
          subHeader={`Video Timestamp: ${formatVideoDuration(videoTimestampSeconds)}`}
          message={"This bookmark will be created at the current video position"}
          inputs={[
            {
              name: "bookmarkName",
              type: "text",
              placeholder: "Bookmark Name",
              max: 100,
            },
          ]}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
              },
            },
            {
              text: "Save",
              role: "confirm",
              handler: (data: any) => {
                addVideoBookmark(data.bookmarkName);
              },
            },
          ]}
        />
        <IonToast isOpen={isToastOpen} onDidDismiss={() => setIsToastOpen(false)}
                  message="A bookmark already exists at this timestamp!" color={"danger"}
                  duration={5000}/>
      </IonContent>
    </>
  );
};

export default YouTubeViewer;
