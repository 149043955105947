import { faBold, faH, faItalic, faP, faQuoteLeft, faStrikethrough } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonLabel } from "@ionic/react";
import { MenuBarFields } from "./EditorMenuBar";
import React from "react";

const FontSubMenu = ({editor}: MenuBarFields) => {
  return (
    <>
      {/* Heading */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('heading', {level: 1}) ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
      >
        <FontAwesomeIcon icon={faH} size={"sm"} style={{display: "inline-block"}}/>
      </IonButton>
      {/* Subheading*/}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('heading', {level: 2}) ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
      >
        <IonLabel className={"ion-text-lowercase"}>h</IonLabel>
      </IonButton>
      {/* Paragraph */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('paragraph') ? "outline" : "solid"}
        onClick={() => editor.chain().focus().setParagraph().run()}
      >
        <FontAwesomeIcon icon={faP}/>
      </IonButton>
      {/* Quote */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('blockquote') ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
      >
        <FontAwesomeIcon icon={faQuoteLeft}/>
      </IonButton>
      <IonButton
        size="default"
        color={editor.isActive('bold') ? "medium" : "light"}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <FontAwesomeIcon icon={faBold}/>
      </IonButton>
      <IonButton
        size="default"
        color={editor.isActive('italic') ? "medium" : "light"}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <FontAwesomeIcon icon={faItalic}/>
      </IonButton>
      <IonButton
        size="default"
        color={editor.isActive('strike') ? "medium" : "light"}
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <FontAwesomeIcon icon={faStrikethrough}/>
      </IonButton>
    </>
  );
};

export default FontSubMenu;