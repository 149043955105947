import React, { ReactNode } from 'react';
import { IonCol, IonCard, IonRow } from '@ionic/react';

interface AuthCardProps {
  children: ReactNode;
}

const AuthCard: React.FC<AuthCardProps> = ({ children }) => (
  <IonCard mode="ios" className="login-card" style={{ margin: "7px auto", maxWidth: "800px" }}>
    <IonRow>
      <IonCol sizeMd="6" offsetMd="3" sizeXs="12">
        <form>
          {children}
        </form>
      </IonCol>
    </IonRow>
  </IonCard>
);

export default AuthCard;
