import React, { useEffect, useRef, useState } from "react";

import "./Card.css";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPopover,
} from "@ionic/react";
import { Handle, NodeToolbar, Position, useReactFlow } from "reactflow";
import { NoteSection } from "../../../types/NoteTypes";
import { enterOutline, trashOutline } from "ionicons/icons";
import { useParams } from "react-router-dom";
import useFlowActions from "../../../hooks/FlowActionHooks";
import { useHistory } from "react-router";
import { ColorPicker } from "../../ColorPicker";
import { PRESET_COLORS } from "../../../configs/Constants";
import { useGetUserFlows } from "../../../hooks/FlowHooks";
import { DBFlow, FlowNode } from "../../../types/FlowTypes";
import { useUpdateCard } from "../../../hooks/FlowCardHooks";
import { NavSource } from "../../../types/NavEnums";
import { useAuthContext } from "../../../providers/AuthProvider";

interface FlowFields {
  id: string;
  data: {
    label: string;
    noteSection: NoteSection;
    toolbarVisible: boolean;
    toolbarPosition: Position;
    color?: string;
  };
  selected: boolean;
}

const FlowchartCard = ({id, data, selected}: FlowFields) => {
  const {authUser} = useAuthContext();
  const history = useHistory();

  const {flowId} = useParams<{ flowId: string; }>();
  const {removeNode} = useFlowActions();
  const {getNode} = useReactFlow();

  const {data: userFlowsData, isLoading: userFlowsDataIsLoading, isError: userFlowsDataIsError} = useGetUserFlows(authUser?.$id);

  const {updateNodeColor} = useUpdateCard();

  const colorPickerPopover = useRef<HTMLIonPopoverElement>(null);
  const [colorPickerPopoverOpen, setColorPickerPopoverOpen] = useState(false);
  const [color, setColor] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [deleteConfirmationAlertOpen, setDeleteConfirmationAlertOpen] = useState<boolean>(false);

  const onDelete = () => {
    const nodeToDelete = getNode(id);
    if (nodeToDelete && flowId) {
      removeNode(nodeToDelete, flowId);
    }
  }

  useEffect(() => {
    const flowData: DBFlow | undefined = userFlowsData?.find((flow) => flow.$id === id);

    if (flowData) {
      setName(flowData.name);
    }

    if (data.color) {
      setColor(data.color);
    } else {
      setColor("#F5F5F5")
    }
  }, [data.color, id]);

  const updateColor = (selectedColor: string) => {
    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];
      const nodeToUpdate = fn.find((node) => node.targetId === `${id}`);

      if (nodeToUpdate) {
        data.color = selectedColor;
        setColor(selectedColor);
        setColorPickerPopoverOpen(false);

        updateNodeColor(nodeToUpdate, flowId, selectedColor);
      }
    }
  };

  return (
    <>
      <Handle type="target" position={Position.Top} style={{opacity: 0}}/>
      <IonCard color={"light"}
               style={{width: "150%"}}> {/*} style={{border: "2px #C63970"}}> <-- this has some issues with border */}
        <IonCardHeader>
          <IonCardTitle
            style={{fontSize: "400%", paddingTop: "50px", paddingBottom: "50px", textAlign: "center"}}>{name}</IonCardTitle>
        </IonCardHeader>
      </IonCard>
      <IonPopover ref={colorPickerPopover} isOpen={colorPickerPopoverOpen} showBackdrop={false}
                  onDidDismiss={() => setColorPickerPopoverOpen(false)} size={"auto"} style={{"--min-width": "280px"}}>
        <IonContent scrollY={false}>
          <ColorPicker color={color} onChange={updateColor} presetColors={PRESET_COLORS}
                       hideFreeformColorPicker={true}/>
        </IonContent>
      </IonPopover>
      <Handle type="source" position={Position.Bottom} style={{opacity: 0}}/>
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}
                   style={{marginTop: "-10px", marginLeft: "20px"}}>
        <IonCard style={{border: "1px solid #ccc"}}>
          <IonButton size={"small"} fill={"clear"} onClick={() => setDeleteConfirmationAlertOpen(true)}><IonIcon
            icon={trashOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"}
                     onClick={() => history.push(`/flows/item/${id}?source=${NavSource.FLOW}`)}><IonIcon
            icon={enterOutline} style={{color: "black"}}/></IonButton>
        </IonCard>
      </NodeToolbar>
      <IonAlert
        isOpen={deleteConfirmationAlertOpen}
        onDidDismiss={() => setDeleteConfirmationAlertOpen(false)}
        header={"Delete this card?"}
        subHeader={"This action is permanent and will also remove the connections to this card"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
            },
          },
          {
            text: "OK",
            handler: (data: any) => {
              onDelete();
            },
          }
        ]}
      />
    </>
  )
}

export default FlowchartCard;