import React from 'react';
import { IonImg, IonLabel } from '@ionic/react';

import './LogoHeader.css';

const LogoHeader: React.FC = () => {

  return (
    <>
      <a href={process.env.PUBLIC_URL}>
        <IonImg
          src={process.env.PUBLIC_URL + '/assets/icon/icon.png'}
          alt="Grapple Flows"
          className="logo-image"
        />
        <IonLabel className="logo-text">grapple flows</IonLabel>
      </a>
    </>
  );
};

export default LogoHeader;
