import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { useHistory } from "react-router";

import './Auth.css';
import LogoHeader from "../../components/auth/LogoHeader";
import AuthCard from "../../components/auth/AuthCard";
import OAuthOption from "../../components/auth/OAuthOption";
import UserPolicy from "../../components/auth/UserPolicy";
import { useAuthContext } from "../../providers/AuthProvider";

const Registration: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {register} = useAuthContext();

  const history = useHistory();

  const handleClickSignIn = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    history.push('/login');
  };

  const handleRegister = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    setErrorMessage('');

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    // Password validation (e.g., minimum length)
    if (!password || password.length < 6) {
      setErrorMessage('Password must be at least 6 characters.');
      return;
    }

    if (!name || name.length < 1) {
      setErrorMessage('Please enter your name.');
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setShowLoading(true);

    try {
      const verification = await register(email, password, name);
      console.log(verification);

      history.replace('/verify');
    } catch (error: any) {
      console.error("Registration Error:", error);
      setErrorMessage(error.message);
    } finally {
      // Hide loading indicator
      setShowLoading(false);
    }
  };

  const handleGoogleSignUp = () => {
    // Implement your Google sign-up logic here

    console.log('Signing up with Google');
  };

  return (
    <IonPage>
      <IonLoading isOpen={showLoading} message="Registering..."/>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <LogoHeader/>
            </IonCol>
          </IonRow>
          <UserPolicy />
          <AuthCard>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Name"
                type="text"
                autocapitalize="on"
                value={name}
                onIonInput={(e) => setName(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Email"
                type="email"
                value={email}
                onIonInput={(e) => setEmail(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Password"
                type="password"
                value={password}
                onIonInput={(e) => setPassword(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onIonInput={(e) => setConfirmPassword(e.detail.value!)}
                required
              />
            </IonItem>
            <IonButton style={{marginTop: "30px"}} expand="block" onClick={(e) => handleRegister(e)}>
              Register
            </IonButton>
          </AuthCard>
          <OAuthOption/>
        </IonGrid>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "0"}}>
          <IonItem lines={"none"} style={{width: "100%", textAlign: "center"}}>
            <IonLabel>Already have an account?
              <a href="/login" onClick={(e) => handleClickSignIn}> Log in</a>
            </IonLabel>
          </IonItem>
        </div>
      </IonContent>
      <IonToast
        isOpen={errorMessage !== ''}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={3000}
        color="danger"
      />
    </IonPage>
  );
};

export default Registration;
