// Each flow gets a copy of this at the top level. It then gets modified PER FLOW
export const DEFAULT_TYPES = {
  "escape": "color",
  "guard_pass": "color",
  "guard_retention": "color",
  "position": "color",
  "reaction": "color",
  "submission": "color",
  "sweep": "color",
  "takedown": "color",
  "other": "color",
  // "nested_flow": "color"
};

export const formatTypeNames = (name: string): string => {
  return name.split("_").map((v) => v[0].toUpperCase() + v.substring(1)).join(" ");
};

export interface Note {
  // Internal Metadata
  $id?: string;
  $createdAt?: Date;
  $updatedAt?: Date;
  ownerId: string;

  // Note Metadata
  isGlobal?: boolean;
  type: string;
  overrideColor?: string;
  tags?: string[];

  // User Metadata
  name: string;
  description?: string;
  comfort?: number;
  points?: number; // negative is okay
  log?: string[];

  // Execution
  notes?: string;
  steps?: string;

  // videoReference?: string;
  // images?: string[];
  // sketch: idk;
  content?: string;
  date: string;
  noteSections?: string;
}

export interface NoteSection {
  id: string,
  level: number, // title is 0; h1 is 1; h2 is 2
  title?: string,
  contentStart?: number,
  contentEnd?: number,
}

export const marshalNoteSection = (ns: NoteSection[]): string => {
  return JSON.stringify(ns);
}

export const unmarshalNoteSection = (ns: string): NoteSection[] => {
  return JSON.parse(ns);
}
