export enum SortMode {
  CREATION_TIME,
  UPDATE_TIME,
  ALPHABETICAL,
}

export enum SortOrder {
  ASCENDING,
  DESCENDING,
}

export interface SortFields {
  mode: SortMode;
  order: SortOrder;
}