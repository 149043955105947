import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';

import './Landing.css';

const LandingPage: React.FC = () => {
  const defaultBackgroundImageUrl = process.env.PUBLIC_URL + '/assets/media/banner.png';
  const webpBackgroundImageUrl = process.env.PUBLIC_URL + '/assets/media/banner.webp';
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>("");

  const defaultMacbookImageUrl = process.env.PUBLIC_URL + "/assets/media/flowchart-macbook.png";
  const webpMacbookImageUrl = process.env.PUBLIC_URL + "/assets/media/flowchart-macbook.webp";
  const [macbookImageUrl, setMacbookImageUrl] = useState<string>("");

  const defaultIphoneImageUrl = process.env.PUBLIC_URL + "/assets/media/flowchart-iphone.png";
  const webpIphoneImageUrl = process.env.PUBLIC_URL + "/assets/media/flowchart-iphone.webp";
  const [iphoneImageUrl, setIphoneImageUrl] = useState<string>("");

  const defaultFlowImageUrl = process.env.PUBLIC_URL + "/assets/media/landing-flow.png";
  const webpFlowImageUrl = process.env.PUBLIC_URL + "/assets/media/landing-flow.webp";
  const [flowImageUrl, setFlowImageUrl] = useState<string>("");

  useEffect(() => {
    // Function to test WebP support
    const testWebPSupport = () => {
      const canvas = document.createElement('canvas');
      if (canvas.getContext && canvas.getContext('2d')) {
        // Check if canvas.toDataURL returns a WebP format
        return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      } else {
        return false;
      }
    };

    if (testWebPSupport()) {
      setBackgroundImageUrl(webpBackgroundImageUrl);
      setMacbookImageUrl(webpMacbookImageUrl);
      setIphoneImageUrl(webpIphoneImageUrl);
      setFlowImageUrl(webpFlowImageUrl);
    } else {
      setBackgroundImageUrl(defaultBackgroundImageUrl);
      setMacbookImageUrl(defaultMacbookImageUrl);
      setIphoneImageUrl(defaultIphoneImageUrl);
      setFlowImageUrl(defaultFlowImageUrl);
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonImg
              className="toolbar-logo"
              src={process.env.PUBLIC_URL + '/assets/icon/icon.png'}
              alt="Grapple Flows"
            />
            <IonTitle className="toolbar-title">grapple flows</IonTitle>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton href="/register" color={"primary"}>Register</IonButton>
            <IonButton href="/login">Log In</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{textAlign: "center", padding: "20px"}}>
          <IonChip id={"about-beta"} color={"primary"}>Open Beta Now Live!</IonChip>
          <h1 style={{fontSize: "2.5rem", fontWeight: "bold", marginBottom: "1rem", color: "#333"}}>Structure and
            Systemize
            Your Jiu Jitsu</h1>
          <p style={{fontSize: "1.2rem", lineHeight: "1.4", color: "#666"}}>Create and organize your Brazilian Jiu
            Jitsu training media
            and notes into interactive flow charts for a systematic learning experience.</p>
          <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
            <IonButton expand="block" href={"/register"} size={"large"}>Get Started</IonButton>
          </div>
        </div>
        <div style={{position: 'relative'}}>
          <IonImg
            src={backgroundImageUrl}
            style={{
              position: 'absolute',
              minWidth: "900px"
            }}
          />
          <div style={{position: 'relative'}}>
            <div
              className="ion-padding">
              <IonGrid style={{maxWidth: "1200px", width: "100%", justifyContent: "center", alignItems: "center"}}>
                <IonRow>
                  <IonCol size="12" size-md="8">
                    <IonImg
                      src={macbookImageUrl}
                      alt="Build interactive flow charts on your laptop"
                      style={{
                        height: "50vh",
                        borderRadius: '5px',
                        position: "float"
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" size-md="2">
                    <IonImg
                      src={iphoneImageUrl}
                      alt="Build interactive flow charts on your mobile device"
                      style={{
                        height: "50vh",
                        borderRadius: '5px',
                        position: "float"
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <div style={{
            height: '50px',
            width: '100%'
          }}>
            {/* This div acts as an invisible divider */}
          </div>
          <div style={{position: 'relative'}}>
            {/* Elements here will not sit on top of the image */}
            <div className={"ion-padding"}>
              <IonGrid className={"grid-container"}>
                <IonRow>
                  <IonCol size="12" size-md="6" style={{paddingRight: "20px"}}>
                    <IonImg
                      src={process.env.PUBLIC_URL + "/assets/media/screenshot_video.png"}
                      alt="Bookmark, title, and timestamp YouTube videos."
                      className={"grid-image"}
                    />
                  </IonCol>
                  <IonCol size="12" size-md="6" className={"grid-content"}>
                    <h2>Enhance your learning by
                      referencing key moments for efficient mastery.</h2>
                    <div>
                      Bookmark, Title, and Timestamp YouTube Videos. Effortlessly bookmark, title, and timestamp
                      training videos and instructionals.
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className={"grid-container"}>
                <IonRow>
                  <IonCol size="12" size-md="6" style={{paddingRight: "20px"}}>
                    <IonImg
                      src={process.env.PUBLIC_URL + "/assets/media/screenshot_notes.png"}
                      alt="Record and reflect your BJJ training as structured notes."
                      className={"grid-image"}
                    />
                  </IonCol>
                  <IonCol size="12" size-md="6" className={"grid-content"}>
                    <h2>Focus on skill improvement while we
                      handle organization.</h2>
                    <div>
                      Record and Reflect Your BJJ Training as Structured Notes. Transform scattered thoughts into
                      structured training notes.
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className={"grid-container"}>
                <IonRow>
                  <IonCol size="12" size-md="6" style={{paddingRight: "20px"}}>
                    <IonImg
                      src={process.env.PUBLIC_URL + "/assets/media/screenshot_media.png"}
                      alt="Import your photos for lessons and techniques."
                      className={"grid-image"}
                    />
                  </IonCol>
                  <IonCol size="12" size-md="6" className={"grid-content"}>
                    <h2>Add value to training photos.</h2>
                    <div>
                      Import your photos to visualize progress, recall key moments, and
                      maximize your training photo potential. Your photos no longer have to sit stale in your phone's
                      camera roll.
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div style={{justifyContent: "center", textAlign: "center", paddingTop: "20px"}}>
                <div className={"tagline-container"}>
                  <h2>Build Flow Charts With Everything.</h2>
                  <p>All your notes, media, and even other flow charts can be incorporated into flow charts.</p>
                </div>
                <div style={{justifyContent: "center", alignItems: "center", display: "flex", paddingTop: "20px"}}>
                  <IonButton expand="block" href={"/register"} size={"large"}>Build Flow Charts</IonButton>
                </div>
                <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
                  <IonImg
                    src={flowImageUrl}
                    alt="Grapple Flows enables you to build flow charts with your notes, media, and other flow charts."
                    style={{maxWidth: "80%"}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
        <IonFooter mode={"md"}>
          <IonToolbar>
            <IonItem lines={"none"} className={"ion-text-center"}>
              <IonLabel style={{fontSize: "small"}}>© 2023 Grapple Flows</IonLabel>
              <IonLabel style={{fontSize: "small"}}><a href={"/user-agreement"} style={{color: "black"}}>Terms of
                Use</a><a style={{paddingLeft: "1vh", color: "black"}} href={"/privacy-policy"}>Privacy
                Policy</a></IonLabel>
            </IonItem>
          </IonToolbar>
        </IonFooter>
      </IonContent>
      <IonAlert trigger={"about-beta"}
                header={"Try the Open Beta!"}
                message={"Open Beta is your chance to join us as we refine and improve our service. Try out our latest features, provide valuable feedback, and be part of Grapple Flows. Your input helps us shape the future of our product."}
      />
    </IonPage>
  );
};

export default LandingPage;
