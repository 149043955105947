export const getNextUntitledNumber = (items: any[] | undefined, regex: RegExp): number => {
  const untitledNumbers = items?.map(item => {
    const match = item.name.match(regex);
    if (match) {
      return parseInt(match[1]);
    }
    return 0;
  });

  let nextUntitledNumber = 0;
  if (untitledNumbers && untitledNumbers.length > 0) {
    nextUntitledNumber = Math.max(...[...untitledNumbers, 0]);
  }
  nextUntitledNumber += 1;
  return nextUntitledNumber;
};
