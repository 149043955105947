import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { useHistory } from 'react-router';

import './Auth.css';
import { useAuthContext } from '../../providers/AuthProvider';
import { Models } from 'appwrite';
import LogoHeader from "../../components/auth/LogoHeader";
import AuthCard from "../../components/auth/AuthCard";
import OAuthOption from "../../components/auth/OAuthOption";
import UserPolicy from "../../components/auth/UserPolicy";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [recoveryEmail, setRecoveryEmail] = useState<string>('');

  const {authUser, logIn, createPasswordRecovery} = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    setErrorMessage('');
    setEmail('');
    setPassword('');
  }, []);

  useEffect(() => {
    if (authUser) {
      console.debug(authUser);
      setShowLoading(false);
      history.replace('/tabs/main');
    }
  }, [authUser]);

  const handleClickRegister = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    history.push('/register');
  }

  const handleSignIn = () => {
    setShowLoading(true);

    // Client-side validations
    if (email === '' || password === '') {
      setErrorMessage('Email and password are required');
      setShowLoading(false);
      return;
    }

    logIn(email, password)
      .then((session: Models.Session) => {
        console.debug(session);
        if (session) {
          setShowLoading(false);
          // I think we can remove this since useEffect can update it
          //history.replace('/tabs/main');
        }
      })
      .catch((err: any) => {
        console.log(err);
        setShowLoading(false);
        setErrorMessage(err.message);
      });
  };

  const handlePasswordReset = (email: string) => {
    if (!email || email === '' || !email.includes('@')) {
      setErrorMessage('Please enter a valid email address');
      return;
    } else {
      createPasswordRecovery(email).then((token) => {
        console.debug(token);
        setErrorMessage('Password reset link sent to ' + email);
      }).catch((err: any) => {
        console.log(err);
        setErrorMessage(err.message);
      })
    }
  }

  return (
    <IonPage>
      <IonLoading isOpen={showLoading} message="Logging in..."/>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <LogoHeader/>
            </IonCol>
          </IonRow>
          <UserPolicy/>
          <AuthCard>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Email"
                type="email"
                autocapitalize="off"
                value={email}
                onIonInput={(e) => setEmail(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Password"
                type="password"
                value={password}
                onIonInput={(e) => setPassword(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem lines="none" className="forgot-password" id={"present-password-reset"}>
              <IonLabel className="small-text">Forgot Password?</IonLabel>
            </IonItem>
            <IonButton expand="block" onClick={handleSignIn}>
              Sign In
            </IonButton>
          </AuthCard>
          <OAuthOption/>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "0"}}>
            <IonItem lines={"none"} style={{width: "100%", textAlign: "center"}}>
              <IonLabel>New here?
                <a href="/register" onClick={(e) => handleClickRegister}> Create an
                  account</a>
              </IonLabel>
            </IonItem>
          </div>
        </IonGrid>
      </IonContent>
      <IonAlert
        trigger="present-password-reset"
        header='Reset Password'
        message='A reset link will be sent to your e-mail address.'
        inputs={[
          {
            name: 'recoveryEmail',
            type: 'email',
            value: recoveryEmail,
            placeholder: 'Your email address',
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            },
          },
          {
            text: 'Submit',
            handler: (data) => {
              handlePasswordReset(data.recoveryEmail);
            }
          }
        ]}
      />
      <IonToast
        isOpen={errorMessage !== ''}
        onDidDismiss={() => setErrorMessage('')}
        message={errorMessage}
        duration={3000}
        color="danger"
      />
    </IonPage>
  );
};

export default Login;
