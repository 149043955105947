import { Edge, getConnectedEdges, Node, useReactFlow } from "reactflow";
import { useSetFlowConnections, useSetFlowNodesAndConnections } from './FlowHooks';
import { FlowConnection, FlowNode, mapEdgeToFlowConnection, mapNodeToFlowNode } from "../types/FlowTypes";

function useFlowActions() {
  const {getNodes, getEdges, setNodes, setEdges} = useReactFlow();

  const setFlowNodesAndConnections = useSetFlowNodesAndConnections();
  const setFlowConnections = useSetFlowConnections();

  const removeEdge = (edge: Edge, flowId: string) => {
    const edges = getEdges();
    const edgesToKeep: Edge[] = edges.filter((e: Edge) => e.id !== edge.id);

    const flowConnections: FlowConnection[] = edgesToKeep.map((e: Edge) => mapEdgeToFlowConnection(e));

    setEdges(edgesToKeep);

    setFlowConnections.mutate({flowId, flowConnections}, {
      onSuccess: () => {
      },
      onError: () => {
        // Revert the removal of the edge if there was an error
        setEdges(edges);
      }
    })
  };

  const removeNode = (node: Node, flowId: string) => {
    const nodes = getNodes();
    const edges = getEdges();

    const edgeIdsToDelete: string[] = getConnectedEdges([node], edges).map((e: Edge) => e.id);

    const nodesToKeep: Node[] = nodes.filter((n: Node) => n.id !== node.id);
    const edgesToKeep: Edge[] = edges.filter((e: Edge) => !edgeIdsToDelete.includes(e.id));

    const flowNodes: FlowNode[] = nodesToKeep.map((n: Node) => mapNodeToFlowNode(n));
    const flowConnections: FlowConnection[] = edgesToKeep.map((e: Edge) => mapEdgeToFlowConnection(e));

    setEdges(edgesToKeep);
    setNodes(nodesToKeep);

    setFlowNodesAndConnections.mutate({flowId, flowNodes, flowConnections}, {
      onSuccess: () => {
      },
      onError: () => {
        // Revert the removal of the node and edges if there was an error
        setNodes(nodes);
        setEdges(edges);
      }
    });
  }

  return {removeEdge, removeNode};
}

export default useFlowActions;