import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";

import LogoHeader from "../../components/auth/LogoHeader";
import AuthCard from "../../components/auth/AuthCard";
import './Auth.css';
import { useAuthContext } from "../../providers/AuthProvider";

type VerificationStatus = 'redirected' | 'emailLinkClicked' | 'authNotVerified';
type MessageDetails = {
  type: 'error' | 'info',
  content: string,
  duration: number
};

const Verification: React.FC = () => {
  const {verify, authUser, logOut, sendVerificationEmail} = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>('redirected');
  const [messageDetails, setMessageDetails] = useState<MessageDetails | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [verificationResendClicked, setVerificationResendClicked] = useState<boolean>(false);

  const updateMessage = (type: 'error' | 'info', content: string, duration: number) => {
    setMessageDetails({type, content, duration});
  };

  useEffect(() => {
    setShowLoading(true);
    const userId = searchParams.get('userId');
    const secret = searchParams.get('secret');

    if (authUser && !authUser.verifiedEmail) {
      setVerificationStatus('authNotVerified');
      setShowLoading(false);
    } else if (userId && secret) {
      setVerificationStatus('emailLinkClicked');
      verify(userId, secret).then(() => {
        setShowLoading(false);
        if (authUser) {
          updateMessage('info', "Your account has been successfully verified!", 2000);
          history.replace('/tabs/main');
        } else {
          updateMessage('info', "Your account has been successfully verified. You can now log in!", 5000);
          history.replace('/login');
        }
      }).catch((error) => {
        console.error("User verification error:", error);
        updateMessage('error', error.message, 5000);
      });
    } else {
      setShowLoading(false);
    }
  }, [authUser, location, verify, history]);

  const handleResendEmail = () => {
    setVerificationResendClicked(true);
    setShowLoading(true);
    sendVerificationEmail().then(() => {
      updateMessage('info', 'A new verification email has been sent to your inbox.', 5000);
    }).catch((error) => {
      updateMessage('error', error.message, 5000);
    }).finally(() => setShowLoading(false));
  };

  const handleLogout = () => {
    setShowLoading(true);
    logOut().then(() => {
      history.push('/login');
    }).finally(() => setShowLoading(false));
  };

  return (
    <>
      <IonLoading isOpen={showLoading}/>
      <IonPage>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="auto">
                <LogoHeader/>
              </IonCol>
            </IonRow>
            {verificationStatus === 'redirected' && (
              <AuthCard>
                <IonItem lines="none">
                  <IonText>
                    <div style={{textAlign: "center"}}>
                      <p>
                        Thank you for creating an account with us!
                      </p>
                      <p>
                        Please check your inbox (including spam) for a verification email and click the link to activate
                        your account.
                      </p>
                      <p>
                        The e-mail may take a few minutes to arrive.
                      </p>
                    </div>
                  </IonText>
                </IonItem>
              </AuthCard>
            )}
            {verificationStatus === 'authNotVerified' && (
              <AuthCard>
                <IonItem lines="none">
                  <IonText>
                    <div style={{textAlign: "center"}}>
                      <p>Your email address has not been verified yet.</p>
                      <p>Please check your email inbox for the verification message.</p>

                      <IonButton disabled={verificationResendClicked} onClick={handleResendEmail}>Resend
                        Verification</IonButton>
                      <IonButton onClick={handleLogout}>Log Out</IonButton>
                    </div>
                  </IonText>
                </IonItem>
              </AuthCard>
            )}
            <IonLoading isOpen={verificationStatus === 'emailLinkClicked' && !messageDetails}
                        message={"Verifying account..."}/>
          </IonGrid>
        </IonContent>
        <IonToast
          isOpen={messageDetails !== null}
          message={messageDetails?.content}
          color={messageDetails?.type === 'error' ? 'danger' : 'primary'}
          position="middle"
          duration={messageDetails?.duration}
          onDidDismiss={() => setMessageDetails(null)}
        />
      </IonPage>
    </>
  );
};

export default Verification;
