import { IonImg, IonTitle, } from "@ionic/react";
import React from "react";

const Logo = () => {
  return (
    <>
      <IonImg
        className="toolbar-logo"
        src={process.env.PUBLIC_URL + '/assets/icon/icon.png'}
        alt="Grapple Flows"
      />
      <IonTitle className="toolbar-title">grapple flows</IonTitle>
    </>
  )
}

export default Logo;
