import { IonButton, IonCol, IonPopover, IonRow, IonToolbar } from '@ionic/react';
import { Editor } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faHighlighter, faItalic, faStrikethrough } from '@fortawesome/free-solid-svg-icons';

import "./EditorMenuBar.css";
import React, { useState } from "react";
import { ColorPicker } from "../ColorPicker";
import { PRESET_COLORS } from "../../configs/Constants";

interface MenuBarFields {
  editor: Editor;
}

const TextSelectionBar = ({editor}: MenuBarFields) => {
  const [highlightColor, setHighlightColor] = useState<string>("#ffff00");
  const [lastSelection, setLastSelection] = useState<number[]>([0, 0]);

  if (!editor) {
    return null;
  }

  return (
    <IonToolbar>
      <IonRow>
        <IonCol class="ion-justify-content-start">
          <IonButton
            size="default"
            color="light"
            id="highlight-expand-trigger"
            onClick={() => {
              const start = editor.view.state.selection.from;
              const end = editor.view.state.selection.to;
              setLastSelection([start, end]);
            }}
          >
            <FontAwesomeIcon icon={faHighlighter}/>
          </IonButton>
          <IonButton
            size="default"
            color={editor.isActive('bold') ? "medium" : "light"}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <FontAwesomeIcon icon={faBold}/>
          </IonButton>
          <IonButton
            size="default"
            color={editor.isActive('italic') ? "medium" : "light"}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <FontAwesomeIcon icon={faItalic}/>
          </IonButton>
          <IonButton
            size="default"
            color={editor.isActive('strike') ? "medium" : "light"}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            <FontAwesomeIcon icon={faStrikethrough}/>
          </IonButton>
        </IonCol>
      </IonRow>
      <IonPopover
        className="expand-options"
        trigger="highlight-expand-trigger"
        triggerAction="click"
        size="auto"
        showBackdrop={false}
        reference="trigger"
        keyboardClose={false}
        keepContentsMounted={true}
        alignment="end"
        side="bottom"
        translucent={false}
        dismissOnSelect={true}
        style={{"--min-width": "280px"}}
        onDidDismiss={() => {
          editor.commands.setTextSelection({
            from: lastSelection[0],
            to: lastSelection[1],
          });

          editor.chain().focus().setColor(highlightColor).run();
        }}
      >
        <ColorPicker color={highlightColor} onChange={setHighlightColor} presetColors={PRESET_COLORS}
                     hideFreeformColorPicker={true}/>
      </IonPopover>
    </IonToolbar>
  );
};

export default TextSelectionBar;