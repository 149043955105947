import { Client } from "appwrite";

export interface AppwriteConfig {
  imagesBucketId: string,
  mediaCollectionId: string,
  notesCollectionId: string,
  flowsCollectionId: string,
  waitlistCollectionId: string,
  feedbackCollectionId: string,
  databaseId: string,
  privateApiFunctionId: string,
  endpoint: string,
  projectId: string,
}

const getConfig = (): AppwriteConfig => {
  const env = process.env.NODE_ENV;

  if (env === "development") {
    return {
      imagesBucketId: "64ee7c5bb33e6b41faf0",
      mediaCollectionId: "64ef71bcbf5f618e9511",
      notesCollectionId: "63a9487f598c8786976a",
      flowsCollectionId: "63ae88c39fb2f5ca27ec",
      waitlistCollectionId: "658cc7877a6ab7eb9547",
      feedbackCollectionId: "658e32a5d3b6469124a0",
      databaseId: "63a9465bf069da4ee9ef",
      privateApiFunctionId: "658d0426b3dec884b52a",
      endpoint: "https://cloud.appwrite.io/v1",
      projectId: "63a941b33d5b09aa6211",
    };
  } else {
    if (!process.env.REACT_APP_APPWRITE_IMAGES_BUCKET_ID ||
      !process.env.REACT_APP_APPWRITE_MEDIA_COLLECTION_ID ||
      !process.env.REACT_APP_APPWRITE_NOTES_COLLECTION_ID ||
      !process.env.REACT_APP_APPWRITE_FLOWS_COLLECTION_ID ||
      !process.env.REACT_APP_APPWRITE_WAITLIST_COLLECTION_ID ||
      !process.env.REACT_APP_APPWRITE_FEEDBACK_COLLECTION_ID ||
      !process.env.REACT_APP_APPWRITE_DATABASE_ID ||
      !process.env.REACT_APP_APPWRITE_PRIVATE_API_FUNCTION_ID ||
      !process.env.REACT_APP_APPWRITE_ENDPOINT ||
      !process.env.REACT_APP_PROJECT_ID) {
      throw "Missing a required Appwrite config";
    }

    return {
      imagesBucketId: process.env.REACT_APP_APPWRITE_IMAGES_BUCKET_ID,
      mediaCollectionId: process.env.REACT_APP_APPWRITE_MEDIA_COLLECTION_ID,
      notesCollectionId: process.env.REACT_APP_APPWRITE_NOTES_COLLECTION_ID,
      flowsCollectionId: process.env.REACT_APP_APPWRITE_FLOWS_COLLECTION_ID,
      waitlistCollectionId: process.env.REACT_APP_APPWRITE_WAITLIST_COLLECTION_ID,
      feedbackCollectionId: process.env.REACT_APP_APPWRITE_FEEDBACK_COLLECTION_ID,
      databaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
      privateApiFunctionId: process.env.REACT_APP_APPWRITE_PRIVATE_API_FUNCTION_ID,
      endpoint: process.env.REACT_APP_APPWRITE_ENDPOINT,
      projectId: process.env.REACT_APP_PROJECT_ID,
    };
  }
};

const config = getConfig();
export const client = new Client();
client.setEndpoint(config.endpoint);
client.setProject(config.projectId);

export default getConfig;