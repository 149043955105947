import React, { useEffect, useRef, useState } from "react";
import DOMPurify from 'dompurify';

import "./Card.css";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPopover,
  IonRow
} from "@ionic/react";
import { Handle, NodeToolbar, Position, useReactFlow } from "reactflow";
import { NoteSection } from "../../../types/NoteTypes";
import { useGetNote } from "../../../hooks/NotesHooks";
import {
  chevronDownCircleSharp,
  chevronUpCircleSharp,
  colorPaletteOutline,
  enterOutline,
  trashOutline
} from "ionicons/icons";
import { useParams } from "react-router-dom";
import useFlowActions from "../../../hooks/FlowActionHooks";
import { useHistory } from "react-router";
import { ColorPicker } from "../../ColorPicker";
import { PRESET_COLORS } from "../../../configs/Constants";
import { useGetUserFlows, useSetFlowNodes } from "../../../hooks/FlowHooks";
import { FlowNode } from "../../../types/FlowTypes";
import { useUpdateCard } from "../../../hooks/FlowCardHooks";
import { useAuthContext } from "../../../providers/AuthProvider";

interface NoteFields {
  id: string;
  data: {
    label: string;
    noteSection: NoteSection;
    toolbarVisible: boolean;
    toolbarPosition: Position;
    color?: string;
    isExpanded?: boolean;
  };
  selected: boolean;
}

const parser = new DOMParser();

const NoteCard = ({id, data, selected}: NoteFields) => {
  const {authUser} = useAuthContext();
  const history = useHistory();

  const {flowId} = useParams<{ flowId: string; }>();
  const {removeNode} = useFlowActions();
  const {getNode} = useReactFlow();

  const noteId = id.split("-")[0];

  const {data: userFlowsData, isLoading: userFlowsDataIsLoading, isError: userFlowsDataIsError} = useGetUserFlows(authUser?.$id);

  const setFlowNodesQuery = useSetFlowNodes();
  const {updateNodeColor} = useUpdateCard();

  const {data: noteData} = useGetNote(noteId);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const colorPickerPopover = useRef<HTMLIonPopoverElement>(null);
  const [colorPickerPopoverOpen, setColorPickerPopoverOpen] = useState(false);
  const [color, setColor] = useState<string>("");

  const [deleteConfirmationAlertOpen, setDeleteConfirmationAlertOpen] = useState<boolean>(false);

  const openPopover = (e: any) => {
    colorPickerPopover.current!.event = e;
    setColorPickerPopoverOpen(true);
  };

  const toggleExpansion = () => {
    // TODO: we might want to have some rate limiting or something so the user can't just smash the update button
    setExpanded(!expanded);

    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];

      if (fn) {
        const nodeToUpdate = fn.find((node) => node.targetId === `${noteId}-0`);

        if (nodeToUpdate) {
          const updatedNode = {...nodeToUpdate, isExpanded: !expanded};
          const flowNodes = fn.map((node) =>
            node.targetId === `${noteId}-0` ? updatedNode : node
          ) as FlowNode[];

          // Set it in the flow context
          data.isExpanded = !expanded;

          setFlowNodesQuery.mutate({flowId, flowNodes}, {
            onSuccess: (resp, args) => {
            }
          });
        }
      }
    }
  }

  const processHTML = (htmlText: string) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlText);
    const doc = parser.parseFromString(sanitizedHTML, 'text/html');

    const checkboxes = doc.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      // @ts-ignore
      checkbox.disabled = true;
    });

    return doc.documentElement.innerHTML;
  }

  const onDelete = () => {
    const nodeToDelete = getNode(id);
    if (nodeToDelete && flowId) {
      removeNode(nodeToDelete, flowId);
    }
  }

  useEffect(() => {
    if (data?.isExpanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }

    if (data.color) {
      setColor(data.color);
    } else {
      setColor("#F5F5F5")
    }
  }, [data.color, id]);

  const updateColor = (selectedColor: string) => {
    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];
      const nodeToUpdate = fn.find((node) => node.targetId === `${id}`);

      if (nodeToUpdate) {
        data.color = selectedColor;
        setColor(selectedColor);
        setColorPickerPopoverOpen(false);

        updateNodeColor(nodeToUpdate, flowId, selectedColor);
      }
    }
  };

  return (
    <>
      <Handle type="target" position={Position.Top} style={{opacity: 0}}/>
      <IonCard> {/*} style={{border: "2px #C63970"}}> <-- this has some issues with border */}
        <IonCardHeader style={{backgroundColor: color, height: "60px"}}>
        </IonCardHeader>
        <IonCardHeader
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
            textAlign: "center"
          }}>
          <IonCardTitle style={{
            fontSize: "300%",
            minWidth: "400px",
            maxWidth: "750px",
            overflowWrap: "break-word"
          }}>{data.label}</IonCardTitle>
        </IonCardHeader>
        <IonRow className="ion-justify-content-center">
          <IonButton onClick={toggleExpansion} fill={"clear"}>
            <IonIcon icon={expanded ? chevronUpCircleSharp : chevronDownCircleSharp} color={"medium"} size={"large"}/>
          </IonButton>
        </IonRow>
        {expanded &&
            <IonCardContent>
                <div
                    dangerouslySetInnerHTML={{__html: processHTML(noteData?.content?.substring(data.noteSection.contentStart as number, data.noteSection.contentEnd as number))}}/>
            </IonCardContent>
        }
      </IonCard>
      <IonPopover ref={colorPickerPopover} isOpen={colorPickerPopoverOpen} showBackdrop={false}
                  onDidDismiss={() => setColorPickerPopoverOpen(false)} size={"auto"} style={{"--min-width": "280px"}}>
        <IonContent scrollY={false}>
          <ColorPicker color={color} onChange={updateColor} presetColors={PRESET_COLORS}
                       hideFreeformColorPicker={true}/>
        </IonContent>
      </IonPopover>
      <Handle type="source" position={Position.Bottom} style={{opacity: 0}}/>
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition} style={{marginTop: "-10px"}}>
        <IonCard style={{border: "1px solid #ccc"}}>
          <IonButton size={"small"} fill={"clear"} onClick={() => setDeleteConfirmationAlertOpen(true)}><IonIcon
            icon={trashOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"} onClick={openPopover}><IonIcon
            icon={colorPaletteOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"} onClick={() => history.push(`/notes/item/${noteId}`)}><IonIcon
            icon={enterOutline} style={{color: "black"}}/></IonButton>
        </IonCard>
      </NodeToolbar>
      <IonAlert
        isOpen={deleteConfirmationAlertOpen}
        onDidDismiss={() => setDeleteConfirmationAlertOpen(false)}
        header={"Delete this card?"}
        subHeader={"This action is permanent and will also remove the connections to this card"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
            },
          },
          {
            text: "OK",
            handler: (data: any) => {
              onDelete();
            },
          }
        ]}
      />
    </>
  )
}

export default NoteCard;