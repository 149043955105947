import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonThumbnail,
  IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FlowNode } from "../../../types/FlowTypes";
import { useGetUserFlows, useSetFlowNodes } from "../../../hooks/FlowHooks";
import { buildResourceList } from "../../../helpers/ResourceHelpers";
import { ResourceItem } from "../../../types/ResourceTypes";

import "./Selector.css";
import { useReactFlow } from "reactflow";
import { getCoordinatesForNewNode } from "../../../utils/FlowChartUtils";
import { useAuthContext } from "../../../providers/AuthProvider";

interface FlowchartSelectorProps {
  flowId?: string;
  dismiss: CallableFunction;
}

const FlowchartSelector = ({flowId, dismiss}: FlowchartSelectorProps) => {
  const {authUser} = useAuthContext();

  const flowsQuery = useGetUserFlows(authUser?.$id);
  const setFlowNodes = useSetFlowNodes();

  const [flowItems, setFlowItems] = useState<ResourceItem[]>([]);
  const [flowNodeIds, setFlowNodeIds] = useState<Set<string>>(new Set());

  const {getViewport} = useReactFlow();

  // Search Bar
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchText("");
  }, [])

  useEffect(() => {
    if (flowsQuery.isSuccess && flowsQuery.data) {
      const flow = flowsQuery.data.find((f) => f.$id === flowId);
      if (flow) {
        const flowNodes = flow.nodes.map((n) => JSON.parse(n) as FlowNode).filter((fn) => fn.type === "flow");

        setFlowNodeIds(new Set(flowNodes.map((n) => n.targetId.split("-")[0])));
        setFlowItems(buildResourceList([], flowsQuery.data ?? [], []));
      }
    }
  }, [flowsQuery.dataUpdatedAt]);

  const onOptionClick = (item: ResourceItem) => {
    flowNodeIds.add(item.id);
    addNewFlow(item.id);
  }

  const addNewFlow = (selectedFlowId: string) => {
    if (flowsQuery.isSuccess && flowsQuery.data) {
      const currentFlowNodes: FlowNode[] = flowsQuery.data.find((f) => f.$id === flowId)!.nodes.map((n) => JSON.parse(n) as FlowNode);

      const {x: canvasX, y: canvasY} = getCoordinatesForNewNode(getViewport())

      const newFlowNode: FlowNode = {
        type: "flow",
        targetId: selectedFlowId,
        x: canvasX,
        y: canvasY,
        creationTime: new Date().toISOString(),
      }
      console.debug(newFlowNode);

      setFlowNodes.mutate({flowId: flowId!, flowNodes: [...currentFlowNodes, newFlowNode]}, {
        onSuccess: (v) => dismiss(),
      });
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar mode={"ios"} placeholder={"Search Flowchart Titles"} value={searchText}
                        onIonInput={(e) => setSearchText(e.detail.value!)}
                        style={{paddingTop: "15px"}}/>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollY={true}>
        <IonList>
          {flowItems
            .filter((item: ResourceItem) => !flowNodeIds.has(item.id)) // don't include it if it's already in the flow
            .filter((item: ResourceItem) => item.id !== flowId) // don't add self
            .filter((item: ResourceItem) => {
              if (searchText && searchText !== "") {
                return item.title.includes(searchText.toLowerCase());
              } else {
                return true;
              }
            })
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map((item: ResourceItem) => (
              <IonItem key={item.id} onClick={() => onOptionClick(item)}>
                <IonThumbnail slot="start" className="centered-thumbnail">
                  <IonIcon icon={item.icon} size={"large"}/>
                </IonThumbnail>
                <div className="label-container">
                  <IonLabel style={{fontSize: "x-small"}}>{new Date(item.createdAt).toLocaleDateString()}</IonLabel>
                  <IonLabel class="ion-text-wrap">{item.title}</IonLabel>
                </div>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </>
  )
}

export default FlowchartSelector;