import { Redirect, Route, useHistory } from 'react-router-dom';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRouterOutlet,
  IonRow,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
} from '@ionic/react';
import {
  addCircle,
  createOutline,
  gitNetworkOutline,
  gridOutline,
  imageOutline,
  imagesOutline,
  searchOutline,
  settingsOutline
} from 'ionicons/icons';
import Dashboard from './Dashboard';
import { ReactFlowProvider } from 'reactflow';
import FlowMain from './FlowMain';
import React, { useRef } from 'react';

import "./Tabs.css";

// TODO: turn this into provider
import { useCreateNote, useGetUserNotes } from '../hooks/NotesHooks';
// import {Flow} from "../types/FlowTypes";
import { Models } from "appwrite";
import { NavSource } from "../types/NavEnums";
import { useCreateFlow, useGetUserFlows } from "../hooks/FlowHooks";
import { marshalNoteSection, Note } from "../types/NoteTypes";
import { getNextUntitledNumber } from "../utils/ResourceUtils";
import { mapNoteContentToNoteSection, populateSectionContent } from "../utils/NoteUtils";
import Media from "./Media";
import MediaUploader from "../components/media/MediaUploader";
import { FlowFields } from "../types/FlowTypes";
import Tags from "./Tags";
import Search from "./Search";
import Settings from "./Settings";
import { useAuthContext } from "../providers/AuthProvider";
import { SentryRoute } from "../App";
import UserFeedback from "./UserFeedback";

const Tabs: React.FC = () => {
  const {authUser} = useAuthContext();
  const notesData = useGetUserNotes(authUser?.$id);

  const createFlow = useCreateFlow();
  const flowsQuery = useGetUserFlows(authUser?.$id);
  const createNote = useCreateNote();

  const history = useHistory();

  const createModal = useRef<HTMLIonModalElement>(null);
  const importModal = useRef<HTMLIonModalElement>(null);

  function dismissCreateModal() {
    createModal.current?.dismiss();
  }

  function dismissImportModal() {
    importModal.current?.dismiss();
  }

  const createNewNote = () => {
    // We want to set up the (?) number for Untitled Notes
    const nextUntitledNoteNumber = getNextUntitledNumber(notesData.data, /^Untitled Note \((\d+)\)$/);

    const newNote: Note = {
      ownerId: authUser?.$id,
      isGlobal: true,
      type: "",
      name: `Untitled Note (${nextUntitledNoteNumber})`,
      content: undefined,
      date: (new Date()).toISOString(),
    };

    const noteSections = mapNoteContentToNoteSection(newNote);
    populateSectionContent(noteSections, newNote);
    newNote.noteSections = marshalNoteSection(noteSections);

    createNote.mutate(newNote, {
      onSuccess: (data: Models.Document) => {
        history.push(`/notes/item/${data.$id}?source=${NavSource.CREATE}`);
      },
      onError: (e) => console.error(e),
    });
  }

  const createNewFlow = () => {
    // We want to set up the (?) number for Untitled Flowcharts
    const nextUntitledFlowNumber = getNextUntitledNumber(flowsQuery.data, /^Untitled Flowchart \((\d+)\)$/);

    const newFlow: FlowFields = {
      nodes: [],
      connectionData: [],
      name: `Untitled Flowchart (${nextUntitledFlowNumber})`,
      description: undefined, // unused for now
      ownerId: authUser?.$id,
      tags: [], //data.tags,
    };

    createFlow.mutate(newFlow, {
      onSuccess: (data: Models.Document) => {
        history.push(`/flows/item/${data.$id}?source=${NavSource.CREATE}`);
      },
      onError: (e) => console.error(e),
    });
  };

  // @ts-ignore
  return (
    <IonContent>
      <IonTabs>
        <IonRouterOutlet>
          <Redirect exact path="/tabs" to="/tabs/main"/>
          <SentryRoute exact path="/tabs/main">
            <Dashboard/>
          </SentryRoute>
          <SentryRoute exact path="/tabs/flow">
            <ReactFlowProvider>
              <FlowMain/>
            </ReactFlowProvider>
          </SentryRoute>
          <SentryRoute path={"/tabs/media"}>
            <Media/>
          </SentryRoute>
          <SentryRoute path={"/tabs/tags"}>
            <Tags/>
          </SentryRoute>
          <SentryRoute path={"/tabs/search"}>
            <Search/>
          </SentryRoute>
          <SentryRoute exact path={"/tabs/settings"}>
            <Settings/>
          </SentryRoute>
          <SentryRoute path={"/tabs/settings/feedback"}>
            <UserFeedback/>
          </SentryRoute>
          <SentryRoute exact path="/tabs">
            <Redirect to="/tabs/main"/>
          </SentryRoute>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tabMain" href="/tabs/main">
            <IonIcon icon={gridOutline}/>
          </IonTabButton>
          <IonTabButton tab="tabMedia" href="/tabs/media">
            <IonIcon icon={imagesOutline}/>
          </IonTabButton>
          <IonTabButton tab="tabCreate" onClick={() => createModal.current?.present()}>
            <IonIcon className="large-tab-button" color={"primary"} icon={addCircle}/>
          </IonTabButton>
          <IonTabButton tab="tabNotes" href="/tabs/search">
            <IonIcon icon={searchOutline}/>
          </IonTabButton>
          <IonTabButton tab="tabSettings" href="/tabs/settings">
            <IonIcon icon={settingsOutline}/>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <IonModal
        className="tabs-modal"
        id="create-modal"
        ref={createModal}
        // trigger="open-create-modal"
        breakpoints={[0, 0.6, 0.8]}
        initialBreakpoint={0.6}
        showBackdrop={true}
        backdropDismiss={true}
        handle={false}
      >
        <IonContent>
          <IonCard style={{marginTop: "10px", boxShadow: "none"}}>
            <IonCardContent>
              <IonGrid>
                <IonRow onClick={() => {
                  dismissCreateModal();
                  createNewNote();
                }}>
                  <IonCol size="2">
                    <IonIcon color={"primary"}  className="create-option" icon={createOutline} size={"large"}/>
                  </IonCol>
                  <IonCol>
                    <IonRow><IonText className="create-option">New Note</IonText></IonRow>
                    <IonRow><IonText>Create references to technique</IonText></IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard style={{marginTop: "-15px", boxShadow: "none"}}>
            <IonCardContent>
              <IonGrid>
                <IonRow onClick={() => {
                  dismissCreateModal();
                  createNewFlow();
                }}>
                  <IonCol size="2">
                    <IonIcon color={"primary"}  className="create-option" icon={gitNetworkOutline} size={"large"}/>
                  </IonCol>
                  <IonCol>
                    <IonRow><IonText className="create-option">New Flowchart</IonText></IonRow>
                    <IonRow><IonText>Connect cards to build flowcharts</IonText></IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard style={{marginTop: "-15px", boxShadow: "none"}}>
            <IonCardContent>
              <IonGrid>
                <IonRow onClick={() => {
                  dismissCreateModal();
                  importModal.current?.present();
                }}>
                  <IonCol size="2">
                    <IonIcon color={"primary"} className="create-option" icon={imageOutline} size={"large"}/>
                  </IonCol>
                  <IonCol>
                    <IonRow><IonText className="create-option">Import Media</IonText></IonRow>
                    <IonRow><IonText>Import your photos and videos</IonText></IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
      <IonModal id="import-modal" ref={importModal} draggable={true}>
        <MediaUploader maxImageFileSize={5 * 1024 * 1024} dismiss={dismissImportModal}/>
      </IonModal>
    </IonContent>
  );
};

export default Tabs;