import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonPopover,
  IonRow,
  IonToolbar
} from '@ionic/react';
import { Editor } from '@tiptap/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont, faImages, faList, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import FontSubMenu from './FontSubMenu';
import ListSubMenu from './ListSubMenu';

import "./EditorMenuBar.css";
import MediaGallery from "../media/MediaGallery";
import { MediaFields } from "../../types/MediaType";
import React from "react";

export interface MenuBarFields {
  editor: Editor;
}

const EditorMenuBar = ({editor}: MenuBarFields) => {

  const mediaSelectModal = React.useRef<HTMLIonModalElement>(null);
  const linkInsertModal = React.useRef<HTMLIonModalElement>(null);

  function dismissUploadModal() {
    mediaSelectModal.current?.dismiss();
  }

  if (!editor) {
    return null;
  }

  return (
    <>
      <IonToolbar>
        <IonRow>
          <IonCol class="ion-justify-content-start">
            {/* Font Style */}
            <IonButton
              id="font-expand-trigger"
              size="default"
              color="light"
            >
              <FontAwesomeIcon icon={faFont}/>
            </IonButton>
            {/* List */}
            <IonButton
              id="list-expand-trigger"
              size="default"
              color="light"
            >
              <FontAwesomeIcon icon={faList}/>
            </IonButton>
            {/* Media */}
            <IonButton
              size="default"
              color="light"
              id="open-media-select-modal"
            >
              <FontAwesomeIcon icon={faImages}/>
            </IonButton>
            {/* Link */}
            {/*}
            <IonButton
              size="default"
              color="light"
              id="open-insert-link-modal"
            >
              <FontAwesomeIcon icon={faLink} />
            </IonButton>
            */}
            {/* Line Break */}
            {/*
            <IonButton
              size="default"
              color="light"
              onClick={() => editor.chain().focus().setHorizontalRule().run()}
            >
              <FontAwesomeIcon icon={faMinus} />
            </IonButton>
            */}
            {/* Clear Style */}
            {/*
            <IonButton
              size="default"
              color="light"
              onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
            >
              <FontAwesomeIcon icon={faTextSlash}/>
            </IonButton>
            */}
          </IonCol>
          <IonCol size="auto" class="ion-justify-content-end">
            <IonButton
              size="default"
              color="light"
              onClick={() => editor.chain().focus().undo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .undo()
                  .run()
              }
            >
              <FontAwesomeIcon icon={faUndo}/>
            </IonButton>
            <IonButton
              size="default"
              color="light"
              onClick={() => editor.chain().focus().redo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .redo()
                  .run()
              }
            >
              <FontAwesomeIcon icon={faRedo}/>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonToolbar>
      <IonPopover
        className="expand-options"
        trigger="font-expand-trigger"
        triggerAction="click"
        size="auto"
        showBackdrop={false}
        reference="trigger"
        keyboardClose={false}
        keepContentsMounted={true}
        alignment="end"
        side="bottom"
        translucent={false}
        dismissOnSelect={true}
      >
        <IonContent>
          <FontSubMenu editor={editor}/>
        </IonContent>
      </IonPopover>
      <IonPopover
        className="expand-options"
        trigger="list-expand-trigger"
        triggerAction="click"
        size="auto"
        showBackdrop={false}
        reference="trigger"
        keyboardClose={false}
        keepContentsMounted={true}
        alignment="end"
        side="bottom"
        translucent={false}
        dismissOnSelect={true}
      >
        <IonContent>
          <ListSubMenu editor={editor}/>
        </IonContent>
      </IonPopover>
      <IonModal ref={mediaSelectModal} trigger="open-media-select-modal" canDismiss={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={"end"}>
              <IonButton fill={"clear"} onClick={() => dismissUploadModal()}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {/* @ts-ignore */}
        <MediaGallery mediaClickAction={
          (mediaData: MediaFields) => {
            if (mediaData.type === "image") {
              editor.chain().focus().setImage({src: mediaData.url}).run();
            } else if (mediaData.type === "youtube") {
              editor.commands.setYoutubeVideo({src: mediaData.url});
            }
          }
        }/>
      </IonModal>
      {/*
      <IonModal ref={linkInsertModal} trigger="open-insert-link-modal" canDismiss={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={"end"}>
              <IonButton fill={"clear"} onClick={() => linkInsertModal.current?.dismiss()}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      </IonModal>
      */}
    </>
  );
};

export default EditorMenuBar;