import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonThumbnail,
  IonToolbar
} from "@ionic/react";
import { useGetUserMedia } from "../../../hooks/MediaHooks";
import React, { useEffect, useRef, useState } from "react";
import { FlowNode } from "../../../types/FlowTypes";
import { useGetUserFlows, useSetFlowNodes } from "../../../hooks/FlowHooks";
import { buildResourceList } from "../../../helpers/ResourceHelpers";
import { ResourceItem } from "../../../types/ResourceTypes";

import "./Selector.css";
import { DBMedia, VideoBookmark } from "../../../types/MediaType";
import VideoBookmarkSelection from "../../media/VideoBookmarkSelection";
import { useReactFlow } from "reactflow";
import { getCoordinatesForNewNode } from "../../../utils/FlowChartUtils";
import { useAuthContext } from "../../../providers/AuthProvider";

interface VideoSelectorProps {
  flowId?: string;
  dismiss: CallableFunction;
}

const VideoSelector = ({flowId, dismiss}: VideoSelectorProps) => {
  const {authUser} = useAuthContext();

  const mediaQuery = useGetUserMedia(authUser?.$id);
  const flowsQuery = useGetUserFlows(authUser?.$id);

  const setFlowNodes = useSetFlowNodes();

  const {getViewport} = useReactFlow();

  // @ts-ignore
  const [dbVideo, setDbVideo] = useState<Map<string, DBMedia>>(new Map());
  const [videoItems, setVideoItems] = useState<ResourceItem[]>([]);
  const [flowNodeIds, setFlowNodeIds] = useState<Set<string>>(new Set());

  // Search Bar
  const [searchText, setSearchText] = useState<string>("");

  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  useEffect(() => {
    setSearchText("");
  }, [])

  useEffect(() => {
    if (flowsQuery.isSuccess && flowsQuery.data) {
      const flow = flowsQuery.data.find((f) => f.$id === flowId);
      if (flow) {
        const flowNodes = flow.nodes.map((n) => JSON.parse(n) as FlowNode).filter((fn) => fn.type === "youtube" || fn.type === "video");
        setFlowNodeIds(new Set(flowNodes.map((n) => n.targetId)));
      }
    }
  }, [flowsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (mediaQuery.isSuccess) {
      // @ts-ignore
      setDbVideo(new Map(mediaQuery.data.filter((v: DBMedia) => v.type === "youtube" || v.type === "video").map((v: DBMedia) => [v.$id, v]) ?? []) ?? new Map());
      setVideoItems(buildResourceList([], [], mediaQuery.data.filter((v: DBMedia) => v.type === "youtube" || v.type === "video") ?? []));
    }
  }, [mediaQuery.dataUpdatedAt]);

  const addNewVideo = (item: ResourceItem, vb?: VideoBookmark) => {
    const selectedMediaId = item.id;
    if (mediaQuery.isSuccess && mediaQuery.data) {
      const mediaId = selectedMediaId.split("-")[0];

      const {x: canvasX, y: canvasY} = getCoordinatesForNewNode(getViewport())

      const newFlowNode: FlowNode = {
        type: "youtube",
        targetId: selectedMediaId,
        x: canvasX,
        y: canvasY,
        label: vb?.description && vb.description !== "" ? vb.description : undefined, // persist the bookmark description here
        creationTime: new Date().toISOString(),
      }

      if (flowsQuery.isSuccess && flowsQuery.data) {
        console.debug(newFlowNode);

        const currentFlowNodes: FlowNode[] = flowsQuery.data.find((f) => f.$id === flowId)!.nodes.map((n) => JSON.parse(n) as FlowNode);
        setFlowNodes.mutate({flowId: flowId!, flowNodes: [...currentFlowNodes, newFlowNode]}, {
          onSuccess: (v) => dismiss(),
        });
      }
    }
  }

  const handleVideoBookmarkSelection = (mediaData: DBMedia, vb: VideoBookmark | undefined) => {
    let bid = "";
    //if (showBookmarkSelection.selectedItem) {
    //  const selectedItem = showBookmarkSelection.selectedItem as DBMedia;

    // Special post-fix for video bookmarks so we can one-to-many in the canvas
    if (vb) {
      bid = mediaData.$id + "-" + vb.seconds;
    } else {
      bid = mediaData.$id + "-";
    }

    if (bid !== "") {
      const resourceItem: ResourceItem = {
        id: bid,
        title: vb?.description ?? "Video Bookmark",
        type: "media",
        subtype: "youtube",
        createdAt: mediaData.$createdAt,
        modifiedAt: mediaData.$updatedAt,
        thumbnail: mediaData.thumbnail,
        icon: "videocam-outline",
      }

      flowNodeIds.add(bid);
      addNewVideo(resourceItem, vb);
    }
    //}
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar mode={"ios"} placeholder={"Search Videos"} value={searchText}
                        onIonInput={(e) => setSearchText(e.detail.value!)}
                        style={{paddingTop: "15px"}}/>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollY={true}>
        <IonAccordionGroup ref={accordionGroup}>
          {videoItems
            .filter((item: ResourceItem) => !flowNodeIds.has(item.id)) // don't include it if it's already in the flow
            .filter((item: ResourceItem) => {
              if (searchText && searchText !== "") {
                return item.metadata?.includes(searchText.toLowerCase());
              } else {
                return true;
              }
            })
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            .map((item: ResourceItem) => (
              <IonAccordion key={item.id}>
                <IonItem slot="header">
                  <IonThumbnail slot="start" className="centered-thumbnail small-thumbnail">
                    <picture>
                      {item.thumbnail?.webp_url &&
                          <source srcSet={`${item.thumbnail?.webp_url}`} type="image/webp"/>
                      }
                      <img src={`${item.thumbnail?.url}`} alt={item.title}/>
                    </picture>
                  </IonThumbnail>
                  <div className="label-container">
                    <IonLabel style={{fontSize: "x-small"}}>{new Date(item.createdAt).toLocaleDateString()}</IonLabel>
                    <IonLabel class="ion-text-wrap">{item.subtitle}</IonLabel>
                  </div>
                </IonItem>
                <div slot="content">
                  <VideoBookmarkSelection mediaData={dbVideo.get(item.id)!}
                                          existingFlowIds={flowNodeIds}
                                          onClickAction={handleVideoBookmarkSelection}
                  />
                </div>
              </IonAccordion>
            ))}
        </IonAccordionGroup>
      </IonContent>
    </>
  )
}

export default VideoSelector;