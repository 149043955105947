import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonThumbnail,
  IonToolbar
} from "@ionic/react";
import {
  arrowDown,
  arrowUp,
  checkmark,
  chevronDown,
  filter,
  gridOutline,
  gridSharp,
  imageOutline,
  listSharp,
  videocamOutline
} from "ionicons/icons";
import React from "react";

import "./MediaGallery.css";
import { useGetUserMedia } from "../../hooks/MediaHooks";
import { DBMedia, MediaFields } from "../../types/MediaType";
import { useHistory } from "react-router";
import { SortFields, SortMode, SortOrder } from "../../types/FilterTypes";
import { useAuthContext } from "../../providers/AuthProvider";

enum MediaDisplayMode {
  GRID,
  LIST,
}


interface MediaGalleryFields {
  mediaClickAction?: (mediaData: MediaFields) => void;
}

const MediaGallery: React.FC = ({mediaClickAction}: MediaGalleryFields) => {
  const history = useHistory();
  const {authUser} = useAuthContext();

  const [displayMode, setDisplayMode] = React.useState<MediaDisplayMode>(MediaDisplayMode.GRID);
  const [filterMode, setFilterMode] = React.useState<string>("All");
  const [sortMode, setSortMode] = React.useState<SortFields>({
    mode: SortMode.CREATION_TIME,
    order: SortOrder.DESCENDING
  });

  const userMedia = useGetUserMedia(authUser?.$id);

  const handleSortModeChange = (mode: SortMode) => {
    let order = SortOrder.DESCENDING;
    if (mode === sortMode.mode) {
      order = sortMode.order === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    }

    setSortMode({
      mode: mode,
      order: order,
    })
  }

  const filterAndSortData = (data?: DBMedia[]) => {
    if (!data) return [];

    return data.filter(
      (data: DBMedia) => {
        if (filterMode === "All") {
          return true;
        } else if (filterMode === "Images") {
          return data.type === "image";
        } else if (filterMode === "Videos") {
          return data.type === "youtube" || data.type === "video";
        } else {
          return true;
        }
      }
    ).sort(
      (a: DBMedia, b: DBMedia) => {
        if (sortMode.mode === SortMode.CREATION_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.$createdAt > b.$createdAt ? -1 : 1;
          } else {
            return a.$createdAt < b.$createdAt ? -1 : 1;
          }
        } else if (sortMode.mode === SortMode.UPDATE_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.$updatedAt > b.$updatedAt ? -1 : 1;
          } else {
            return a.$updatedAt < b.$updatedAt ? -1 : 1;
          }
        } else {
          return 0; // Return 0 if neither creation time nor update time is selected for sorting
        }
      }
    )
  }

  return (
    <>
      <IonToolbar>
        <IonButtons slot={"start"}>
          <IonChip id={"trigger-media-type-filter-popover"}><IonIcon icon={
            filterMode === "All" ? gridOutline : filterMode === "Images" ? imageOutline : filterMode === "Videos" ? videocamOutline : gridOutline
          }/>{filterMode}<IonIcon icon={chevronDown}/></IonChip>
          <IonChip id={"trigger-media-sort-popover"}><IonIcon icon={filter}/>Sort<IonIcon icon={chevronDown}/></IonChip>
        </IonButtons>
        <IonButtons slot={"end"}>
          <IonButton
            onClick={() => setDisplayMode(displayMode === MediaDisplayMode.GRID ? MediaDisplayMode.LIST : MediaDisplayMode.GRID)}>
            <IonChip><IonIcon style={{paddingRight: "11px"}}
                              icon={displayMode === MediaDisplayMode.GRID ? gridSharp : listSharp}/></IonChip>
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {/* Filter Media Type */}
      <IonPopover trigger={"trigger-media-type-filter-popover"} dismissOnSelect={true}>
        <IonList>
          <IonItem button onClick={() => setFilterMode("All")} lines={"none"} detail={false}>
            <IonIcon icon={gridOutline} style={{paddingRight: "10px"}}/><IonLabel>All</IonLabel>{filterMode === "All" &&
              <IonIcon icon={checkmark}/>}
          </IonItem>
          <IonItem button onClick={() => setFilterMode("Images")} lines={"none"} detail={false}>
            <IonIcon icon={imageOutline}
                     style={{paddingRight: "10px"}}/><IonLabel>Images</IonLabel>{filterMode === "Images" &&
              <IonIcon icon={checkmark}/>}
          </IonItem>
          <IonItem button onClick={() => setFilterMode("Videos")} lines={"none"} detail={false}>
            <IonIcon icon={videocamOutline}
                     style={{paddingRight: "10px"}}/><IonLabel>Videos</IonLabel>{filterMode === "Videos" &&
              <IonIcon icon={checkmark}/>}
          </IonItem>
        </IonList>
      </IonPopover>
      {/* Filter Sorting */}
      <IonPopover trigger={"trigger-media-sort-popover"} dismissOnSelect={true}>
        <IonList>
          <IonItem button onClick={() => handleSortModeChange(SortMode.CREATION_TIME)} lines={"none"} detail={false}>
            <IonLabel>Created</IonLabel>{sortMode.mode === SortMode.CREATION_TIME ? (
            sortMode.order === SortOrder.DESCENDING ? (
              <IonIcon icon={arrowDown}/>
            ) : (
              <IonIcon icon={arrowUp}/>
            )
          ) : (
            <></>
          )}
          </IonItem>
          <IonItem button onClick={() => handleSortModeChange(SortMode.UPDATE_TIME)} lines={"none"} detail={false}>
            <IonLabel>Last Modified</IonLabel>{sortMode.mode === SortMode.UPDATE_TIME ? (
            sortMode.order === SortOrder.DESCENDING ? (
              <IonIcon icon={arrowDown}/>
            ) : (
              <IonIcon icon={arrowUp}/>
            )
          ) : (
            <></>
          )}
          </IonItem>
        </IonList>
      </IonPopover>
      <IonContent>
        {userMedia?.data?.length === 0 && (
          <div className="ion-padding" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}>
            <IonImg style={{height: "50vh", width: "80vw"}}
                    src={process.env.PUBLIC_URL + "/assets/app/media-placeholder.png"}
                    alt={"Your media shows up here!"}/>
            <IonLabel style={{
              fontSize: 'small',
              color: '#777',
              textAlign: 'center',
              marginTop: '10px'
            }}>Your imported media will show up here! Get started by selecting ➕ in the bottom of the screen.</IonLabel>
            {/* Hard to hook it up now /*}
            {/*
            <IonButton style={{marginTop: "10px"}}>Import Media</IonButton>
            */}
          </div>
        )}
        {displayMode === MediaDisplayMode.GRID && (
          <IonGrid style={{ display: 'flex', flexWrap: 'wrap' }}>
              {filterAndSortData(userMedia?.data).map((data: DBMedia) => (
                <IonCol size="4" key={data.$id}>
                  <div className="media-container">
                  {data.type === "image" &&
                      <picture onClick={() => {
                        if (mediaClickAction) {
                          mediaClickAction(data);
                        } else {
                          history.push(`/media/${data.$id}`);
                        }
                      }}>
                          <source srcSet={`${data.thumbnailUrl}&quality=50&output=webp`} type="image/webp"/>
                          <img src={`${data.thumbnailUrl}&quality=50&output=jpg`} alt={data.description} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                      </picture>
                  }
                  {data.type === "youtube" &&
                      <IonImg src={data.thumbnailUrl} alt={data.description}
                              onClick={() => {
                                if (mediaClickAction) {
                                  mediaClickAction(data);
                                } else {
                                  history.push(`/media/${data.$id}`);
                                }
                              }}
                      ></IonImg>
                  }
                  </div>
                </IonCol>
              ))}
          </IonGrid>
        )}
        {displayMode === MediaDisplayMode.LIST && (
          <IonList>
            {filterAndSortData(userMedia?.data).map((data: DBMedia) => (
              <IonItem key={data.$id} onClick={() => {
                if (mediaClickAction) {
                  mediaClickAction(data);
                } else {
                  history.push(`/media/${data.$id}`);
                }
              }}>
                {data.type === "image" &&
                    <IonThumbnail slot="start">
                        <picture>
                            <source srcSet={`${data.thumbnailUrl}&quality=50&output=webp`} type="image/webp"/>
                            <img src={`${data.thumbnailUrl}&quality=50&output=jpg`} alt={data.description}/>
                        </picture>
                    </IonThumbnail>
                }
                {data.type === "youtube" &&
                    <IonThumbnail slot="start">
                        <IonImg src={data.thumbnailUrl} alt={data.description}></IonImg>
                    </IonThumbnail>
                }
                <IonLabel>{data.description}</IonLabel>
              </IonItem>))}
          </IonList>
        )}
      </IonContent>
    </>
  )
}

export default MediaGallery;