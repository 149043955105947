import {faListCheck, faListOl, faListUl} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IonButton} from "@ionic/react";
import {MenuBarFields} from "./EditorMenuBar";

const ListSubMenu = ({editor}: MenuBarFields) => {
  return (
    <>
      {/* Bullet List */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('bulletList') ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FontAwesomeIcon icon={faListUl}/>
      </IonButton>
      {/* Ordered List */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('orderedList') ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FontAwesomeIcon icon={faListOl}/>
      </IonButton>
      {/* Task List */}
      <IonButton
        size="default"
        color="medium"
        fill={editor.isActive('taskList') ? "outline" : "solid"}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
      >
        <FontAwesomeIcon icon={faListCheck}/>
      </IonButton>
    </>
  );
};

export default ListSubMenu;