import { IonButton, IonCard, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import React from "react";

import "./OAuthOption.css";
import { useHistory } from "react-router";
import { useAuthContext } from "../../providers/AuthProvider";

const OAuthOption: React.FC = () => {

  const {logInWithGoogle} = useAuthContext();
  const history = useHistory();

  const handleLogIn = async () => {
    await logInWithGoogle()
  }

  return (
    <>
      <div className="line-container ion-padding-horizontal">
        <span className="line"></span>
        <IonLabel className="small-text">OR</IonLabel>
        <span className="line"></span>
      </div>
      <IonCard mode="ios" className="login-oauth-card login-card">
        <IonRow>
          <IonCol sizeMd="6" offsetMd="3" sizeXs="12">
            <IonButton expand="block" className="ion-text-capitalize google-button" onClick={() => handleLogIn()}>
              <IonIcon icon={logoGoogle} slot="start"/>
              <IonLabel className="google-label">Continue with Google</IonLabel>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCard>
    </>
  )
}

export default OAuthOption;