import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { useHistory, useLocation } from "react-router";

import './Auth.css';
import LogoHeader from "../../components/auth/LogoHeader";
import AuthCard from "../../components/auth/AuthCard";
import UserPolicy from "../../components/auth/UserPolicy";
import { useAuthContext } from "../../providers/AuthProvider";

const PasswordReset: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<{ message: string; color: string }>({ message: '', color: 'danger' });

  const { setPasswordRecovery } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userId = searchParams.get('userId');
  const secret = searchParams.get('secret');

  const handleReset = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setToast({ ...toast, message: '' });

    if (!userId || !secret) {
      setToast({ message: 'Invalid password reset link. Please reload the page from your e-mail.', color: 'danger' });
      return;
    }

    if (!password || password.length < 6) {
      setToast({ message: 'Password must be at least 6 characters.', color: 'danger' });
      return;
    }

    if (password !== confirmPassword) {
      setToast({ message: 'Passwords do not match.', color: 'danger' });
      return;
    }

    setShowLoading(true);

    try {
      await setPasswordRecovery(userId!, secret!, password);
      history.replace('/login');
    } catch (error: any) {
      console.error("Password Reset Error:", error);
      setToast({ message: error.message, color: 'danger' });
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size="auto">
              <LogoHeader />
            </IonCol>
          </IonRow>
          <AuthCard>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Password"
                type="password"
                value={password}
                onIonInput={(e) => setPassword(e.detail.value!)}
                required
              />
            </IonItem>
            <IonItem>
              <IonInput
                labelPlacement="floating"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onIonInput={(e) => setConfirmPassword(e.detail.value!)}
                required
              />
            </IonItem>
            <IonButton style={{marginTop: "30px"}} expand="block" onClick={handleReset}>
              Reset Password
            </IonButton>
          </AuthCard>
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={toast.message !== ''}
        onDidDismiss={() => setToast({ ...toast, message: '' })}
        message={toast.message}
        duration={3000}
        color={toast.color}
      />
    </IonPage>
  );
};

export default PasswordReset;
