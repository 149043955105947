import { MarkerType } from "reactflow";

export const DEFAULT_NOTES_STALE_TIME = 600000; // 10 minutes

export const DEFAULT_FLOWS_STALE_TIME = 600000; // 10 minutes

export const DEFAULT_CONNECTIONS_STALE_TIME = 600000; // 10 minutes

export const DEFAULT_MEDIA_STALE_TIME = 600000; // 10 minutes

export const FLOW_RATE_LIMIT_MS = 300; // 0.3 seconds

export const PAGINATION_SIZE = 5000;


const allHexColors = [
  // BJJ Adult Belt Colors
  "#F5F5F5", // White Belt
  "#85C1E9", // Blue Belt (Light Blue)
  "#2874A6", // Blue Belt (Dark Blue)
  "#D7BDE2", // Purple Belt (Lavender)
  "#A569BD", // Purple Belt (Plum)
  "#8E735B", // Brown Belt (Tan)
  "#A93226", // Brown Belt (Sienna)
  "#333333", // Black Belt
  "#E74C3C", // Red Belt

  // Judo Belt Colors
  "#F7DC6F", // Yellow Belt (Judo)
  "#2ECC71", // Green Belt (Judo)
  "#82E0AA", // Kids Green Belt
  "#F1C40F", // Orange Belt (Judo)
  // "#9B59B6", // Violet Belt (Judo)
  // "#8E44AD", // Purple Belt (Judo)
  // "#3498DB", // Blue Belt (Judo)
  "#E67E22", // Brown Belt (Judo)
  // "#D35400", // Dark Brown Belt (Judo)
  // "#C0392B", // Red Belt (Judo)
  // "#EC7063", // Light Red Belt (Judo)

  // Kids Belt Colors (BJJ and Judo)
  "#F5B7B1", // Kids White Belt
  // "#F0E68C", // Kids Yellow Belt

  "#A9A9A9", // Kids Gray Belt
  // "#BB8FCE"  // Kids Purple Belt
];


export const PRESET_COLORS = allHexColors;// ["#F5F5F5","#85C1E9","#2874A6","#D7BDE2","#78281F","#D5DBDB","#A93226","#333333","#E74C3C","#F7DC6F"] // ["#cd9323", "#1a53d8", "#9a2151", "#0d6416", "#8d2808"];

export const DEFAULT_CONNECTION_STYLE = {
  animated: false,
  deletable: false,
  style: {
    strokeWidth: 5,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 12,
    height: 12,
  },
  type: "flowEdge",
};

export const MAX_MEDIA_DESCRIPTION_LENGTH = 150;