import { generateImageTitle, generateYoutubeTitle, ResourceItem, Thumbnail } from "../types/ResourceTypes";
import { Models } from "appwrite";
import { DBFlow } from "../types/FlowTypes";
import { DBMedia, VideoAdditionalData } from "../types/MediaType";
import { documentTextOutline, gitNetworkOutline, imageOutline, logoYoutube } from "ionicons/icons";

// Combine all resources into a single list
export const buildResourceList = (notesData: Models.Document[], flowsData: DBFlow[], mediaData: DBMedia[]): ResourceItem[] => {
  const selectionItems: ResourceItem[] = [];

  notesData.forEach((note) => {

    let noteMetadata = "";
    if (note.name) {
      noteMetadata += note.name.replace (/\(|\)/g, '') + " ";
    }
    if (note.description) {
      noteMetadata += note.description + " ";
    }
    if (note.content) {
      noteMetadata += note.content.replace(/< (.|n)*?>/g, '').replace(/ [^\\p {L}\\s]/gu, "");
    }

    selectionItems.push({
      id: note.$id,
      title: note.name,
      type: "note",
      createdAt: note.$createdAt,
      modifiedAt: note.$updatedAt,
      icon: documentTextOutline,
      metadata: noteMetadata.toLowerCase(),
    });
  });
  flowsData.forEach((flow: DBFlow) => {
    const metadata = flow.name.replace (/\(|\)/g, '') + " " + (flow.description ? flow.description : "");
    selectionItems.push({
      id: flow.$id,
      title: flow.name,
      type: "flow",
      createdAt: flow.$createdAt,
      modifiedAt: flow.$updatedAt,
      icon: gitNetworkOutline,
      metadata: metadata.toLowerCase(),
    })
  });
  mediaData.forEach((media: DBMedia) => {
    let id: string = "";
    let icon: string = "";
    let title: string = "";
    let subtitle: string = "";
    let thumbnail: Thumbnail = {url: "https://picsum.photos/id/237/200/300"}; // placeholder image
    let metadata = "";

    if (media.type === "image") {
      icon = imageOutline;
      title = generateImageTitle(media);
      subtitle = media.description ? media.description : "";
      thumbnail = {
        url: `${media.thumbnailUrl!}&quality=50&output=jpg`,
        webp_url: `${media.thumbnailUrl!}&quality=50&output=webp`,
      };
      id = media.$id;
      metadata = media.description ? media.description + " " : "";
    } else if (media.type === "youtube") {
      icon = logoYoutube;
      title = generateYoutubeTitle(media);
      subtitle = media.description ? media.description : "";
      thumbnail = {
        url: `${media.thumbnailUrl!}`,
      }
      id = media.$id;

      metadata += media.description ? media.description + " " : "";
      if (media.additionalData) {
        const additionalData = JSON.parse(media.additionalData) as VideoAdditionalData;
        if (additionalData && additionalData.videoBookmarks) {
          additionalData.videoBookmarks.map((bookmark) => {
            metadata += bookmark.description ? bookmark.description + " " : "";
          })
        }
      }
    }

    selectionItems.push({
      id: id,
      title: title,
      subtitle: subtitle,
      type: "media",
      subtype: media.type,
      createdAt: media.$createdAt,
      modifiedAt: media.$updatedAt,
      thumbnail: thumbnail,
      icon: icon,
      metadata: metadata.toLowerCase(),
    })
  })

  return selectionItems;
}

interface FilterOptions {
  showNotes?: boolean,
  showFlows?: boolean,
  showMedia?: boolean,
}

export const filterResourceList = ({showNotes, showFlows, showMedia}: FilterOptions): Set<string> => {
  const filters: Set<string> = new Set();

  if (showNotes) {
    filters.add("note");
  }
  if (showFlows) {
    filters.add("flow");
  }
  if (showMedia) {
    filters.add("media");
  }

  return filters;
}