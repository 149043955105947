export enum TaggableType {
  Note = "note",
  Flowchart = "flowchart",
  Image = "image",
  Video = "video",
}

export interface TaggedResource {
  id: string,
  name: string,
  type: TaggableType,
}

export interface Tag {
  name: string,
  color: string,
}

export const UNTAGGED_TAG: Tag = {
  color: "#f4f5f8",
  name: "Untagged",
};

export interface GroupedTag {
  tag: Tag;
  targets: Array<TaggedResource>;
}

/* Appwrite limitation on joining + unable to nest objects */
export const marshalTag = (tag: Tag): string => {
  return `${tag.color}_${tag.name}`;
};

export const unmarshalTag = (tag: string): Tag => {
  return {
    color: tag.substring(0, 7),
    name: tag.substring(8),
  };
};

export type TagReference = Record<string, Tag>;

export const TagColors: Array<string> = [
  "#ea97c1",
  "#f7b3c1",
  "#fdd1b4",
  "#dcd5cc",
  "#efdfc6",
  "#e4efc9",
  "#dce6dc",
  "#c8cedc",
  "#9ad4e0",
  "#8eaddb",
  "#c18bbd",
  "#8c8cc5",
];
