import {
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSearchbar,
  IonThumbnail,
  IonToolbar
} from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { documentTextOutline, gitNetworkSharp } from "ionicons/icons";
import { buildResourceList } from "../helpers/ResourceHelpers";
import { useGetUserNotes } from "../hooks/NotesHooks";
import { useGetUserFlows } from "../hooks/FlowHooks";
import { useGetUserMedia } from "../hooks/MediaHooks";
import { ResourceItem } from "../types/ResourceTypes";
import { NavSource } from "../types/NavEnums";
import { format } from "date-fns";
import { useAuthContext } from "../providers/AuthProvider";
import Logo from "../components/header/Logo";

const Tags = () => {
  const history = useHistory();
  const {authUser} = useAuthContext();

  const notesQuery = useGetUserNotes(authUser?.$id);
  const flowsQuery = useGetUserFlows(authUser?.$id);
  const mediaQuery = useGetUserMedia(authUser?.$id);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const searchbarRef = React.useRef<HTMLIonSearchbarElement>(null);
  const [searchText, setSearchText] = React.useState<string>("");

  const [userResources, setUserResources] = React.useState<ResourceItem[]>([]);

  useEffect(() => {
    if (searchbarRef.current) {
      searchbarRef.current!.setFocus();
    }
  }, [searchbarRef]);

  useEffect(() => {
    if (notesQuery.isSuccess && flowsQuery.isSuccess && mediaQuery.isSuccess) {
      // TODO: this should be abstracted so we don't rerun this every time
      const resources = buildResourceList(notesQuery.data, flowsQuery.data, mediaQuery.data);
      setUserResources(resources);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [notesQuery.isSuccess, flowsQuery.isSuccess, mediaQuery.isSuccess]);

  const navigateToResource = (resource: ResourceItem, source: NavSource) => {
    console.log(resource)
    if (resource.type === "flow") {
      history.push(`/flows/item/${resource.id}?source=${source}`);
    } else if (resource.type === "note") {
      history.push(`/notes/item/${resource.id}?source=${source}`);
    } else if (resource.type === "media") {
      history.push(`/media/${resource.id}?source=${source}`);
    }
  }


  /*
  const filterAndSortData = (data?: ResourceItem[]) => {
    if (!data) return [];

    return data.filter(
      (data: ResourceItem) => {
        if (filterMode === "All") {
          return true;
        } else if (filterMode === "Flowcharts") {
          return data.type === "flow";
        } else if (filterMode === "Notes") {
          return data.type === "note";
        } else {
          return true;
        }
      }
    ).sort(
      (a: ResourceItem, b: ResourceItem) => {
        if (sortMode.mode === SortMode.CREATION_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.createdAt > b.createdAt ? -1 : 1;
          } else {
            return a.createdAt < b.createdAt ? -1 : 1;
          }
        } else if (sortMode.mode === SortMode.UPDATE_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.modifiedAt > b.modifiedAt ? -1 : 1;
          } else {
            return a.modifiedAt < b.modifiedAt ? -1 : 1;
          }
        } else if (sortMode.mode === SortMode.ALPHABETICAL) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.title > b.title ? -1 : 1;
          } else {
            return a.title < b.title ? -1 : 1;
          }
        } else {
          return 0; // Return 0 if neither creation time nor update time is selected for sorting
        }
      }
    )
  }

   */

  return (
    <IonPage>
      <IonLoading isOpen={isLoading}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Logo />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar ref={searchbarRef} mode={"ios"} value={searchText}
                        onIonInput={(e) => setSearchText(e.detail.value!)}>
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={"ion-padding-horizontal"}>
          <IonCard mode={"ios"}>
            <IonList style={{paddingTop: "5px"}}>
              {//filterAndSortData(userResources).map((resource, index) => (
                userResources.filter((ur) => {
                  if (searchText === "") {
                    return true;
                  }
                  if (ur.metadata) {
                    return ur.metadata.includes(searchText.toLowerCase());
                  } else {
                    return ur.title.includes(searchText.toLowerCase());
                  }
                }).sort(
                  (a, b) => b.modifiedAt > a.modifiedAt ? 1 : -1
                ).map((resource, index) => (
                  <IonItem key={index}
                           style={{
                             "--border-color": "#efefef",
                             marginTop: "4px",
                             marginBottom: "4px",
                             marginLeft: "-6px"
                           }}
                           onClick={() => navigateToResource(resource, NavSource.SEARCH)}
                  >
                    {/*
                    <IonIcon size={"small"} icon={resource.type === "flow" ? gitNetworkSharp : documentTextOutline}
                             style={{position: "absolute", right: "15px"}}
                    />
                    */}
                    {resource.subtype === "image" &&
                        <IonThumbnail slot="start">
                            <picture>
                                <source srcSet={`${resource.thumbnail?.webp_url}`} type="image/webp"/>
                                <img src={`${resource.thumbnail?.url}`} alt={resource.subtitle}/>
                            </picture>
                        </IonThumbnail>
                    }
                    {resource.subtype === "youtube" &&
                        <IonThumbnail slot="start">
                            <IonImg src={`${resource.thumbnail?.url}`} alt={resource.subtitle}></IonImg>
                        </IonThumbnail>
                    }
                    {resource.type === "flow" &&
                        <IonItem slot={"start"} lines={"none"}>
                            <IonIcon size={"medium"} icon={gitNetworkSharp}/>
                        </IonItem>
                    }
                    {resource.type === "note" &&
                        <IonItem slot={"start"} lines={"none"}>
                            <IonIcon size={"medium"} icon={documentTextOutline}/>
                        </IonItem>
                    }
                    <IonGrid>
                      <IonRow>
                        <IonCol className={"ion-text-start"} style={{marginLeft: "-10px"}}>
                          {resource.type === "media" ?
                            <IonLabel style={{maxWidth: "90%"}}>{resource.subtitle}</IonLabel>
                            :
                            <IonLabel style={{maxWidth: "90%"}}>{resource.title}</IonLabel>
                          }
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className={"ion-text-start"} style={{marginLeft: "-10px"}}>
                          <IonLabel
                            style={{
                              fontSize: "x-small",
                              color: "#5b5b5b"
                            }}>{format(new Date(resource.modifiedAt), "MMM d, yyyy")}</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                ))}
            </IonList>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Tags;