import {DBMedia, MediaFields, MediaUploadFields, VideoAdditionalData, VideoBookmark} from "../types/MediaType";
import {
  createMedia,
  deleteMedia,
  getMedia,
  getNote,
  getUserMedia,
  MediaUpdateFields, setMediaFields, updateNote
} from "../helpers/AppwriteDatabaseHelper";
import {deleteImageFile, uploadImageFile} from "../helpers/AppwriteStorageHelper";
import getConfig from "../configs/AppwriteConfigs";
import {useMutation, useQuery, useQueryClient} from "react-query";
import imageCompression from "browser-image-compression";
import {DEFAULT_MEDIA_STALE_TIME, DEFAULT_NOTES_STALE_TIME} from "../configs/Constants";
import {Note} from "../types/NoteTypes";
import { useAuthContext } from "../providers/AuthProvider";

const APPWRITE_CONFIGS = getConfig();
const IMAGES_BUCKET_ID = APPWRITE_CONFIGS.imagesBucketId;
const PROJECT_ID = APPWRITE_CONFIGS.projectId;

const buildMediaURL = (fileId: string): string => {
  return `https://cloud.appwrite.io/v1/storage/buckets/${IMAGES_BUCKET_ID}/files/${fileId}/preview?project=${PROJECT_ID}`;
}

const buildThumbnailURL = (fileId: string): string => {
  return `https://cloud.appwrite.io/v1/storage/buckets/${IMAGES_BUCKET_ID}/files/${fileId}/preview?project=${PROJECT_ID}`;
}

const uploadImageFileAndMetadata = async (mediaUploadData: MediaUploadFields): Promise<DBMedia> => {
  // Pre-process the data, if needed
  let processedFiles: File[] = mediaUploadData.files;
  if (mediaUploadData.imageCompressionOptions) {
    const beforeTime = new Date().getTime();
    processedFiles = await Promise.all(mediaUploadData.files.map(async (file) => {
      let processedFile: File = file;
      const compressedFile = await imageCompression(file, mediaUploadData.imageCompressionOptions!);
      if (compressedFile instanceof Blob) {
        processedFile = new File([compressedFile], file.name, {type: file.type});
      }
      return processedFile;
    }));
    const afterTime = new Date().getTime();
    console.debug(`Compression completed ${(afterTime - beforeTime) / 1000}s before: ${mediaUploadData.files.reduce((acc, file) => acc + file.size, 0)}, after: ${processedFiles.reduce((acc, file) => acc + file.size, 0)}`);
  }

  // Upload the file, then add the DB record
  const beforeTime = new Date().getTime();
  const uploadedFiles = await Promise.all(processedFiles.map(uploadImageFile));
  const afterTime = new Date().getTime();
  console.debug(`Upload completed ${(afterTime - beforeTime) / 1000}s`);

  mediaUploadData.imageCompressionOptions = undefined; // Purge this out to make the MediaFields populate more cleanly

  const mediaData: MediaFields[] = uploadedFiles.map((uploadedFile) => ({
    fileId: uploadedFile.$id,
    thumbnailUrl: buildMediaURL(uploadedFile.$id),
    url: buildThumbnailURL(uploadedFile.$id),
    ...mediaUploadData,
  }));

  mediaData.forEach((media) => {
    // @ts-ignore
    media.files = undefined;
  });
  
  const dbMedia = await Promise.all(mediaData.map(createMedia));
  return dbMedia[0];
}

const deleteImageFileAndMetadata = async (mediaData: DBMedia) => {
  const imageFileId = mediaData.fileId;
  const imageDbId = mediaData.$id;

  // Delete the file, then delete the DB record
  return deleteImageFile(imageFileId).then(() => {
    deleteMedia(imageDbId)
  });
}

export const useUploadImage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (mediaUploadData: MediaUploadFields) => uploadImageFileAndMetadata(mediaUploadData), {
      onSuccess: (newMedia) => {
        // Invalidate cache on upload
        queryClient.invalidateQueries(["media"])
      }
    }
  );
};

export const useImportYoutube = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (mediaData: MediaFields) => createMedia(mediaData), {
      onSuccess: (newMedia, variables ) => {
        queryClient.setQueryData(["media"], (oldData: any) => [...oldData, newMedia]);
      }
    }
  )
}

export const useGetUserMedia = (userId: string) => {
  const queryClient = useQueryClient();

  return useQuery(["media"], () => getUserMedia(userId), {
    staleTime: DEFAULT_MEDIA_STALE_TIME,
  });
}

export const useGetMedia = (mediaId: string) => {
  return useQuery(["media", mediaId], () => getMedia(mediaId), {
    staleTime: DEFAULT_MEDIA_STALE_TIME,
  });
}

export const useUpdateImage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({mediaId, mediaUpdateFields}: {mediaId: string, mediaUpdateFields: MediaUpdateFields}) => setMediaFields(mediaId, mediaUpdateFields), {
      onSuccess: (updatedMedia, variables ) => {
        queryClient.setQueryData(["media"], (oldData: any) => [...oldData.filter((media: DBMedia) => media.$id !== updatedMedia.$id), updatedMedia]);
      },
      onError: ((error: any) => {
        console.error(error);
        queryClient.invalidateQueries("media"); // for when Appwrite chokes on update
      })
    }
  );
}

export const useUpdateVideoData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({mediaId, videoUpdateFields}: {mediaId: string, videoUpdateFields: VideoAdditionalData}) => setMediaFields(mediaId, {additionalData: videoUpdateFields}), {
      onSuccess: (updatedMedia, variables ) => {
        queryClient.setQueryData(["media"], (oldData: any) => [...oldData.filter((media: DBMedia) => media.$id !== updatedMedia.$id), updatedMedia]);
      },
      onError: ((error: any) => {
        console.error(error);
        queryClient.invalidateQueries("media"); // for when Appwrite chokes on update
      })
    }
  );
}

export const useDeleteImage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (mediaData: DBMedia) => deleteImageFileAndMetadata(mediaData), {
      onSuccess: (data, deletedMediaData) => {
        queryClient.setQueryData(["media"], (oldData: any) => oldData.filter((media: DBMedia) => media.$id !== deletedMediaData.$id))
      }
    });
}

export const useDeleteYoutubeVideo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (mediaData: DBMedia) => deleteMedia(mediaData.$id), {
      onSuccess: (data, deletedMediaData) => {
        queryClient.setQueryData(["media"], (oldData: any) => oldData.filter((media: DBMedia) => media.$id !== deletedMediaData.$id))
      }
    });
}
