// This should be moved into a database with dates to keep track of when they become live

export const USER_AGREEMENT_V1 = `
# User Agreement (Terms of Service)

**Effective Date: December 11, 2023**

1. **Acceptance of Terms**

   Welcome to Grapple Flows! By accessing or using the Grapple Flows mobile application or website (the "Service"), you agree to comply with and be bound by the following terms and conditions (the "User Agreement"). If you do not agree to these terms, please do not use the Service.

2. **Use of the Service**

   * You must be at least 13 years old to use the Service.
   - You are responsible for maintaining the security of your account and password. Grapple Flows cannot and will not be liable for any loss or damage resulting from your failure to comply with this security obligation.
   - You are solely responsible for your interactions with other users of the Service, and Grapple Flows is not responsible for any disputes or actions that may arise between users.

3. **User-Generated Content**

   - Users may create and share content, including flow charts, notes, images, and videos.
   - Grapple Flows does not endorse, support, or guarantee the accuracy, completeness, or quality of user-generated content.
   - Users are responsible for the content they create and share and must not violate any intellectual property rights, copyrights, or proprietary rights of others.

4. **Privacy Policy**

   Your use of the Service is also governed by our Privacy Policy. Please review the Privacy Policy to understand how we collect, use, and protect your information.

5. **Termination of Service**

   Grapple Flows reserves the right to terminate or suspend your access to the Service, with or without cause, at any time.

6. **Limitation of Liability**

   Grapple Flows is not responsible for any damages or losses resulting from the use of the Service or user-generated content. Users assume all risks associated with their use of the Service.

7. **Changes to Terms**

   Grapple Flows may revise and update this User Agreement from time to time. Your continued use of the Service after any such changes constitutes your acceptance of the updated User Agreement.

8. **Contact Information**

   If you have any questions or concerns about this User Agreement, please contact us at hello@grappleflows.com.
`;

export const PRIVACY_POLICY_V1 = `
# Privacy Policy

**Effective Date: December 11, 2023**

1. **Introduction**

   Welcome to Grapple Flows! This Privacy Policy explains how we collect, use, and protect your information when you access or use our mobile application or website (the "Service"). By using the Service, you agree to the terms outlined in this Privacy Policy.

2. **Information We Collect**

   - We collect information you provide when you create an account, including your email address.
   - We may collect user-generated content, such as flow charts, notes, images, and videos, that you create and share on the Service.
   - We automatically collect certain information, such as device information and usage data, when you use the Service.

3. **Use of Information**

   We use the information collected to provide and improve the Service, customize user experiences, and communicate with users.
   User-generated content may be publicly accessible on the Service, and we are not responsible for its accuracy or quality.

4. **Sharing of Information**

   - We may share your information with third-party service providers to support the operation of the Service.
   - User-generated content may be visible to other users as part of the Service's social features.

5. **Data Security**

   - We implement security measures to protect user data but cannot guarantee the absolute security of information.
   - Users are responsible for maintaining the security of their accounts and passwords.

6. **Age Verification**

   You must be at least 13 years old to use the Service. We do not knowingly collect or use personal information from users under 13.

7. **Changes to Privacy Policy**

   We may update this Privacy Policy from time to time, and the revised policy will be effective upon posting.

8. **Contact Information**

   If you have questions or concerns about this Privacy Policy or our data practices, please contact us at hello@grappleflows.com.
`;
