import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  DEFAULT_FLOWS_STALE_TIME,
  DEFAULT_MEDIA_STALE_TIME,
  DEFAULT_NOTES_STALE_TIME
} from "../configs/Constants";
import {
  createFlow,
  deleteFlow,
  getFlow,
  getUserFlows,
  getUserMedia,
  getUserNotes, setFlowConnections,
  setFlowNodes,
  setFlowNodesAndConnections,
  updateFlowMetadata
} from "../helpers/AppwriteDatabaseHelper";
import { DBFlow, FlowConnection, FlowFields, FlowNode } from "../types/FlowTypes";

export const useGetUserFlows = (userId: string) => {
  const queryClient = useQueryClient();

  return useQuery(["flows"], () => getUserFlows(userId), {
    staleTime: DEFAULT_FLOWS_STALE_TIME,
  });
};

export const useGetFlow = (flowId: string) => {

  return useQuery(["flows", flowId], () => getFlow(flowId), {
    staleTime: DEFAULT_FLOWS_STALE_TIME,
  });
};

export const useCreateFlow = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (flow: FlowFields) => createFlow(flow), {
      onSuccess: (newFlow) => {
        queryClient.setQueryData(["flows"], (oldData: any) => [...oldData, newFlow]);
      }
    }
  );
};

interface useUpdateFlowMetadataFields {
  flowId: string;
  updatedFlowName: string;
}

export const useUpdateFlowMetadata = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({flowId, updatedFlowName}: useUpdateFlowMetadataFields) => updateFlowMetadata(flowId, updatedFlowName), {
      onSuccess: (updatedFlow, updatedFlowId) => {
        queryClient.setQueryData(["flows"], (oldData: any) =>
          [...oldData.filter((v: any) => v.$id !== updatedFlow.$id), updatedFlow]
        );
      },
    }
  );
};

export const useDeleteFlow = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (flowId: string) => deleteFlow(flowId), {
      onSuccess: (_, flowId) => {
        queryClient.setQueryData(["flows"], (oldData: any) => oldData.filter((flow: DBFlow) => flow.$id !== flowId));
      }
    }
  );
};

export const useSetFlowNodes = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({flowId, flowNodes}: {flowId: string, flowNodes: FlowNode[]}) => setFlowNodes(flowId, flowNodes), {
      onSuccess: (resp, args) => {
        queryClient.setQueryData(["flows"], (oldData: any) => [...oldData.filter((v: any) => v.$id !== resp.$id), resp]);
      }
    });
};

export const useSetFlowConnections = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({flowId, flowConnections}: {flowId: string, flowConnections: FlowConnection[]}) => setFlowConnections(flowId, flowConnections), {
      onSuccess: (resp, args) => {
        queryClient.setQueryData(["flows"], (oldData: any) => [...oldData.filter((v: any) => v.$id !== resp.$id), resp]);
      }
    }
  )
}

interface useSetFlowNodesAndConnectionsFields {
  flowId: string;
  flowNodes?: Array<FlowNode>;
  flowConnections?: Array<FlowConnection>;
}

export const useSetFlowNodesAndConnections = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
       flowId,
       flowNodes,
       flowConnections,
     }: useSetFlowNodesAndConnectionsFields) => setFlowNodesAndConnections(flowId, flowNodes, flowConnections), {
      onSuccess: (resp, args) => {
        queryClient.setQueryData(["flows"], (oldData: any) => {
          // Handles when we have an empty list of connections
          if (oldData === null || oldData === undefined) {
            return [resp];
          } else {
            return [...oldData.filter((v: any) => v.$id !== resp.$id), resp]
          }
        });
      },
      onError: ((error: any) => console.error(error))
    }
  );
};

export const useBuildFlowchart = (userId: string) => {
  const queryClient = useQueryClient();

  const {
    data: notes,
    isLoading: notesLoading,
    isError: notesError,
  } = useQuery(["notes"], () => getUserNotes(userId), {
    staleTime: DEFAULT_NOTES_STALE_TIME,
  });

  const {
    data: flows,
    isLoading: flowsLoading,
    isError: flowsError,
  } = useQuery(["flows"], () => getUserFlows(userId), {
    staleTime: DEFAULT_FLOWS_STALE_TIME,
  });

  const {
    data: media,
    isLoading: mediaLoading,
    isError: mediaError,
  } = useQuery(["media"], () => getUserMedia(userId), {
    staleTime: DEFAULT_MEDIA_STALE_TIME,
  });

  const allLoaded =
    !notesLoading &&
    !flowsLoading &&
    !mediaLoading;

  return {
    notes,
    notesLoading,
    notesError,
    flows,
    flowsLoading,
    flowsError,
    media,
    mediaLoading,
    mediaError,
    allLoaded, // Indicates whether all queries have finished loading
  };
};
