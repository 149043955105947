export const createYouTubeVideoThumbnailUrl = (videoId: string): string => {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}

export const createYouTubeVideoStartTime = (videoId: string, startTime: string): string => {
  // startTime in format mm:ss
  const [minutes, seconds] = startTime.split(':').map(Number);

  let timestamp = '';

  // Add minutes to the timestamp if greater than 0
  if (minutes > 0) {
    timestamp += `${minutes}m`;
  }

  // Add seconds to the timestamp if greater than 0
  if (seconds > 0) {
    timestamp += `${seconds}s`;
  }

  // Construct the full YouTube video Url with the timestamp
  return `https://youtu.be/${videoId}?t=${timestamp}`;
}

export const isYouTubeUrl = (url: string): boolean => {
  // Define a regular expression to match YouTube URLs with optional http/https, www, and query parameters
  const youtubeUrlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/[^?]+(\?.*)?$/;

  return youtubeUrlPattern.test(url);
}

export const stripExtraParamsFromYouTubeUrl = (ytUrl: string): string => {
  try {
    // Create a URL object from the input YouTube URL
    const url = new URL(ytUrl);

    // Check if the hostname is youtube.com or youtu.be
    if (url.hostname === 'www.youtube.com' || url.hostname === 'youtu.be') {
      // Create a new URL object with only the necessary parts
      const strippedURL = new URL(`${url.protocol}//${url.hostname}${url.pathname}`);

      // Append the "&v=" parameter if it exists in the original URL
      if (url.searchParams.has('v')) {
        strippedURL.searchParams.set('v', url.searchParams.get('v') || '');
      }

      // Return the stripped URL as a string
      return strippedURL.toString();
    } else {
      // If the URL doesn't match YouTube's hostname, return the original URL
      return ytUrl;
    }
  } catch (error) {
    // If there was an error parsing the URL, return the original URL
    return ytUrl;
  }
}

export function extractYouTubeVideoId(url: string): string | null {
  try {
    const urlObj = new URL(url);

    // Check if the hostname is a known YouTube domain
    if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com" || urlObj.hostname === "youtu.be") {
      // Extract the video ID based on the hostname
      if (urlObj.hostname === "youtu.be") {
        const path = urlObj.pathname.split("/");
        if (path.length > 1) {
          return path[1];
        }
      } else if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
        const searchParams = new URLSearchParams(urlObj.search);
        if (searchParams.has("v")) {
          return searchParams.get("v");
        }
      }
    }
  } catch (error) {
    // Invalid URL
  }

  return null;
}

export function formatVideoDuration(durationInSeconds: number): string {
  if (durationInSeconds === 0) {
    return '00:00';
  }

  // Round the duration to the nearest integer
  const roundedDuration = Math.round(durationInSeconds);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(roundedDuration / 3600);
  const minutes = Math.floor((roundedDuration % 3600) / 60);
  const seconds = roundedDuration % 60;

  // Format the duration as HH:mm:ss if it's 1 hour or more
  if (hours >= 1) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  // Format the duration as mm:ss if it's less than 1 hour
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}