import {useMutation, useQuery, useQueryClient} from "react-query";
import {DEFAULT_NOTES_STALE_TIME} from "../configs/Constants";
import {createNote, deleteNote, getNote, getNotes, getUserNotes, updateNote} from "../helpers/AppwriteDatabaseHelper";
import {Note} from "../types/NoteTypes";

// Storing single list as individual records: https://stackoverflow.com/a/69108079
export const useGetUserNotes = (userId: string) => {
  const queryClient = useQueryClient();

  return useQuery(["notes"], () => getUserNotes(userId), {
    staleTime: DEFAULT_NOTES_STALE_TIME,
    // onSuccess: notes => notes?.map(note => queryClient.setQueryData(['notes', note.$id], note))
  });
};

export const useGetNote = (noteId: string) => {
  return useQuery(["notes", noteId], () => getNote(noteId), {
    staleTime: DEFAULT_NOTES_STALE_TIME,
  });
};

/* this function is all fucked up
export const useGetUserNote = (noteId: string) => {
  const { getNote } = useContext(AppwriteContext);
  const queryClient = useQueryClient();

  return useQuery(["notes"], () => getNote?.(noteId), {
    staleTime: STALE_TIME,
    // @ts-ignore
    enabled: () => {
      return queryClient.getQueryData("notes") === undefined; 
    }, // only query if it wasn't in the cache 
    // TODO: turn above into anonymous function if it turns out we don't need the code below
  });
}
*/

// Yeah so this is not a hook I'll figure out how to structure later I guess
// Expected to be used with a user
/*
export const getUserNote = (noteId: string): Note | undefined => {
  const queryClient = useQueryClient();
  const queryData: Array<Note> | undefined = queryClient.getQueryData("notes");

  if (queryData) {
    const noteData = queryData.find((note: Note) => note.$id === noteId);
    if (noteData) {
      return noteData;
    }
  }
  return undefined;
};
*/

// export const useGetNote = (noteId: string) => {}

/*
export const useGetNotes = (noteIds: string[]) => {
  const { getNotes } = useContext(AppwriteContext);
  const queryClient = useQueryClient();

  return useQuery(["notes", noteIds], () => getNotes?.(noteIds), {
    staleTime: STALE_TIME,
    onSuccess: notes => notes?.map(note => queryClient.setQueryData(['notes', note.$id], note))
  });
};
*/
export const useGetNotes = (noteIds: string[]) => {
  const queryClient = useQueryClient();

  return useQuery(["notes", noteIds], () => getNotes(noteIds), {
    staleTime: DEFAULT_NOTES_STALE_TIME,
    onSuccess: notes => notes?.map(note => queryClient.setQueryData(['notes', note.$id], note))
  });
};


// Mutations: https://tkdodo.eu/blog/mastering-mutations-in-react-query
export const useCreateNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (note: Note) => createNote(note), {
      onSuccess: (newNote) => {
        queryClient.setQueryData(["notes"], (oldData: any) => [...oldData, newNote]);
        //queryClient.setQueryData(["notes", newNote.$id], newNote);
      }
    }
  );
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (note: Note) => updateNote(note), {
      onSuccess: (newNote) => {
        queryClient.setQueryData(["notes"], (oldData: any) => {
          return [...oldData.filter((note: Note) => note.$id !== newNote.$id), newNote];
        });
      }
    }
  );
};

export const useDeleteNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (noteId: string) => deleteNote(noteId), {
      onSuccess: (_, noteId) => {
        queryClient.setQueryData(["notes"], (oldData: any) => oldData.filter((note: Note) => note.$id !== noteId));
        //queryClient.removeQueries(["notes", noteId]);
      }
    }
  );
};
