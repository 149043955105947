import { Edge, Node, Viewport } from "reactflow";
import {v4 as uuidv4} from "uuid";


const MIN_CONNECTION_DISTANCE = 125;

interface ClosestNodeResult {
  distance: number;
  node: Node | null;
}

interface StoreState {
  nodeInternals: Map<string, Node>;
}

export const getClosestEdge = (node: Node, storeState: StoreState): Edge | null => {
  const {nodeInternals} = storeState
  const storeNodes = Array.from(nodeInternals.values());

  const closestNode = storeNodes.reduce(
    (res: ClosestNodeResult, n) => {
      if (n?.positionAbsolute && node.positionAbsolute && n.id !== node.id) {
        // We want the top middle position of node; bottom middle position of n
        // absolute position of y increases as you move down the canvas

        // source (dragged)
        const nodeWidth = node.width!;
        const nodeHeight = node.height!;
        const positionSelectedX = node.positionAbsolute.x + nodeWidth / 2;
        const positionSelectedY = node.positionAbsolute.y;

        // target
        const nWidth = n.width!;
        const nHeight = n.height!;
        const positionSourceX = n.positionAbsolute.x + nWidth / 2;
        const positionSourceY = n.positionAbsolute.y + nHeight;

        const dx = positionSourceX - positionSelectedX;
        const dy = positionSourceY - positionSelectedY;
        const d = Math.sqrt(dx * dx + dy * dy);
        if (d < res.distance && d < MIN_CONNECTION_DISTANCE) {
          res.distance = d;
          res.node = n;
        }
      }

      return res;
    },
    {distance: Number.MAX_VALUE, node: null,}
  );

  if (!closestNode.node) {
    return null;
  }

  // We don't want to prompt connections when dragged is above undragged
  const bottomEdgePosY = closestNode.node.positionAbsolute!.y + closestNode.node.height!;
  if (bottomEdgePosY > node.positionAbsolute!.y) {
    return null;
  }

  const closeNodeIsSource = false;

  const sourceNodeId = closeNodeIsSource ? node.id : closestNode.node.id;
  const targetNodeId = closeNodeIsSource ? closestNode.node.id : node.id;

  // We only want to connect the dragged node as the target
  if (sourceNodeId === node.id) {
    return null;
  }

  const newEdgeId = uuidv4();
  // The dragged node becomes the target
  return {
    id: newEdgeId,
    source: sourceNodeId,
    target: targetNodeId,
    style: {
      strokeWidth: 8,
    },
  };
};

export const getCoordinatesForNewNode = ({x, y, zoom}: Viewport) => {
  // const {x: viewportX, y: viewportY, zoom: viewportZoom} = getViewport();
  const reactFlowBounds = document.querySelector('.react-flow')!.getBoundingClientRect();

  return {
    x: -1 * x / zoom + reactFlowBounds.width / zoom / 2,
    y: -1 * y / zoom + reactFlowBounds.height / zoom / 2,
  }
}
