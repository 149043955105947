import React from 'react';
import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';

import './Landing.css';
import ReactMarkdown from "react-markdown";
import { PRIVACY_POLICY_V1 } from "../../configs/UserPolicies";

const PrivacyPolicy: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <a href={process.env.PUBLIC_URL} className={"toolbar-link"}>
            <IonImg
              className="toolbar-logo"
              src={process.env.PUBLIC_URL + '/assets/icon/icon.png'}
              alt="Grapple Flows"
            />
            <IonTitle className="toolbar-title">grapple flows</IonTitle>
            </a>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={"ion-padding"}>
          <ReactMarkdown>{PRIVACY_POLICY_V1}</ReactMarkdown>
        </div>
        <IonFooter mode={"md"} style={{border: "none !important", boxShadow: "none"}}>
          <IonToolbar>
            <IonItem lines={"none"}>
              <IonLabel style={{fontSize: "small"}}>© 2023 Grapple Flows. All rights reserved.</IonLabel>
            </IonItem>
          </IonToolbar>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
