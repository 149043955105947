import React, { useState } from 'react';
import { IonButton, IonContent, IonHeader, IonInput, IonTitle, IonToolbar } from '@ionic/react';
import { FlowNode } from "../../../types/FlowTypes";
import { getCoordinatesForNewNode } from "../../../utils/FlowChartUtils";
import { useGetUserFlows, useSetFlowNodes } from "../../../hooks/FlowHooks";
import { useReactFlow } from "reactflow";

import { v4 as uuidv4 } from "uuid";
import { useAuthContext } from "../../../providers/AuthProvider";

interface TextSelectorProps {
  flowId?: string;
  dismiss: CallableFunction;
}

const TextSelector = ({flowId, dismiss}: TextSelectorProps) => {
  const {authUser} = useAuthContext();

  const flowsQuery = useGetUserFlows(authUser?.$id);
  const setFlowNodes = useSetFlowNodes();

  const {getViewport} = useReactFlow();

  const [cardContent, setCardContent] = useState('');

  const handleCancel = () => {
    setCardContent('');
    dismiss();
  };

  const handleCreate = () => {
    addNewText();
    console.debug(cardContent);
    setCardContent('');
  };

  const addNewText = () => {
    if (flowsQuery.isSuccess && flowsQuery.data) {
      const currentFlowNodes: FlowNode[] = flowsQuery.data.find((f) => f.$id === flowId)!.nodes.map((n) => JSON.parse(n) as FlowNode);

      const {x: canvasX, y: canvasY} = getCoordinatesForNewNode(getViewport())

      const newFlowNode: FlowNode = {
        type: "text",
        label: cardContent,
        targetId: uuidv4().replace("-", "").substring(0, 21),
        x: canvasX,
        y: canvasY,
        creationTime: new Date().toISOString(),
      }
      console.debug(newFlowNode);

      setFlowNodes.mutate({flowId: flowId!, flowNodes: [...currentFlowNodes, newFlowNode]}, {
        onSuccess: (v) => dismiss(),
      });
    }
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create a Text Card</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonInput
          value={cardContent}
          placeholder="Your text (up to 150 characters)"
          onIonInput={e => setCardContent(e.detail.value!)}
          maxlength={150}
        />
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
          <IonButton onClick={handleCancel} fill="outline">Cancel</IonButton>
          <IonButton onClick={handleCreate} style={{marginLeft: '1em'}}>Create</IonButton>
        </div>
      </IonContent>
    </IonContent>
  );
};

export default TextSelector;
