import { IonButton, IonChip, IonItem, IonLabel, IonList } from "@ionic/react";
import { DBMedia, VideoAdditionalData, VideoBookmark } from "../../types/MediaType";
import React, { useEffect, useState } from "react";
import { formatVideoDuration } from "../../utils/YouTubeUtils";

interface VideoBookmarkSelectionProps {
  mediaData: DBMedia;
  existingFlowIds: Set<string>;
  onClickAction: (mediaData: DBMedia, videoBookmark: VideoBookmark | undefined) => void;
}

const VideoBookmarkSelection = ({
                                  mediaData,
                                  existingFlowIds,
                                  onClickAction,
                                }: VideoBookmarkSelectionProps) => {

  const [bookmarks, setBookmarks] = useState<VideoBookmark[]>([]);
  const [currentId, setCurrentId] = useState<string>("");

  useEffect(() => {
    console.debug("mediaData", mediaData);
    if (mediaData && mediaData.additionalData) {
      const serializedBookmarks = JSON.parse(mediaData.additionalData) as VideoAdditionalData;
      console.debug("Serialized bookmarks", serializedBookmarks);
      setBookmarks(serializedBookmarks.videoBookmarks);
      setCurrentId(mediaData.$id);
    }
  }, [mediaData]);

  return (
    <IonList>
      {/* Don't show it if it's been set already */}
      {!existingFlowIds.has(`${currentId}-`) &&
        (
          <IonItem onClick={() => {
            onClickAction(mediaData, undefined);
          }}>
            <IonButton mode={"ios"} color={"light"}>Import Without
              Timestamp</IonButton>
          </IonItem>
        )}
      {bookmarks
        .filter((bookmark: VideoBookmark) => {
          const bid = `${currentId}-${bookmark.seconds}`;
          return !existingFlowIds.has(bid);
        })
        .sort((a, b) => a.seconds - b.seconds)
        .map((bookmark: VideoBookmark, index) => (
          <IonItem key={index} onClick={() => {
            onClickAction(mediaData, bookmark);
          }}>
            <IonChip>{formatVideoDuration(bookmark.seconds)}</IonChip>
            <IonLabel style={{paddingLeft: "10px"}}>{bookmark.description}</IonLabel>
          </IonItem>
        ))}
    </IonList>
  )
}

export default VideoBookmarkSelection;
