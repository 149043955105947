import React, { createContext, useContext, useEffect, useState } from 'react';
import { Models } from 'appwrite';
import {
  createAccount,
  getCurrentUser,
  sendVerificationEmail,
  signInUser,
  signInUserWithGoogle,
  signOutUser,
  verifyUser,
  createPasswordRecovery,
  setPasswordRecovery, getSession,
} from "../helpers/AppwriteAuthHelper";

interface AuthContextProps {
  authUser?: Models.Preferences | null;
  // isLoading: boolean;
  loadUser: () => Promise<void>;
  logIn: (username: string, password: string) => Promise<Models.Session>;
  logInWithGoogle: () => Promise<void>;
  logOut: () => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<Models.Token>;
  verify: (userId: string, secret: string) => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  getUserDetails: () => UserDetails;
  getSession: () => Promise<Models.Session>;
  createPasswordRecovery: (email: string) => Promise<Models.Token>;
  setPasswordRecovery: (userId: string, secret: string, password: string) => Promise<Models.Token>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export type UserDetails = {
  name: string,
  email: string,
  memberSince: number
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [authUser, setAuthUser] = useState<Models.Preferences | null>(null);

  // const [isLoading, setLoading] = useState(true);

  const getUserDetails = (): UserDetails => {
    return {
      name: authUser?.name,
      email: authUser?.email,
      memberSince: authUser?.createdAt, // override for BuddyTape at some point
    }
  }

  const loadUser = async () => {
    try {
      const user = await getCurrentUser(); //.catch(() => setLoading(false))
      setAuthUser(user)
    } catch (error) {
      setAuthUser(null);
    }
    //setLoading(false)
  }

  useEffect(() => {
    loadUser()
  }, [])

  const logIn = async (username: string, password: string) => {
    const session = await signInUser(username, password);
    await loadUser();
    return session;
  }

  const logInWithGoogle = async () => {
    await signInUserWithGoogle();
    await loadUser();
  }

  const logOut = async () => {
    await signOutUser();
    setAuthUser(null);
  }

  const register = async (email: string, password: string, name: string) => {
    const verification = await createAccount(email, password, name);
    console.log(verification);
    return verification;
  }

  const verify = async (userId: string, secret: string) => {
    await verifyUser(userId, secret);
    await loadUser();
  }

  const contextValue: AuthContextProps = {
    authUser,
    // isLoading,
    loadUser,
    logIn,
    logInWithGoogle,
    logOut,
    register,
    verify,
    sendVerificationEmail,
    getUserDetails,
    getSession,
    createPasswordRecovery,
    setPasswordRecovery,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
