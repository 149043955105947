import Color from "@tiptap/extension-color";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import ListItem from '@tiptap/extension-list-item';
import Image from "@tiptap/extension-image";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import {Editor, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {useState} from "react";
import {Youtube} from "@tiptap/extension-youtube";
import {Details} from "@tiptap-pro/extension-details";
import {DetailsSummary} from "@tiptap-pro/extension-details-summary";
import {DetailsContent} from "@tiptap-pro/extension-details-content";

interface NoteEditorFields {
  autosave: boolean;
  saveData: () => void;
}

const useNoteEditor = ({autosave, saveData}: NoteEditorFields) => {
  const [isTextSelected, setIsTextSelected] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const noteEditor = useEditor({
    onFocus() {
      setIsFocused(true);
    },
    onBlur() {
      setIsFocused(false);
      if (autosave && saveData) {
        saveData();
      }
    },
    onSelectionUpdate({editor, transaction}) {
      // TODO: detect YouTube video selection
      if (editor.isEditable) {
        const {to, from} = transaction.selection;
        if (to - from > 0) {
          setIsTextSelected(true);
        } else {
          setIsTextSelected(false);
        }
      }
    },
    autofocus: true,
    extensions: [
      Image.configure({
        inline: false,
      }),
      Youtube.configure({
        inline: false,
      }),
      Details.configure({
        persist: true,
        HTMLAttributes: {
          class: 'details',
        },
      }),
      DetailsSummary,
      DetailsContent,
      TaskList,
      TaskItem.configure({
        nested: false,
      }),
      Color.configure({types: [TextStyle.name, ListItem.name]}),
      // @ts-ignore
      TextStyle.configure({types: [ListItem.name]}),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        placeholder: ({node}) => {
          if (node.type.name === 'detailsSummary') {
            return 'Summary';
          }
          if (node.type.name === 'heading') {
            return ''; // Placeholder for headings, if required
          }
          return '';//Write your training notes here!';
        },
      }),
    ],
  }) as Editor;

  return {isTextSelected, isFocused, noteEditor};
};

export default useNoteEditor;
