import {
  IonAlert,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonToolbar
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../providers/AuthProvider";
import Logo from "../components/header/Logo";
import { cafeOutline, logoDiscord } from "ionicons/icons";

const Settings = () => {

  const history = useHistory();
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const {logOut, getUserDetails} = useAuthContext();

  const handleLogout = () => {
    setLoadingMessage("Signing out...")
    logOut().then(() => {
      setLoadingMessage("");
      history.replace("/login");
    })
  }

  const getUserCard = () => {
    const userDetails = getUserDetails();

    return (
      <IonCard mode={"ios"}>
        <IonCardContent>
          <IonRow>
            {userDetails?.name}
          </IonRow>
          <IonRow>
            {userDetails?.email}
          </IonRow>
          {/* <IonRow>
            Blue Belt
          </IonRow> */}
        </IonCardContent>
      </IonCard>
    )
  }

  return (
    <>
      <IonLoading isOpen={loadingMessage !== ""} message={loadingMessage}/>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <Logo/>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className={"ion-padding-horizontal"}>
          <IonCard mode={"ios"} style={{
            backgroundColor: "#8b5f96",
            color: "white"
          }}>
            <IonCardContent>
              <IonLabel style={{fontSize: "larger", fontWeight: "500"}}>🙏 Thanks for being an early
                user!</IonLabel><br/>
              <IonLabel>We would appreciate it if you could fill out our <a href={"https://forms.gle/QndW4oTVTfpTWWNX9"}
                                                                            target={"_blank"}
                                                                            style={{color: "rgba(38,43,255,0.61)"}}>Grapple
                Flows Beta Survey</a>👈</IonLabel>
            </IonCardContent>
          </IonCard>
          {getUserCard()}
          <IonCard mode={"ios"}>
            <IonCardContent>
              <IonList>
                {/*
                <IonItem>
                  <IonLabel>Change Password</IonLabel>
                </IonItem>
                */}
                <IonItem onClick={() => history.push("/tabs/settings/feedback")}>
                  <IonLabel>Submit Feedback</IonLabel>
                </IonItem>
                <IonItem id={"logout-confirmation"}>
                  <IonLabel color={"danger"}>Sign Out</IonLabel>
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
          <IonCard mode={"ios"}>
            <IonCardContent>
              <IonList>
                <IonItem onClick={() => window.open("https://ko-fi.com/grappleflows#", "_blank")}>
                  <IonIcon icon={cafeOutline} style={{paddingRight: "5px"}}/>
                  <IonLabel>Support Grapple Flows</IonLabel>
                </IonItem>
                <IonItem onClick={() => window.open("https://discord.gg/B8j8CmVHSD", "_blank")}>
                  <IonIcon icon={logoDiscord} style={{paddingRight: "5px"}}/>
                  <IonLabel>Join Our Discord</IonLabel>
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
          {/* Communication Preferences, Discord Link, Tutorial, FAQ */}
          {/* Privacy, TOS */}
        </IonContent>
        <IonAlert
          trigger={"submit-feedback-alert"}
          header={"Contact Us"}
          subHeader={"We're working on a feedback form!"}
          message={"In the meantime, please email us at: hello@grappleflows.com"}
          buttons={[
            {
              text: "OK",
              handler: () => {
              }
            }
          ]}
        />
        <IonAlert
          trigger="logout-confirmation"
          header="Sign Out"
          message="Are you sure you want to sign out?"
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                console.log('Logout canceled');
              },
            },
            {
              text: 'Confirm',
              handler: () => {
                handleLogout();
              },
            },
          ]}
        />
      </IonPage>
    </>
  )
}

export default Settings;