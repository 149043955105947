import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonToolbar, } from "@ionic/react";
import { DBMedia } from "../../types/MediaType";
import React from "react";
import { pricetag } from "ionicons/icons";

import { format } from "date-fns";

interface ImageViewerFields {
  mediaData: DBMedia;
}

const ImageViewer: React.FC<ImageViewerFields> = ({mediaData}: ImageViewerFields) => {

  return (
    <>
      <IonContent>
        <div style={{backgroundColor: '#ababab'}}>
          <picture style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
            <source
              srcSet={`${mediaData.url}&output=webp`}
              type="image/webp"
            />
            <img
              src={`${mediaData.url}&output=jpg`}
              alt={mediaData.description}
            />
          </picture>
        </div>
        <IonToolbar mode={"ios"} className={"ion-no-border"}>
          {/*
          <IonButtons slot={"end"}>
            <IonButton><IonIcon icon={pricetag}/></IonButton>
          </IonButtons>
          */}
        </IonToolbar>
        <IonItem lines={"none"} style={{marginBottom: "-15px"}}>
          <IonLabel style={{fontSize: "small"}}>
            {format(new Date(mediaData.$createdAt), 'MMMM d, yyyy')}
          </IonLabel>
        </IonItem>
        <IonItem lines={"none"}>
          <IonLabel className="ion-text-wrap">
            {mediaData.description}
          </IonLabel>
        </IonItem>
      </IonContent>
    </>
  );
};

export default ImageViewer;
