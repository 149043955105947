import { useCallback, useMemo } from "react";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  getConnectedEdges,
  Node,
  useStoreApi,
} from "reactflow";

import "reactflow/dist/style.css";

import { getClosestEdge } from "../utils/FlowChartUtils";
import { FlowSelection } from "../types/NavEnums";
import { v4 as uuidv4 } from "uuid";
import NoteCard from "./flowchart/cards/NoteCard";
import ImageCard from "./flowchart/cards/ImageCard";
import YouTubeCard from "./flowchart/cards/YouTubeCard";
import CardConnector from "./flowchart/cards/CardConnector";
import FlowchartCard from "./flowchart/cards/FlowchartCard";
import TextCard from "./flowchart/cards/TextCard";
import { DEFAULT_CONNECTION_STYLE } from "../configs/Constants";


const FlowChart = ({
                     nodesState,
                     edgesState,
                     persistState,
                     addEdgeHandler,
                     flowSelection,
                     setFlowSelection,
                     selectionTarget,
                     setSelectionTarget
                   }: any) => {
  const nodeTypes = useMemo(() => ({
    "flowNote": NoteCard,
    "flowImage": ImageCard,
    "flowYoutube": YouTubeCard,
    "flowFlow": FlowchartCard,
    "flowText": TextCard
  }), []);
  const edgeTypes = useMemo(() => ({"flowEdge": CardConnector}), []);
  const store = useStoreApi();

  const [nodes, setNodes, onNodesChange] = nodesState;
  const [edges, setEdges, onEdgesChange] = edgesState;

  const onConnect = useCallback((params: Edge<any> | Connection) => setEdges((eds: Edge<any>[]) => addEdge(params, eds)), [setEdges]);

  const onNodeDrag = useCallback(
    (_: any, node: Node) => {
      const closeEdge: Edge | null = getClosestEdge(node, store.getState());

      setEdges((es: Edge[]) => {
        const nextEdges = es.filter((e) => e.className !== 'temp');
        if (closeEdge && !nextEdges.find((ne) => ne.source === closeEdge.source && ne.target === closeEdge.target)) {
          closeEdge.className = 'temp';
          closeEdge.animated = true;
          closeEdge.data = {};

          nextEdges.push(closeEdge);
        }
        return nextEdges;
      });
    },
    [getClosestEdge, setEdges]
  );

  const onNodeDragStop = (_: any, node: Node) => {
    console.debug("onNodeDragStop");

    const closeEdge: Edge | null = edges.find((e: Edge) => e.className === 'temp');
    if (closeEdge) {
      const newEdge = {...closeEdge, ...DEFAULT_CONNECTION_STYLE};
      newEdge.id = uuidv4();
      newEdge.className = undefined;

      setEdges([newEdge, ...edges.filter((e: Edge) => e.id !== closeEdge.id)]);
      addEdgeHandler(newEdge);
    }

    // TODO: figure out how to reduce persistState call when a node is simply clicked and not dragged
    persistState();
  };

  const deanimateEdges = (): Edge[] => {
    return edges.map((e: Edge) => ({...e, animated: false}));
  }

  const handleSelectionChange = (elements: { nodes: Node[], edges: Edge[] }) => {
    if (elements.nodes.length === 0 && elements.edges.length === 0) {
      if (selectionTarget === null) {
        return;
      }
      setFlowSelection(FlowSelection.CANVAS);
      setSelectionTarget(null);

      // Remove animation for all edges
      if (edges.length > 0) {
        // This helps prevent an on-load where edges are just nuked right off the bat
        setEdges(deanimateEdges());
      }
    } else if (elements.nodes.length > 0) {
      if (selectionTarget === elements.nodes[0]) {
        return;
      }
      setFlowSelection(FlowSelection.NODE);
      setSelectionTarget(elements.nodes[0]);

      // Set all connected edges as animated
      const connectedEdgeIds = getConnectedEdges(elements.nodes, edges).map((e: Edge) => e.id);
      const updatedEdges = deanimateEdges().map((e: Edge) =>
        connectedEdgeIds.includes(e.id) ? {...e, animated: true} : e
      );
      setEdges(updatedEdges);
    } else if (elements.edges.length > 0) {
      if (selectionTarget === elements.edges[0]) {
        return;
      }
      setFlowSelection(FlowSelection.EDGE);
      setSelectionTarget(elements.edges[0]);

      const selectedEdgeId = elements.edges[0].id;

      // Set edge as animated
      const updatedEdges = deanimateEdges().map((e: Edge) => e.id === selectedEdgeId ? {...e, animated: true} : e);
      setEdges(updatedEdges);
    }
  }

  return (
    <div style={{height: "100vh", width: "100vw"}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        selectionOnDrag={true}
        minZoom={0.1}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onSelectionChange={handleSelectionChange}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onConnect={onConnect}
        zoomOnPinch={true}
        panOnDrag={true}
        zoomOnScroll={true}
        fitView
      >
        <Background/>
        {/*
          <MiniMap
            position={"bottom-left"}
            pannable={true}
            zoomable={true}
            style={{marginBottom: "50px"}}
          />
          */}
        {/*
        <Panel position="bottom-center" style={{marginBottom: "100px"}}>-center</Panel>*/}
        <Controls showInteractive={false} position={"top-right"}/>
      </ReactFlow>
    </div>
  );
};

export default FlowChart;