import { Handle, NodeToolbar, Position, useReactFlow } from "reactflow";
import { useParams } from "react-router-dom";
import useFlowActions from "../../../hooks/FlowActionHooks";
import { useGetUserFlows } from "../../../hooks/FlowHooks";
import React, { useEffect, useRef, useState } from "react";
import { FlowNode } from "../../../types/FlowTypes";
import { IonAlert, IonButton, IonCard, IonCardHeader, IonContent, IonIcon, IonLabel, IonPopover } from "@ionic/react";
import { colorPaletteOutline, trashOutline } from "ionicons/icons";
import { ColorPicker } from "../../ColorPicker";
import { PRESET_COLORS } from "../../../configs/Constants";
import { useUpdateCard } from "../../../hooks/FlowCardHooks";
import { useAuthContext } from "../../../providers/AuthProvider";

interface TextProps {
  id: string;
  data: {
    description: string;
    toolbarVisible: boolean;
    toolbarPosition: Position;
    label?: string;
    color?: string;
  };
  selected: boolean;
}

const TextCard = ({id, data, selected}: TextProps) => {
  const {authUser} = useAuthContext();

  const {flowId} = useParams<{ flowId: string; }>();
  const {removeNode} = useFlowActions();
  const {getNode} = useReactFlow();

  const {updateNodeColor} = useUpdateCard();

  const {data: userFlowsData, isLoading: userFlowsDataIsLoading, isError: userFlowsDataIsError} = useGetUserFlows(authUser?.$id);

  const colorPickerPopover = useRef<HTMLIonPopoverElement>(null);
  const [colorPickerPopoverOpen, setColorPickerPopoverOpen] = useState(false);
  const [color, setColor] = useState<string>("");

  const [deleteConfirmationAlertOpen, setDeleteConfirmationAlertOpen] = useState<boolean>(false);

  const openPopover = (e: any) => {
    colorPickerPopover.current!.event = e;
    setColorPickerPopoverOpen(true);
  };

  const onDelete = () => {
    const nodeToDelete = getNode(id);
    if (nodeToDelete && flowId) {
      removeNode(nodeToDelete, flowId);
    }
  }

  useEffect(() => {
    if (data.color) {
      setColor(data.color);
    } else {
      setColor("#F5F5F5")
    }
  }, [data.color, id]);

  const updateColor = (selectedColor: string) => {
    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];
      const nodeToUpdate = fn.find((node) => node.targetId === `${id}`);

      if (nodeToUpdate) {
        data.color = selectedColor;
        setColor(selectedColor);
        setColorPickerPopoverOpen(false);

        updateNodeColor(nodeToUpdate, flowId, selectedColor);
      }
    }
  };

  return (
    <>
      <Handle type="target" position={Position.Top} style={{opacity: 0}}/>
      <IonCard style={{minWidth: "400px", maxWidth: "750px", overflowWrap: "break-word"}}>
        <IonCardHeader style={{backgroundColor: color, height: "60px"}}>
        </IonCardHeader>
        <IonCardHeader style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
          textAlign: "center"
        }}>
          <IonLabel style={{fontSize: "xx-large"}}>{data.label}</IonLabel>
        </IonCardHeader>
      </IonCard>
      <IonPopover ref={colorPickerPopover} isOpen={colorPickerPopoverOpen} showBackdrop={false}
                  onDidDismiss={() => setColorPickerPopoverOpen(false)} size={"auto"} style={{"--min-width": "280px"}}>
        <IonContent scrollY={false}>
          <ColorPicker color={color} onChange={updateColor} presetColors={PRESET_COLORS}
                       hideFreeformColorPicker={true}/>
        </IonContent>
      </IonPopover>
      <Handle type="source" position={Position.Bottom} style={{opacity: 0}}/>
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition} style={{marginTop: "-10px"}}>
        <IonCard style={{border: "1px solid #ccc"}}>
          <IonButton size={"small"} fill={"clear"} onClick={() => setDeleteConfirmationAlertOpen(true)}><IonIcon
            icon={trashOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"} onClick={openPopover}><IonIcon
            icon={colorPaletteOutline}
            style={{color: "black"}}/></IonButton>
        </IonCard>
      </NodeToolbar>
      <IonAlert
        isOpen={deleteConfirmationAlertOpen}
        onDidDismiss={() => setDeleteConfirmationAlertOpen(false)}
        header={"Delete this card?"}
        subHeader={"This action is permanent and will also remove the connections to this card"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
            },
          },
          {
            text: "OK",
            handler: (data: any) => {
              onDelete();
            },
          }
        ]}
      />
    </>
  )
}

export default TextCard;