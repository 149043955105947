import React from "react";

// https://medium.com/@trisianto/react-query-how-to-memoize-results-from-usequeries-hook-eaed9a0ec700
export function useArrayMemo(array: Array<any>) {
  // this holds reference to previous value 
  const ref = React.useRef();

  // check if each element of the old and new array match
  const areArraysConsideredTheSame =
    // @ts-ignore
    ref.current && array.length === ref.current?.length
      ? array.every((element, i) => {
        return element === ref.current?.[i];
      })
      //initially there's no old array defined/stored, so set to false
      : false;
  React.useEffect(() => {
    //only update prev results if array is not deemed the same
    if (!areArraysConsideredTheSame) {
      // @ts-ignore
      ref.current = array;
    }
  }, [areArraysConsideredTheSame, array]);


  const result = areArraysConsideredTheSame ? ref.current : array;
  //console.log(result);
  return result;
}