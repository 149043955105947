import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonPopover,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonThumbnail,
  IonToast,
  IonToolbar,
  ScrollDetail
} from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  arrowDown,
  arrowUp,
  checkmark,
  chevronDown,
  documentTextOutline,
  documentTextSharp,
  filter,
  gitNetworkOutline,
  gitNetworkSharp,
  gridOutline,
  imageSharp,
  videocamSharp
} from "ionicons/icons";
import { useGetUserNotes } from "../hooks/NotesHooks";
import { useGetUserFlows } from "../hooks/FlowHooks";
import React, { useEffect, useState } from "react";
import { ResourceItem } from "../types/ResourceTypes";
import { buildResourceList } from "../helpers/ResourceHelpers";
import { useHistory } from "react-router";

import { format } from "date-fns";
import { SortFields, SortMode, SortOrder } from "../types/FilterTypes";
import { NavSource } from "../types/NavEnums";
import { useGetUserMedia } from "../hooks/MediaHooks";
import { useAuthContext } from "../providers/AuthProvider";
import Logo from "../components/header/Logo";
import { bootstrapUser } from "../helpers/AppwriteFunctionsHelper";
import { useQueryClient } from "react-query";

enum CardDisplayOption {
  RECENTLY_UPDATED = "Recently Updated",
  LAST_IMPORTED = "Last Imported",
  FAVORITES = "Favorites",
}

const Dashboard: React.FC = () => {
  const history = useHistory();
  const {authUser} = useAuthContext();

  const queryClient = useQueryClient();
  const notesQuery = useGetUserNotes(authUser?.$id);
  const flowsQuery = useGetUserFlows(authUser?.$id);
  const mediaQuery = useGetUserMedia(authUser?.$id);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cardDisplayOption, setCardDisplayOption] = useState<CardDisplayOption>(CardDisplayOption.RECENTLY_UPDATED);
  const [cardDisplayCount, setCardDisplayCount] = useState<number>(2);

  const [lastImportedResources, setLastImportedResources] = useState<ResourceItem[]>([]);
  const [lastModifiedResources, setLastModifiedResources] = useState<ResourceItem[]>([]);
  const [userResources, setUserResources] = useState<ResourceItem[]>([]);

  const [filterMode, setFilterMode] = React.useState<string>("All");
  const [sortMode, setSortMode] = React.useState<SortFields>({
    mode: SortMode.CREATION_TIME,
    order: SortOrder.DESCENDING
  });

  const [toastMessage, setToastMessage] = useState<string>("");

  const [userHasScrolled, setUserHasScrolled] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      let numCards = 2;

      if (window.innerWidth < 480) { // iPhone SE in landscape mode
        numCards = 2;
      } else if (window.innerWidth < 768) { // iPad in portrait mode
        numCards = 3;
      } else if (window.innerWidth < 992) { // Common breakpoint for desktop screens
        numCards = 4;
      } else if (window.innerWidth < 1200) { // Another common breakpoint for desktop screens
        numCards = 6;
      } else {
        numCards = 8;
      }
      setCardDisplayCount(numCards);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (notesQuery.isSuccess && flowsQuery.isSuccess) {
      // TODO: this should be abstracted so we don't rerun this every time. Necessary? Maybe not for now.
      const resources = buildResourceList(notesQuery.data, flowsQuery.data, []);

      const lastTenModified = resources.sort((a, b) => {
        return b.modifiedAt > a.modifiedAt ? 1 : -1;
      }).slice(0, 10);

      setLastModifiedResources(lastTenModified);

      setUserResources(resources);
      setIsLoading(false);
    }
  }, [notesQuery.dataUpdatedAt, flowsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (mediaQuery.isSuccess) {
      const resources = buildResourceList([], [], mediaQuery.data);

      const lastTenImported = resources.sort((a, b) => {
        return b.modifiedAt > a.modifiedAt ? 1 : -1;
      }).slice(0, 10);
      setLastImportedResources(lastTenImported);
    }
  }, [mediaQuery.dataUpdatedAt]);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (ev.detail.scrollTop === 0) {
      setUserHasScrolled(false);
    } else {
      setUserHasScrolled(true);
    }
  }

  const handleSortModeChange = (mode: SortMode) => {
    let order = SortOrder.DESCENDING;
    if (mode === sortMode.mode) {
      order = sortMode.order === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    }

    setSortMode({
      mode: mode,
      order: order,
    })
  }

  const filterAndSortData = (data?: ResourceItem[]) => {
    if (!data) return [];

    return data.filter(
      (data: ResourceItem) => {
        if (filterMode === "All") {
          return true;
        } else if (filterMode === "Flowcharts") {
          return data.type === "flow";
        } else if (filterMode === "Notes") {
          return data.type === "note";
        } else {
          return true;
        }
      }
    ).sort(
      (a: ResourceItem, b: ResourceItem) => {
        if (sortMode.mode === SortMode.CREATION_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.createdAt > b.createdAt ? -1 : 1;
          } else {
            return a.createdAt < b.createdAt ? -1 : 1;
          }
        } else if (sortMode.mode === SortMode.UPDATE_TIME) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.modifiedAt > b.modifiedAt ? -1 : 1;
          } else {
            return a.modifiedAt < b.modifiedAt ? -1 : 1;
          }
        } else if (sortMode.mode === SortMode.ALPHABETICAL) {
          if (sortMode.order === SortOrder.DESCENDING) {
            return a.title > b.title ? -1 : 1;
          } else {
            return a.title < b.title ? -1 : 1;
          }
        } else {
          return 0; // Return 0 if neither creation time nor update time is selected for sorting
        }
      }
    )
  }

  const handleClickBootstrap = () => {
    setIsLoading(true);
    bootstrapUser(authUser?.$id).then(() => {
      queryClient.invalidateQueries("flows");
      queryClient.invalidateQueries("notes");
    }).catch((e) => {
      console.error(e);
      setToastMessage("Error setting up tutorial. Try again later.");
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const navigateToResource = (resource: ResourceItem, source: NavSource) => {
    console.log(resource)
    if (resource.type === "flow") {
      history.push(`/flows/item/${resource.id}?source=${source}`);
    } else if (resource.type === "note") {
      history.push(`/notes/item/${resource.id}?source=${source}`);
    } else if (resource.type === "media") {
      history.push(`/media/${resource.id}?source=${source}`);
    }
  }

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} message={"Loading data..."}/>
      <IonHeader className={userHasScrolled ? "" : "ion-no-border"}>
        <IonToolbar>
          <IonButtons slot="start">
            <Logo/>
          </IonButtons>
        </IonToolbar>
        {/*
        <IonToolbar>
          <IonSearchbar mode={"ios"} onIonFocus={() => history.push("tabs/search")} value=""></IonSearchbar>
        </IonToolbar>
        */}
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScroll={handleScroll}
      >
        <IonRow className={"ion-padding-start ion-padding-end"}>
          <IonSegment mode={"md"} scrollable={true} value={cardDisplayOption}>
            {lastModifiedResources.length > 0 &&
                <IonSegmentButton value={CardDisplayOption.RECENTLY_UPDATED}
                                  onClick={() => setCardDisplayOption(CardDisplayOption.RECENTLY_UPDATED)}>
                    <IonLabel style={{fontSize: "x-small"}}>Recently Updated</IonLabel>
                </IonSegmentButton>
            }
            {lastImportedResources.length > 0 &&
                <IonSegmentButton value={CardDisplayOption.LAST_IMPORTED}
                                  onClick={() => setCardDisplayOption(CardDisplayOption.LAST_IMPORTED)}>
                    <IonLabel style={{fontSize: "x-small"}}>Last Imported</IonLabel>
                </IonSegmentButton>
            }
            {/* REMOVE FOR NOW BEFORE TAGGING IS ENABLED
            <IonSegmentButton value={CardDisplayOption.FAVORITES} onClick={() => setCardDisplayOption(CardDisplayOption.FAVORITES)}>
              <IonLabel style={{fontSize: "x-small"}}>Favorites</IonLabel>
            </IonSegmentButton>
            */}
          </IonSegment>
        </IonRow>
        <Swiper
          spaceBetween={10}
          slidesPerView={cardDisplayCount + 0.3}
          navigation={false}
          style={{marginTop: "-8px"}}
        >
          {cardDisplayOption === CardDisplayOption.RECENTLY_UPDATED && lastModifiedResources.map((resource, index) => (
            <SwiperSlide key={index}>
              <IonCard
                mode={"ios"}
                onClick={() => navigateToResource(resource, NavSource.HOME_CARD)}
                style={{
                  height: '100%',
                  width: '100%',
                  margin: '15px',
                  minHeight: "130px",
                  boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)"
                }}
              >
                <IonCardContent style={{backgroundColor: '#8b5f96', height: "50px"}}>
                </IonCardContent>
                <IonIcon size={"large"} style={{position: 'absolute', top: "25%", right: "10%"}} color="primary"
                         icon={resource.type === "flow" ? gitNetworkSharp : documentTextSharp}/>
                <IonCardContent>
                  <div style={{paddingTop: "0px"}}/>
                  <IonCardTitle
                    style={{
                      fontSize: "small",
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      margin: 0
                    }}>
                    {resource.title}
                  </IonCardTitle>
                </IonCardContent>
              </IonCard>
            </SwiperSlide>
          ))}
          {cardDisplayOption === CardDisplayOption.LAST_IMPORTED && lastImportedResources.map((resource, index) => (
            <SwiperSlide key={index}>
              <IonCard
                mode={"ios"}
                onClick={() => navigateToResource(resource, NavSource.HOME_CARD)}
                style={{
                  backgroundColor: "#d2d2d2",
                  height: '100%',
                  width: '100%',
                  margin: '15px',
                  minHeight: "130px",
                  boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)"
                }}
              >
                <IonIcon style={{position: 'absolute', top: 10, right: 5}} color="dark"
                         icon={resource.type === "image" ? videocamSharp : imageSharp}/>
                <IonThumbnail style={{width: "100%", display: "flex", justifyContent: "center"}}>
                  <picture>
                    <img src={`${resource.thumbnail?.url}`} alt={resource.subtitle}/>
                  </picture>
                </IonThumbnail>
              </IonCard>
            </SwiperSlide>
          ))}
        </Swiper>
        {userResources.length > 0 &&
            <div className={"ion-padding"}>
                <div style={{marginBottom: "-15px", marginTop: "-5px"}}>
                    <IonRow>
                        <IonChip id={"trigger-type-filter-popover"}>
                            <IonIcon
                                icon={filterMode === "All" ? gridOutline : filterMode === "Flowcharts" ? gitNetworkSharp : filterMode === "Notes" ? documentTextOutline : gridOutline}/>{filterMode}
                            <IonIcon icon={chevronDown}/>
                        </IonChip>
                        <IonChip id={"trigger-sort-popover"}>
                            <IonIcon icon={filter}/>Sort<IonIcon icon={chevronDown}/>
                        </IonChip>
                    </IonRow>
                </div>
                <IonCard mode={"ios"}>
                    <IonList style={{paddingTop: "5px"}}>
                      {filterAndSortData(userResources).map((resource, index) => (
                        <IonItem key={index}
                                 style={{"--border-color": "#efefef", marginTop: "4px", marginBottom: "4px"}}
                                 onClick={() => navigateToResource(resource, NavSource.HOME)}
                        >
                          <IonIcon size={"small"}
                                   icon={resource.type === "flow" ? gitNetworkSharp : documentTextOutline}
                                   style={{position: "absolute", right: "15px"}}
                          />
                          <IonGrid>
                            <IonRow>
                              <IonCol className={"ion-text-start"}>
                                <IonLabel style={{maxWidth: "80%"}}>{resource.title}</IonLabel>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol className={"ion-text-start"}>
                                <IonLabel
                                  style={{
                                    fontSize: "x-small",
                                    color: "#5b5b5b"
                                  }}>{format(new Date(resource.modifiedAt), "MMM d, yyyy")}</IonLabel>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      ))}
                    </IonList>
                </IonCard>
            </div>
        }
        {/* Show placeholder for no content */}
        {userResources.length === 0 &&
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center"
            }}>
                <IonImg style={{height: "40vh", width: "70vw", paddingTop: "20px"}}
                        src={process.env.PUBLIC_URL + "/assets/app/notes-placeholder.png"}
                        alt={"Your notes shows up here!"}/>
                <IonLabel style={{
                  fontSize: 'small',
                  color: '#777',
                  textAlign: 'center',
                  marginTop: '10px'
                }}>Your content will show up here! Get started by selecting ➕ in the bottom of the screen.</IonLabel>
                <IonLabel style={{
                  fontSize: 'small',
                  color: '#777',
                  textAlign: 'center',
                  marginTop: '20px'
                }}>Not sure how to use Grapple Flows?</IonLabel>
                <IonButton style={{marginTop: "10px"}} onClick={() => handleClickBootstrap()}>Start Tutorial</IonButton>
            </div>
        }
        {/* Filter Media Type */}
        <IonPopover trigger={"trigger-type-filter-popover"} dismissOnSelect={true}>
          <IonList>
            <IonItem button onClick={() => setFilterMode("All")} lines={"none"} detail={false}>
              <IonIcon icon={gridOutline}
                       style={{paddingRight: "10px"}}/><IonLabel>All</IonLabel>{filterMode === "All" &&
                <IonIcon icon={checkmark}/>}
            </IonItem>
            <IonItem button onClick={() => setFilterMode("Flowcharts")} lines={"none"} detail={false}>
              <IonIcon icon={gitNetworkOutline}
                       style={{paddingRight: "10px"}}/><IonLabel>Flowcharts</IonLabel>{filterMode === "Flowcharts" &&
                <IonIcon icon={checkmark}/>}
            </IonItem>
            <IonItem button onClick={() => setFilterMode("Notes")} lines={"none"} detail={false}>
              <IonIcon icon={documentTextOutline}
                       style={{paddingRight: "10px"}}/><IonLabel>Notes</IonLabel>{filterMode === "Notes" &&
                <IonIcon icon={checkmark}/>}
            </IonItem>
          </IonList>
        </IonPopover>
        {/* Filter Sorting */}
        <IonPopover trigger={"trigger-sort-popover"} dismissOnSelect={true}>
          <IonList>
            <IonItem button onClick={() => handleSortModeChange(SortMode.CREATION_TIME)} lines={"none"} detail={false}>
              <IonLabel>Created</IonLabel>{sortMode.mode === SortMode.CREATION_TIME ? (
              sortMode.order === SortOrder.DESCENDING ? (
                <IonIcon icon={arrowDown}/>
              ) : (
                <IonIcon icon={arrowUp}/>
              )
            ) : (
              <></>
            )}
            </IonItem>
            <IonItem button onClick={() => handleSortModeChange(SortMode.UPDATE_TIME)} lines={"none"} detail={false}>
              <IonLabel>Last Modified</IonLabel>{sortMode.mode === SortMode.UPDATE_TIME ? (
              sortMode.order === SortOrder.DESCENDING ? (
                <IonIcon icon={arrowDown}/>
              ) : (
                <IonIcon icon={arrowUp}/>
              )
            ) : (
              <></>
            )}
            </IonItem>
            <IonItem button onClick={() => handleSortModeChange(SortMode.ALPHABETICAL)} lines={"none"} detail={false}>
              <IonLabel>Alphabetical</IonLabel>{sortMode.mode === SortMode.ALPHABETICAL ? (
              sortMode.order === SortOrder.DESCENDING ? (
                <IonIcon icon={arrowDown}/>
              ) : (
                <IonIcon icon={arrowUp}/>
              )
            ) : (
              <></>
            )}
            </IonItem>
          </IonList>
        </IonPopover>
        <IonToast
          isOpen={toastMessage !== ""}
          onDidDismiss={() => setToastMessage("")}
          message={toastMessage}
        />
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
