import { ID, Models, Storage } from "appwrite";
import getConfig, { client } from "../configs/AppwriteConfigs";

const config = getConfig();
const storage = new Storage(client);

export const uploadImageFile = async (imageFile: File): Promise<Models.File> => {
  return storage.createFile(
    config.imagesBucketId,
    ID.unique(),
    imageFile,
  );
}

export const deleteImageFile = async (imageId: string): Promise<{}> => {
  return storage.deleteFile(
    config.imagesBucketId,
    imageId,
  );
}
