import React from "react";
import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import MediaGallery from "../components/media/MediaGallery";
import Logo from "../components/header/Logo";

const Media: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Logo />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <MediaGallery />
    </IonPage>
  )
}

export default Media;