import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonContent, IonDatetime, IonInput, IonItem, IonModal} from '@ionic/react';
import {Editor, EditorContent} from '@tiptap/react';
import {format, parseISO} from "date-fns";

import EditorMenuBar from './EditorMenuBar';
import TextSelectionBar from './TextSelectionBar';

import './NoteEditor.css';


interface NoteEditorFields {
  editor: Editor;
  isTextSelected?: boolean;
  noteTitle: string;
  setNoteTitle?: React.Dispatch<React.SetStateAction<string>>;
  noteDate: string;
  setNoteDate?: React.Dispatch<React.SetStateAction<string>>;
  autosave: boolean;
  saveData: () => void;
}

const NoteEditor = ({editor, isTextSelected, noteTitle, setNoteTitle, noteDate, setNoteDate, autosave, saveData}: NoteEditorFields) => {

  const modal = useRef<HTMLIonModalElement>(null);

  // Handle toolbar margin shifting
  const [toolbarMargin, setToolbarMargin] = useState<number>(0);
  const toolbarWrap = useRef<HTMLInputElement>();
  const toolbar = useRef<HTMLInputElement>();

  const [titleInputSelected, setTitleInputSelected] = useState(false);

  useEffect(() => {
    const showToolbar = function () {
      if (toolbarMargin > 0) {
        setToolbarMargin(0);
      }
      setMargin();
    };

    window.addEventListener("scroll", showToolbar);
    return () => {
      if (visualViewport) {
        window.removeEventListener('scroll', showToolbar);
      }
    };
  }, []);

  /* Adapted from: https://www.codemzy.com/blog/sticky-fixed-header-ios-keyboard-fix */
  const setMargin = () => {
    if (toolbarWrap.current) {
      const newPosition = toolbarWrap.current.getBoundingClientRect().top;
      if (newPosition < -1) {
        const fixPosition = Math.abs(newPosition);
        setToolbarMargin(fixPosition);
      } else {
        setToolbarMargin(0);
      }
    }
  };

  const focusEditor = () => {
    if (editor && editor.isEditable) {
      editor.commands.focus();
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <div ref={toolbarWrap}>
        {/* @ts-ignore */}
        <div ref={toolbar} style={{marginTop: `${toolbarMargin}px`}}>
          {isTextSelected ? <TextSelectionBar editor={editor!}/> : < EditorMenuBar editor={editor!}/>}
        </div>
      </div>
      <IonContent fullscreen={true}>
        <IonItem color="none" lines='none'>
          <IonInput
            type="text"
            className="header-text"
            placeholder="Untitled"
            value={noteTitle}
            maxlength={50}
            counter={titleInputSelected}
            counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
            onInput={(e) => {
              if (setNoteTitle) {
                setNoteTitle((e.target as HTMLInputElement).value);
              }
            }}
            onIonInput={() => {
              setTitleInputSelected(true);
            }}
            onBlur={() => {
              setTitleInputSelected(false);
              if (autosave) {
                saveData();
              }
            }}
          />
          <IonButton
            id="open-date-modal"
            expand="block"
            color="light"
            style={{fontSize: "0.8em", textTransform: "none"}}
          >
            {format(parseISO(noteDate!), 'EE, MMM d, yyyy')}
          </IonButton>
        </IonItem>
        <IonModal id="date-modal" keepContentsMounted={true} ref={modal} trigger="open-date-modal">
          <IonDatetime
            presentation="date"
            defaultValue={noteDate}
            onIonChange={e => setNoteDate!(e.detail.value as string)}
            showDefaultButtons={true}
            doneText="Done"
            onBlur={() => {
              if (autosave) {
                saveData();
              }
            }}
          >
          </IonDatetime>
        </IonModal>
        <div className="editor-container">
          <EditorContent className="ion-padding" editor={editor}/>
          <div className="clickable-area" onClick={() => focusEditor()}></div>
        </div>
      </IonContent>
    </>
);
};

export default NoteEditor;