import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading, IonModal,
  IonPage, IonRow,
  IonText,
  IonToolbar
} from "@ionic/react";
import {chevronBack, ellipsisHorizontalCircleOutline} from "ionicons/icons";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router";

import {marshalNoteSection, Note} from "../types/NoteTypes";
import {useCreateNote, useDeleteNote, useGetUserNotes, useUpdateNote} from "../hooks/NotesHooks";
import NoteEditor from "../components/editor/NoteEditor";
import useNoteEditor from "../hooks/NoteEditorHooks";
import {mapNoteContentToNoteSection, populateSectionContent, setYouTubeDimensions} from "../utils/NoteUtils";
import { useAuthContext } from "../providers/AuthProvider";

interface NoteDetailFields {
  noteId?: string;
  editable: true;
}

interface AutosaveFields {
  noteTitle: string;
  noteDate: string;
  noteContent: string;
}

const NoteDetail: React.FC = () => {
  const {authUser} = useAuthContext();

  /* Only used for setting existing notes */
  const {noteId} = useParams<Record<string, string | undefined>>();
  const notesQuery = useGetUserNotes(authUser?.$id);
  const deleteNoteQuery = useDeleteNote();

  const settingsModal = useRef<HTMLIonModalElement>(null);

  // const createNote = useCreateNote();
  const updateNote = useUpdateNote();

  const history = useHistory();

  const [noteTitle, setNoteTitle] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>((new Date()).toISOString());

  const saveData = () => {
    //console.debug("Save attempt")

    let content: string | undefined = noteEditor.getHTML();
    if (noteEditor.isEmpty) {
      content = undefined;
    }

    if (!autoSaveFieldsChanged()) {
      return;
    }

    const updatedNote: Note = {
      ownerId: authUser?.$id,
      isGlobal: true,
      type: "",
      name: noteTitle,
      content: content,
      date: noteDate,
    };

    const noteSections = mapNoteContentToNoteSection(updatedNote);
    populateSectionContent(noteSections, updatedNote);
    updatedNote.noteSections = marshalNoteSection(noteSections);

    updatedNote.$id = noteId; // inject id for update
    updateNote.mutate(updatedNote, {
      onSuccess: () => console.debug("Updated note data"),
      onError: (e) => console.error(e),
    });
  }

  const autosave = true;
  const {noteEditor, isTextSelected} = useNoteEditor({autosave, saveData});

  const [showLoading, setShowLoading] = useState(false);

  const [lastSavedFields, setLastSavedFields] = useState<AutosaveFields>();

  useEffect(() => {
    // Initialize editor with content
    if (noteId && notesQuery.data && noteEditor) {

      setShowLoading(true);
      // @ts-ignore - models.Document not playing nice
      const noteData = notesQuery.data!.find((v: Note) => v.$id === noteId);

      console.debug(noteData);
      if (!noteData) {
        console.error("No note found");
        // HANDLE THIS
      } else {
        setNoteTitle(noteData.name);
        setNoteDate(noteData.date);

        setLastSavedFields({
          noteTitle: noteData?.name,
          noteDate: noteData?.date,
          noteContent: noteData?.content,
        })
        // Inject this to change the editor's YouTube dimensions since they are stored statically in the actual HTML
        const cleanedContent = setYouTubeDimensions(noteData.content);
        noteEditor.commands.setContent(cleanedContent);
      }

      setShowLoading(false);
    }

    // Initialize new empty editor
    /* we don't need this anymore
    if (noteEditor && noteEditor.isEmpty) {
      setNoteTitle("");
      setNoteDate((new Date()).toISOString());
      noteEditor.commands.clearContent();
    }
    */
  }, [noteEditor, noteId, notesQuery.data]);

  const autoSaveFieldsChanged = (): boolean => {
    if (!lastSavedFields) {
      return true;
    }

    return noteTitle !== lastSavedFields.noteTitle ||
      noteDate !== lastSavedFields.noteDate ||
      noteEditor.getHTML() !== lastSavedFields.noteContent;
  }

  const handleDeleteNote = () => {
    if (noteId) {
      history.replace("/main");

      // delete after redirect so it moves faster
      // also, this page itself will be wonky because it tries to reference this note's data
      deleteNoteQuery.mutate(noteId, {
        onSuccess: () => {
        }
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.back()}><IonIcon icon={chevronBack}/></IonButton>
            <IonText>{noteTitle}</IonText>
          </IonButtons>
          <IonButtons slot={"end"}>
            <IonButton fill={"clear"} id={"open-settings-modal"}>
              <IonIcon icon={ellipsisHorizontalCircleOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div>
          <NoteEditor
            editor={noteEditor}
            isTextSelected={isTextSelected}
            noteTitle={noteTitle}
            setNoteTitle={setNoteTitle}
            noteDate={noteDate}
            setNoteDate={setNoteDate}
            autosave={true}
            saveData={saveData}
          />
        </div>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={5000}
        />
        <IonModal ref={settingsModal} trigger="open-settings-modal" initialBreakpoint={0.5} breakpoints={[0, 0.5]} draggable={false} handle={false}>
          <IonContent className={"ion-padding"}>
            <IonRow className={"ion-justify-content-center"}><IonButton color={"danger"} id="present-deletion-confirmation">Delete</IonButton></IonRow>
            <IonAlert
              header="Delete Confirmation"
              subHeader="Permanently delete this note?"
              trigger="present-deletion-confirmation"
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                },
                {
                  text: 'OK',
                  role: 'confirm',
                  handler: () => {
                    handleDeleteNote();
                  },
                },
              ]}
            ></IonAlert>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default NoteDetail;
