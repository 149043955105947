import React, {ChangeEvent, useState} from "react";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonToolbar
} from "@ionic/react";
import {closeCircle, cloudUpload, logoYoutube} from "ionicons/icons";
import {useImportYoutube, useUploadImage} from "../../hooks/MediaHooks";
import {MediaFields, MediaUploadFields} from "../../types/MediaType";
import {
  createYouTubeVideoThumbnailUrl,
  extractYouTubeVideoId,
  isYouTubeUrl,
  stripExtraParamsFromYouTubeUrl
} from "../../utils/YouTubeUtils";
import { MAX_MEDIA_DESCRIPTION_LENGTH } from "../../configs/Constants";
import { useAuthContext } from "../../providers/AuthProvider";

interface MediaUploaderProps {
  maxImageFileSize: number; // bytes
  dismiss?: () => void;
}

enum MediaUploadMode {
  IMAGE,
  YOUTUBE,
}

const MediaUploader: React.FC<MediaUploaderProps> = ({maxImageFileSize, dismiss}: MediaUploaderProps) => {
  const {authUser} = useAuthContext();

  const [uploadMode, setUploadMode] = useState(MediaUploadMode.IMAGE);

  const [mediaDescription, setMediaDescription] = useState<string>("");

  // Image Upload
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);

  // YouTube Upload
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState<string>("");
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadImageAndMetadata = useUploadImage();
  const importYoutubeVideo = useImportYoutube();

  const clearSelection = () => {
    setSelectedImages([]);
    setSelectedFilenames([]);

    setYoutubeVideoId("");
    setYoutubeVideoUrl("");
  }

  const canUploadFile = () => {
    if (uploadMode === MediaUploadMode.IMAGE) {
      if (selectedImages === null) {
        return false;
      }
      if (selectedFilenames === null) {
        return false;
      }
      if (errorMessage !== null) {
        return false;
      }
      return true;
    } else if (uploadMode === MediaUploadMode.YOUTUBE) {
      if (youtubeVideoUrl === null || youtubeVideoUrl === "") {
        return false;
      }
      if (youtubeVideoId === null || youtubeVideoId === "") {
        return false;
      }
      if (errorMessage !== null || errorMessage === "") {
        return false;
      }
      return true;
    }
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let totalSize = 0;

      for(let i = 0; i < files.length; i++) {
        const file = files[i];
        totalSize += file.size;
        
        if(totalSize > maxImageFileSize) {
          setErrorMessage(`Total file size exceeds the allowed limit of ${Math.floor(maxImageFileSize / 1024 / 1024)}MB`);
          return;  
        };

        setSelectedImages(prevImages => [...prevImages, file]);
        setSelectedFilenames(prevFilenames => [...prevFilenames, file.name]);
      }

      setErrorMessage(null);
    }
  };

  const onImageUpload = () => {
    setIsUploading(true);
    const uploadMetadata: MediaUploadFields = {
      files: selectedImages!,
      description: mediaDescription.length > 0 ? mediaDescription : undefined,
      type: "image",
      tags: [],
      ownerId: authUser?.$id,
      imageCompressionOptions: {
        maxWidthOrHeight: 700,
        maxSizeMB: 0.8,
      }
    };

    const start = new Date().getTime();
    uploadImageAndMetadata.mutate(uploadMetadata, {
      onSuccess: () => {
      },
      onError: (e) => {
        console.error(e);
      },
      onSettled: () => {
        const end = new Date().getTime();
        console.debug(`Uploading took ${(end - start) / 1000}s`);
        setIsUploading(false);
        // TODO: make this run in the background
        if (dismiss) {
          dismiss();
        }
      }
    });
  }

  const onYouTubeImport = () => {
    setIsUploading(true);
    const uploadMetadata: MediaFields = {
      url: youtubeVideoUrl,
      thumbnailUrl: createYouTubeVideoThumbnailUrl(youtubeVideoId),
      description: mediaDescription,
      type: "youtube",
      tags: [],
      ownerId: authUser?.$id,
      fileId: youtubeVideoId,
    }

    importYoutubeVideo.mutate(uploadMetadata, {
      onSettled: () => {
        setIsUploading(false);
        if (dismiss) {
          dismiss();
        }
      }
    })
  }

  const getDescriptionText = () => {
    if (uploadMode === MediaUploadMode.IMAGE) {
      return "Describe your image...";
    } else {
      return "Describe the YouTube video...";
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"end"}>
            {dismiss &&
                <IonButton onClick={() => dismiss()}>Close</IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment mode={"ios"} value={uploadMode === MediaUploadMode.IMAGE ? "image" : "youtube"}
                    onIonChange={(e) => setUploadMode(e.detail.value === "image" ? MediaUploadMode.IMAGE : MediaUploadMode.YOUTUBE)}>
          <IonSegmentButton value={"image"}>
            <IonLabel>Image</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={"youtube"}>
            <IonLabel>YouTube</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div style={{marginTop: "20px"}}></div>
        {uploadMode === MediaUploadMode.IMAGE &&
            <div
                className="image-placeholder"
                style={{
                  width: "80%",
                  margin: "0 auto",
                  height: "200px",
                  border: "dotted 2px #ccc",
                  overflow: "auto", // Enable scrolling if the content overflows
                  maxHeight: "400px", // Set a maximum height for the container
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
            >
              {selectedImages.length === 0 ? (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <IonIcon icon={cloudUpload}/>
                  <IonButton fill={"clear"} size={"small"} color={"primary"}>
                    <label htmlFor="imageUpload">
                      Select File to Upload
                      <input
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{display: "none"}}
                      />
                    </label>
                  </IonButton>
                </div>
              ) : (
                selectedImages.map((image, index) => (
                  <IonImg
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt="Selected upload image"
                    style={{maxWidth: '100%', marginTop: '10px'}}
                  />
                ))
              )} 
            </div>
        }
        {uploadMode === MediaUploadMode.YOUTUBE &&
            <div
                className="image-placeholder"
                style={{
                  width: "80%",
                  margin: "0 auto",
                  height: "200px",
                  border: "dotted 2px #ccc",
                  overflow: "auto", // Enable scrolling if the content overflows
                  maxHeight: "400px", // Set a maximum height for the container
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
            >
              {youtubeVideoUrl ? (
                <IonImg
                  src={createYouTubeVideoThumbnailUrl(youtubeVideoId)}
                  alt="Selected YouTube video"
                  style={{maxWidth: '100%', marginTop: '10px'}}
                />
              ) : (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <IonIcon icon={logoYoutube}/>
                  <IonButton id="present-youtube-url-alert" fill={"clear"} size={"small"} color={"primary"}>
                    <label htmlFor="imageUpload">
                      Set YouTube URL to Import
                    </label>
                  </IonButton>
                </div>
              )}
            </div>
        }
        {youtubeVideoUrl &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <IonLabel style={{fontSize: "small"}}>{youtubeVideoUrl}</IonLabel><IonButton fill={"clear"}
                                                                                             onClick={() => clearSelection()}><IonIcon
                icon={closeCircle} style={{color: "gray"}}/></IonButton>
            </div>
        }
        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
        <>
          <IonItem className={"ion-padding"} lines={"none"}>
            <IonInput
              label="Description (Optional)"
              labelPlacement="stacked"
              placeholder={getDescriptionText()}
              maxlength={MAX_MEDIA_DESCRIPTION_LENGTH}
              counter={true}
              counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
              onIonInput={(e) => setMediaDescription(e.target.value as string)}
            />
          </IonItem>
          <div style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center"
          }}>
            {isUploading ? <IonSpinner></IonSpinner> :
              <IonButton disabled={!canUploadFile()} onClick={() => {
                if (uploadMode === MediaUploadMode.IMAGE && selectedImages) {
                  onImageUpload();
                } else if (uploadMode === MediaUploadMode.YOUTUBE && youtubeVideoId) {
                  onYouTubeImport();
                }
              }}>Import</IonButton>
            }
          </div>
        </>
      </IonContent>
      {/* Wrapping this to be rendered conditionally helps put it on top so it's not hidden when presented*/}
      {uploadMode === MediaUploadMode.YOUTUBE &&
          <IonAlert
              trigger="present-youtube-url-alert"
              header="YouTube URL"
              inputs={[
                {
                  name: 'inputUrl',
                  type: 'url',
                  value: youtubeVideoUrl,
                  placeholder: 'https://youtu.be/ACAI123',
                },
              ]}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                  handler: () => {
                  },
                },
                {
                  text: "OK",
                  handler: (data: any) => {
                    const videoId = extractYouTubeVideoId(data.inputUrl);
                    console.debug(videoId);
                    if (isYouTubeUrl(data.inputUrl) && videoId) {
                      setYoutubeVideoUrl(stripExtraParamsFromYouTubeUrl(data.inputUrl));
                      setYoutubeVideoId(videoId);
                    } else {
                      setErrorMessage("Invalid YouTube URL");
                    }
                  },
                }
              ]}
          >
          </IonAlert>
      }
    </>
  );
};

export default MediaUploader;