import { Databases, ID, Models, Query } from "appwrite";
import getConfig, { client } from "../configs/AppwriteConfigs";
import { Note } from "../types/NoteTypes";
import { DBFlow, FlowConnection, FlowFields, FlowNode } from "../types/FlowTypes";
import { DBMedia, MediaFields } from "../types/MediaType";
import { PAGINATION_SIZE } from "../configs/Constants";

const config = getConfig();
const db = new Databases(client);

export const getUserNotes = async (userId: string): Promise<Array<Models.Document>> => {
  console.debug("AW - getUserNotes");
  const documents = await db.listDocuments(config.databaseId, config.notesCollectionId, [
    Query.equal("ownerId", userId),
    Query.orderDesc("$createdAt"),
    Query.limit(PAGINATION_SIZE),
  ]);
  return documents.documents;
};

export const getNote = async (noteId: string): Promise<Models.Document> => {
  console.debug("AW - getNote");
  const document = await db.getDocument(config.databaseId, config.notesCollectionId, noteId);
  return document;
};

export const getNotes = async (noteIds: string[]): Promise<Array<Models.Document>> => {
  console.debug("AW - getNotes");
  const documents = await db.listDocuments(config.databaseId, config.notesCollectionId, [
    Query.equal("$id", noteIds),
    Query.limit(PAGINATION_SIZE),
  ]);
  return documents.documents;
};

export const createNote = async (noteData: Note): Promise<Models.Document> => {
  console.debug("AW - createNote");
  return db.createDocument(config.databaseId, config.notesCollectionId, ID.unique(), noteData);
};

export const updateNote = async (noteData: Note): Promise<Models.Document> => {
  console.debug("AW - updateNote");
  if (noteData.$id) {
    return db.updateDocument(config.databaseId, config.notesCollectionId, noteData.$id, noteData);
  } else {
    throw Error("Note to update did not contain an $id field");
  }
};

export const deleteNote = async (noteId: string): Promise<{}> => {
  console.debug("AW - deleteNote");
  return db.deleteDocument(config.databaseId, config.notesCollectionId, noteId);
};

/* FLOWS */

export const getUserFlows = async (userId: string): Promise<Array<DBFlow>> => {
  console.debug("AW - getUserFlows");
  const documents = await db.listDocuments(config.databaseId, config.flowsCollectionId, [
    Query.equal("ownerId", userId),
    Query.orderDesc("$createdAt"),
    Query.limit(PAGINATION_SIZE),
  ]);

  return documents.documents as DBFlow[];
};

export const getFlow = async (flowId: string): Promise<DBFlow> => {
  console.debug("AW - getFlow");
  const document = await db.getDocument(config.databaseId, config.flowsCollectionId, flowId);
  return document as DBFlow;
};

export const createFlow = async (flowData: FlowFields): Promise<DBFlow> => {
  console.debug("AW - createFlow");
  return db.createDocument(config.databaseId, config.flowsCollectionId, ID.unique(), flowData);
};

// Add more fields or break them up into an interface as we need to add more fields to update
export const updateFlowMetadata = async (flowId: string, flowName: string): Promise<DBFlow> => {
  console.log("AW - updateFlow");
  return db.updateDocument(config.databaseId, config.flowsCollectionId, flowId, {
    name: flowName
  });
}

export const deleteFlow = async (flowId: string): Promise<{}> => {
  console.debug("AW - deleteFlow");
  return db.deleteDocument(config.databaseId, config.flowsCollectionId, flowId);
};

export const setFlowNodes = async (flowId: string, flowNodes: FlowNode[]): Promise<DBFlow> => {
  console.debug("AW - setFlowNodes - flowId " + flowId);

  return db.updateDocument(config.databaseId, config.flowsCollectionId, flowId, {
    nodes: flowNodes.map((v: FlowNode) => JSON.stringify(v)),
  });
};

export const setFlowConnections = async (flowId: string, flowConnections: FlowConnection[]): Promise<DBFlow> => {
  console.debug("AW - setFlowConnections - flowId" + flowId);

  return db.updateDocument(config.databaseId, config.flowsCollectionId, flowId, {
    connectionData: flowConnections.map((v: FlowConnection) => JSON.stringify(v)),
  });
};

export const setFlowNodesAndConnections = async (flowId: string, flowNodes?: FlowNode[], flowConnections?: FlowConnection[]): Promise<DBFlow> => {
  console.debug("AW - setFlowNodesAndConnections");

  if (flowNodes !== undefined && flowConnections !== undefined) {
    console.debug("setFlowNodesAndConnections - updateDocument");
    return db.updateDocument(config.databaseId, config.flowsCollectionId, flowId, {
      connectionData: flowConnections.map((v: FlowConnection) => JSON.stringify(v)),
      nodes: flowNodes.map((v: FlowNode) => JSON.stringify(v)),
    });
  }

  if (flowNodes === undefined && flowConnections !== undefined) {
    console.debug("setFlowNodesAndConnections - setFlowConnections");
    return setFlowConnections(flowId, flowConnections);
  }

  if (flowNodes !== undefined && flowConnections === undefined) {
    console.debug("setFlowNodesAndConnections - setFlowNodes");
    return setFlowNodes(flowId, flowNodes);
  } else {
    throw new Error("Invalid inputs; either nodes or connections need to be defined.");
  }
};

/** MEDIA **/

export const getUserMedia = async (userId: string): Promise<Array<DBMedia>> => {
  console.debug("AW - getUserMedia");
  const documents = await db.listDocuments(config.databaseId, config.mediaCollectionId, [
    Query.equal("ownerId", userId),
    Query.orderDesc("$createdAt"),
    Query.limit(PAGINATION_SIZE),
  ]);

  return documents.documents as DBMedia[];
}

export const getMedia = async (mediaId: string): Promise<DBMedia> => {
  console.debug("AW - getMedia");
  const document = await db.getDocument(config.databaseId, config.mediaCollectionId, mediaId);
  return document as DBMedia;
}

export const createMedia = async (mediaData: MediaFields): Promise<DBMedia> => {
  console.debug("AW - createMedia");

  return db.createDocument(config.databaseId, config.mediaCollectionId, ID.unique(), mediaData);
}

// TODO: update tags; update description
export interface MediaUpdateFields {
  tags?: string[];
  description?: string;
  additionalData?: object;
}

export const setMediaFields = async (mediaId: string, mediaUpdateFields: MediaUpdateFields): Promise<DBMedia> => {
  console.debug("AW - setMediaFields");

  const serializedFields = {
    tags: mediaUpdateFields.tags,
    description: mediaUpdateFields.description,
    additionalData: mediaUpdateFields.additionalData ? JSON.stringify(mediaUpdateFields.additionalData) : undefined,
  };

  return db.updateDocument(config.databaseId, config.mediaCollectionId, mediaId, serializedFields);
}

export const deleteMedia = async (mediaId: string): Promise<{}> => {
  console.debug("AW - deleteMedia");
  return db.deleteDocument(config.databaseId, config.mediaCollectionId, mediaId);
}

export const retrieveWaitlistUser = async (email: string): Promise<Models.Document> => {
  console.debug("AW - retrieveWaitlistUser");
  const documents = await db.listDocuments(config.databaseId, config.waitlistCollectionId, [
    Query.equal("email", email),
    Query.limit(1),
  ]);

  if (documents && documents.total > 0) {
    return documents.documents[0];
  } else {
    throw new Error("Thanks for your interest! Please sign up for the waitlist.");
  }
}

export const addUserToWaitlist = async (email: string): Promise<Models.Document> => {
  console.debug("AW - addUserToWaitlist");
  return db.createDocument(config.databaseId, config.waitlistCollectionId, ID.unique(), {
    email,
  });
}

export const updateUserWaitlistUserId = async (waitlistId: string, email: string, userId: string): Promise<Models.Document> => {
  console.debug("AW - updateUserWaitlistUserId");
  return db.updateDocument(config.databaseId, config.waitlistCollectionId, waitlistId, {
    email: email,
    userId: userId,
    enabled: true,
  });
}

export const createFeedback = async (name: string, email: string, message: string, optIn: boolean, userId?: string, session?: Models.Session): Promise<Models.Document> => {
  console.debug("AW - createFeedback");
  return db.createDocument(config.databaseId, config.feedbackCollectionId, ID.unique(), {
    name: name,
    email: email,
    message: message,
    contact: optIn,
    userId: userId,
    session: session ? JSON.stringify(session) : undefined,
  });
}