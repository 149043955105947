import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonToast,
  IonToolbar
} from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { chevronBack } from "ionicons/icons";
import { createFeedback } from "../helpers/AppwriteDatabaseHelper";
import { useAuthContext } from "../providers/AuthProvider";

const UserFeedback = () => {

  const history = useHistory();
  const {logOut, authUser, getSession} = useAuthContext();

  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [formContent, setFormContent] = React.useState("");
  const [optIn, setOptIn] = React.useState(false);

  const [feedbackToast, setFeedbackToast] = React.useState({isOpen: false, message: "", color: ""});

  useEffect(() => {
    setSubmitDisabled(true);
    setFormContent("");
    setOptIn(false);
  }, []);

  const handleSubmit = async () => {
    console.log(formContent);
    console.log(optIn);

    const email = authUser?.email;
    const name = authUser?.name;
    const userId = authUser?.$id;
    const session = await getSession();

    setSubmitDisabled(true);
    createFeedback(name, email, formContent, optIn, userId, session).then(() => {
      setFeedbackToast({isOpen: true, message: "Thank you for your thoughts!", color: "success"});
    }).catch((error) => {
      console.error("Error creating feedback:", error);
      setFeedbackToast({isOpen: true, message: "Error submitting feedback", color: "danger"});
    }).finally(
      history.back()
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.back()}><IonIcon icon={chevronBack}/></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={"ion-padding"} style={{maxWidth: "1000px"}}>
          <IonCard mode={"ios"} style={{
            backgroundColor: "#8b5f96",
            color: "white"
          }}>
            <IonCardContent>
              <IonLabel>We would appreciate it if you could fill out our <a href={"https://forms.gle/QndW4oTVTfpTWWNX9"}
                                                                            target={"_blank"}
                                                                            style={{color: "rgba(38,43,255,0.61)"}}>Grapple
                Flows Beta Survey</a> 👈</IonLabel>
            </IonCardContent>
          </IonCard>
          <IonCard mode={"ios"} style={{backgroundColor: "#f8f8f8", "--border-color": "#000000"}}
                   className={"ion-padding"}>
            <IonCardTitle>Feedback</IonCardTitle>
            <IonCardContent>
              <IonLabel>
                <p>👋 Please feel free to share your thoughts!</p>
              </IonLabel>
              <IonInput
                placeholder={"Tell us anything!"}
                type={"text"}
                required={true}
                minlength={10}
                maxlength={5000}
                counter={true}
                value={formContent}
                onIonInput={(e) => {
                  if (e.detail.value!.length >= 10) {
                    setSubmitDisabled(false);
                    setFormContent(e.detail.value!);
                  } else {
                    setSubmitDisabled(true);
                  }
                }}
              />
              <IonCheckbox value={optIn}
                           onIonChange={(e) => setOptIn(e.target.value)}
                           style={{paddingTop: "15px", fontSize: "smaller"}}
                           labelPlacement={"end"}>Opt-in for Follow-up</IonCheckbox>
              <br/>
              <IonLabel style={{fontSize: "small", paddingTop: "5px"}}>We'll make our best effort to respond, but we can't guarantee a
                follow-up.</IonLabel>
              <br/>
              <IonButton disabled={submitDisabled} style={{paddingTop: "25px"}}
                         onClick={handleSubmit}>Submit</IonButton>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonToast
        color={feedbackToast.color}
        isOpen={feedbackToast.isOpen}
        message={feedbackToast.message}
        onDidDismiss={() => setFeedbackToast({isOpen: false, message: "", color: ""})}
        duration={3000}
      ></IonToast>
    </IonPage>
  );
}

export default UserFeedback;