import React, { useEffect, useRef, useState } from "react";

import "./Card.css";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonLabel,
  IonPopover
} from "@ionic/react";
import { Handle, NodeToolbar, Position, useReactFlow } from "reactflow";
import { useGetMedia } from "../../../hooks/MediaHooks";
import ReactPlayer from "react-player/lazy";
import { colorPaletteOutline, textOutline, trashOutline } from "ionicons/icons";
import { useParams } from "react-router-dom";
import useFlowActions from "../../../hooks/FlowActionHooks";
import { useGetUserFlows } from "../../../hooks/FlowHooks";
import { FlowNode } from "../../../types/FlowTypes";
import { ColorPicker } from "../../ColorPicker";
import { PRESET_COLORS } from "../../../configs/Constants";
import { createYouTubeVideoStartTime, extractYouTubeVideoId, formatVideoDuration } from "../../../utils/YouTubeUtils";
import { useUpdateCard } from "../../../hooks/FlowCardHooks";
import { useAuthContext } from "../../../providers/AuthProvider";

interface YouTubeFields {
  id: string;
  data: {
    url: string;
    thumbnailUrl: string;
    description: string;
    toolbarVisible: boolean;
    toolbarPosition: Position;
    color?: string;
    videoOffset?: number;
    label?: string;
  };
  selected: boolean;
}

const YouTubeCard = ({id, data, selected}: YouTubeFields) => {
  const {authUser} = useAuthContext();

  const {flowId} = useParams<{ flowId: string; }>();
  const mediaId = id.split("-")[0];

  const {removeNode} = useFlowActions();
  const {getNode} = useReactFlow();

  const {data: ytData} = useGetMedia(mediaId);

  const {data: userFlowsData, isLoading: userFlowsDataIsLoading, isError: userFlowsDataIsError} = useGetUserFlows(authUser?.$id);
  const [deleteConfirmationAlertOpen, setDeleteConfirmationAlertOpen] = useState<boolean>(false);

  const {updateNodeColor, updateNodeLabel} = useUpdateCard();

  // Color Input Popover
  const colorPickerPopover = useRef<HTMLIonPopoverElement>(null);
  const [colorPickerPopoverOpen, setColorPickerPopoverOpen] = useState(false);
  const [color, setColor] = useState<string>("");

  // Label Input Alert
  const [labelInputAlertOpen, setLabelInputAlertOpen] = React.useState<boolean>(false);
  const [customLabel, setCustomLabel] = React.useState<string>("");

  const updateLabel = (labelValue: string) => {
    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];
      const nodeToUpdate = fn.find((node) => node.targetId === `${id}`);

      if (nodeToUpdate) {
        data.label = labelValue;
        setCustomLabel(labelValue);
        setLabelInputAlertOpen(false);

        updateNodeLabel(nodeToUpdate, flowId, labelValue);
      }
    }
  }

  const onDelete = () => {
    const nodeToDelete = getNode(id);
    if (nodeToDelete && flowId) {
      removeNode(nodeToDelete, flowId);
    }
  }

  const openPopover = (e: any) => {
    colorPickerPopover.current!.event = e;
    setColorPickerPopoverOpen(true);
  };

  useEffect(() => {
    if (data.color) {
      setColor(data.color);
    } else {
      setColor("#F5F5F5")
    }

    if (data.label) {
      setCustomLabel(data.label);
    } else {
      setCustomLabel("");
    }
  }, [data.label, data.color, id]);

  const updateColor = (selectedColor: string) => {
    if (selected && !userFlowsDataIsLoading && !userFlowsDataIsError) {
      const fn: FlowNode[] = userFlowsData?.find((flow) => flow.$id === flowId)?.nodes?.map((node) => JSON.parse(node)) as FlowNode[];
      const nodeToUpdate = fn.find((node) => node.targetId === `${id}`);

      if (nodeToUpdate) {
        data.color = selectedColor;
        setColor(selectedColor);
        setColorPickerPopoverOpen(false);

        updateNodeColor(nodeToUpdate, flowId, selectedColor);
      }
    }
  };

  const getUrlWithTimestamp = () => {
    if (data.videoOffset && ytData?.url) {
      const videoId = extractYouTubeVideoId(ytData?.url)!;
      const displayString = formatVideoDuration(data.videoOffset);
      return createYouTubeVideoStartTime(videoId, displayString)
    } else {
      return ytData?.url;
    }
  }

  return (
    <>
      <Handle type="target" position={Position.Top} style={{opacity: 0}}/>
      <IonCard style={{maxWidth: "640px"}}>
        <IonCardHeader style={{backgroundColor: color, height: "60px"}}>
          {data.videoOffset !== undefined &&
              <IonCardTitle>{formatVideoDuration(data.videoOffset)}</IonCardTitle>
          }
        </IonCardHeader>
        <div style={{display: "flex", justifyContent: "center",}}>
          <ReactPlayer stopOnUnmount={true} url={getUrlWithTimestamp()} controls={true} light={true} muted={true}/>
        </div>
        {customLabel === "" && ytData?.description === "" ? (
          <></>
        ) : customLabel === "" && ytData?.description !== "" ? (
          <IonCardHeader>
            <IonLabel style={{
              fontSize: 36,
              fontWeight: 500,
            }}>
              {ytData?.description}
            </IonLabel>
          </IonCardHeader>
        ) : (
          <IonCardHeader>
            <IonLabel style={{
              fontSize: 36,
              fontWeight: 500,
            }}>{customLabel}</IonLabel>
          </IonCardHeader>
        )}
      </IonCard>
      <IonPopover ref={colorPickerPopover} isOpen={colorPickerPopoverOpen} showBackdrop={false}
                  onDidDismiss={() => setColorPickerPopoverOpen(false)} size={"auto"} style={{"--min-width": "280px"}}>
        <IonContent scrollY={false}>
          <ColorPicker color={color} onChange={updateColor} presetColors={PRESET_COLORS}
                       hideFreeformColorPicker={true}/>
        </IonContent>
      </IonPopover>
      <Handle type="source" position={Position.Bottom} style={{opacity: 0}}/>
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition} style={{marginTop: "-10px"}}>
        <IonCard style={{border: "1px solid #ccc"}}>
          <IonButton size={"small"} fill={"clear"} onClick={() => setDeleteConfirmationAlertOpen(true)}><IonIcon
            icon={trashOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"} onClick={openPopover}><IonIcon
            icon={colorPaletteOutline}
            style={{color: "black"}}/></IonButton>
          <IonButton size={"small"} fill={"clear"} onClick={() => {
            setLabelInputAlertOpen(true);
          }}><IonIcon icon={textOutline}
                      style={{color: "black"}}/></IonButton>
        </IonCard>
      </NodeToolbar>
      <IonAlert
        isOpen={labelInputAlertOpen}
        onDidDismiss={() => setLabelInputAlertOpen(false)}
        header={"Customize This Card"}
        subHeader={"Add a unique description to this YouTube video reference"}
        inputs={[
          {
            name: "cardDescription",
            value: data?.label,
            placeholder: "Card Description",
            max: 150,
          }
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
            },
          },
          {
            text: "OK",
            handler: (data: any) => {
              updateLabel(data.cardDescription);
            },
          }
        ]}
      />
      <IonAlert
        isOpen={deleteConfirmationAlertOpen}
        onDidDismiss={() => setDeleteConfirmationAlertOpen(false)}
        header={"Delete this card?"}
        subHeader={"This action is permanent and will also remove the connections to this card"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
            },
          },
          {
            text: "OK",
            handler: (data: any) => {
              onDelete();
            },
          }
        ]}
      />
    </>
  )
}

export default YouTubeCard;