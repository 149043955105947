import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  add,
  arrowBack,
  create,
  documentText,
  ellipsisHorizontalCircleOutline,
  gitNetwork,
  image,
  videocam
} from 'ionicons/icons';
import {
  useBuildFlowchart,
  useDeleteFlow,
  useSetFlowConnections,
  useSetFlowNodesAndConnections,
  useUpdateFlowMetadata
} from '../hooks/FlowHooks';
import { Note } from '../types/NoteTypes';
import FlowChart from '../components/FlowChart';
import { FlowSelection, NavSource } from '../types/NavEnums';
import { FLOW_RATE_LIMIT_MS } from '../configs/Constants';
import { buildFlowChartEdges, buildFlowChartNodes } from '../helpers/FlowHelpers';
import { Edge, Node, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { FlowConnection, FlowNode, mapEdgeToFlowConnection, mapNodeToFlowNode } from "../types/FlowTypes";
import { DBMedia } from "../types/MediaType";

import "./FlowMain.css";
import ImageSelector from "../components/flowchart/selectors/ImageSelector";
import VideoSelector from "../components/flowchart/selectors/VideoSelector";
import NoteSelector from "../components/flowchart/selectors/NoteSelector";
import FlowchartSelector from "../components/flowchart/selectors/FlowchartSelector";
import TextSelector from "../components/flowchart/selectors/TextSelector";
import { useAuthContext } from "../providers/AuthProvider";

const FlowMain: React.FC = () => {
  const {flowId} = useParams<{
    flowId: string;
  }>();

  const {authUser} = useAuthContext();
  const history = useHistory();

  /* REACT FLOW STATES */
  const nodesState = useNodesState([]);
  const edgesState = useEdgesState([]);
  const [nodes, setNodes] = nodesState;
  const [edges, setEdges] = edgesState;

  // const [edgeState, setEdgeState] = useState<Edge[]>([]); // detect new edges
  // const [nodeState, setNodeState] = useState<Node[]>([]); // tracks nodes so we can diff to check changes
  /* END REACT FLOW STATES */

  /* BOTTOM TOOLBAR STATES */
  const [flowSelection, setFlowSelection] = useState<FlowSelection>(FlowSelection.CANVAS);
  const [selectionTarget, setSelectionTarget] = useState<Node | Edge | null>(null);

  const [flowMutation, setFlowMutation] = useState<number>(0); // increment this to trigger changes
  const [lastMutated, setLastMutated] = useState<Date>(new Date()); // used for rate limiting user actions

  const deleteFlowQuery = useDeleteFlow();
  const settingsModal = useRef<HTMLIonModalElement>(null);

  /* REACT QUERY */
  const {notes, flows, media, allLoaded} = useBuildFlowchart(authUser?.$id);

  let initialReadyState = allLoaded;
  //const lastUpdated = fcd.reduce((a, b) => Math.max(a, b.dataUpdatedAt), -Infinity);

  // const createConnection = useCreateConnection();
  const setFlowNodesAndConnections = useSetFlowNodesAndConnections();
  const setFlowConnections = useSetFlowConnections();

  /* Toolbar Title Edit */
  const [flowName, setFlowName] = useState<string>("");
  const updateFlowMetadataQuery = useUpdateFlowMetadata();

  const [addNoteModal, setAddNoteModal] = useState({isOpen: false});

  const [addResourceModal, setAddResourceModal] = useState({isOpen: false, type: ""});

  const dismissSelectionDrawer = () => {
    setAddResourceModal({isOpen: false, type: ""});
    setUserChange();
  }

  const openSelectionDrawer = (type: string) => {
    setAddResourceModal({isOpen: true, type: type});
  }

  const isRateLimited = () => {
    // 0.5 seconds limit
    const now = new Date();
    const interval = now.getTime() - lastMutated.getTime();
    if (interval < FLOW_RATE_LIMIT_MS) {
      return true;
    } else {
      setLastMutated(now);
      return false;
    }
  };

  const setUserChange = () => {
    const i = flowMutation + 1;
    setFlowMutation(i);
  };

  const addNewEdge = (edge: Edge) => {
    console.debug("addNewEdge", edge);
    edge.data.creationTime = new Date().toISOString();

    const nc: FlowConnection = mapEdgeToFlowConnection(edge);
    const flowConnections: FlowConnection[] = [...edges.map((e: Edge) => mapEdgeToFlowConnection(e)), nc];

    // Prevent duplicate edges from being created
    if (flowConnections.filter((c: FlowConnection) => c.source === nc.source && c.target === nc.target).length > 1) {
      console.warn("Duplicate edge detected, not adding");
      return;
    }

    setFlowConnections.mutate({flowId, flowConnections}, {});
  };

  const storeNodesAndEdges = (onlyNodes: boolean = false, onlyEdges: boolean = false) => {
    console.debug("storeNodesAndEdges - setFlowNodesAndConnections");

    if (isRateLimited()) {
      console.log("Currently rate limited!");
      return;
    }

    let flowConnections: FlowConnection[] | undefined;
    let flowNodes: FlowNode[] | undefined;

    if (!onlyNodes) {
      flowConnections = edges.map((e: Edge) => mapEdgeToFlowConnection(e));
    }

    if (!onlyEdges) {
      flowNodes = nodes.map((n: Node) => mapNodeToFlowNode(n));
    }

    setFlowNodesAndConnections.mutate({flowId, flowNodes, flowConnections}, {
      onSuccess: () => {
      }
    });
  };

  const handleDeleteFlow = () => {
    deleteFlowQuery.mutate(flowId, {
      onSuccess: () => {
        history.replace("/main");
      }
    })
  }

  useEffect(() => {
    if (flowId && allLoaded && flows && notes && media) {
      const flowData = flows.find((f) => f.$id === flowId);

      if (flowData) {
        const flowChartNodes = buildFlowChartNodes(
          (flowData.nodes || []).map((n) => JSON.parse(n) as FlowNode),
          notes as unknown as Note[],
          media as unknown as DBMedia[]
        );

        const flowChartEdges: Edge[] = buildFlowChartEdges(
          (flowData.connectionData || []).map((c) => JSON.parse(c) as FlowConnection)
        );

        setNodes(flowChartNodes);
        setEdges(flowChartEdges);

        // Set flow metadata
        setFlowName(flowData.name);
      }
    }
  }, [initialReadyState, flowMutation, history, flowId]);

  useEffect(() => {
    return () => {
      // TODO: make this only run on leave, not enter
      setFlowMutation(0);
    };
  }, []);

  const handleBack = () => {
    const url = new URL(window.location.href);
    const flowSource = url.searchParams.get("source");

    if (!flowSource) {
      history.replace("/main");
    } else if (flowSource === NavSource.CREATE) {
      history.replace("/tabs/main");
    } else if (flowSource === NavSource.HOME) {
      history.back();
    } else if (flowSource === NavSource.LIBRARY) {
      history.back();
    } else if (flowSource === NavSource.MENU) {
      history.back();
    } else if (flowSource === NavSource.FLOW) {
      history.back();
    } else if (flowSource === NavSource.HOME_CARD) {
      history.back();
    } else if (flowSource === NavSource.SEARCH) {
      history.back();
    } else {
      history.replace("/main");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* TODO: change animation so it looks like a pop */}
            <IonButton
              onClick={() => handleBack()}
            >
              <IonIcon icon={arrowBack}/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <IonInput value={flowName} maxlength={100} type="text" onIonBlur={(e) => {
              const updatedFlowName = e.target.value?.toString()?.trim();
              if (updatedFlowName && updatedFlowName.length > 0 && updatedFlowName !== flowName) {
                setFlowName(updatedFlowName);
                updateFlowMetadataQuery.mutate({flowId, updatedFlowName});
              }
            }}/>
          </IonTitle>
          <IonButtons slot={"end"}>
            <IonButton fill={"clear"} id={"open-settings-modal"}>
              <IonIcon icon={ellipsisHorizontalCircleOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} scrollY={false} scrollX={false}>
        {/*
        {
        <IonButton onClick={() => {
          console.log(rf.getNodes());
          console.log(rf.getEdges());
        }}>DEBUG</IonButton>
        }
        */}
        {initialReadyState ?
          <>
            <div>
              <FlowChart nodesState={nodesState} edgesState={edgesState} persistState={storeNodesAndEdges}
                         addEdgeHandler={addNewEdge} flowState={flowSelection} setFlowSelection={setFlowSelection}
                         selectionTarget={selectionTarget} setSelectionTarget={setSelectionTarget}/>
            </div>
          </>
          :
          <div style={{textAlign: "center", paddingTop: "50px"}}>
            <IonSpinner/>
          </div>
        }
      </IonContent>
      <IonFab slot={"fixed"} vertical={"bottom"} horizontal={"end"}>
        <IonFabButton mode="md">
          <IonIcon icon={add}/>
        </IonFabButton>
        <IonFabList side={"top"}>
          <IonFabButton mode="md" color={"primary"} className="fab-button-desc" data-desc="Quick Text"
                        onClick={() => openSelectionDrawer("text")}>
            <IonIcon icon={create}/>
          </IonFabButton>
          <IonFabButton mode="md" color={"primary"} className="fab-button-desc" data-desc="Image"
                        onClick={() => openSelectionDrawer("image")}>
            <IonIcon icon={image}/>
          </IonFabButton>
          <IonFabButton mode="md" color={"primary"} className="fab-button-desc" data-desc="Video"
                        onClick={() => openSelectionDrawer("video")}>
            <IonIcon icon={videocam}/>
          </IonFabButton>
          <IonFabButton mode="md" color={"primary"} className="fab-button-desc" data-desc="Note"
                        onClick={() => openSelectionDrawer("note")}>
            <IonIcon icon={documentText}/>
          </IonFabButton>
          <IonFabButton mode="md" color={"primary"} className="fab-button-desc" data-desc="Flowchart"
                        onClick={() => openSelectionDrawer("flow")}>
            <IonIcon icon={gitNetwork}/>
          </IonFabButton>
        </IonFabList>
      </IonFab>
      <IonModal
        isOpen={addResourceModal.isOpen}
        onDidDismiss={() => dismissSelectionDrawer()}
        breakpoints={[0, 0.95]}
        initialBreakpoint={0.95}
        draggable={false}
        keepContentsMounted={true}
        handle={false}
      >
        {addResourceModal.type === "image" ?
          <ImageSelector flowId={flowId} dismiss={dismissSelectionDrawer}/> :
          addResourceModal.type === "video" ?
            <VideoSelector flowId={flowId} dismiss={dismissSelectionDrawer}/> :
            addResourceModal.type === "note" ?
              <NoteSelector flowId={flowId} dismiss={dismissSelectionDrawer}/> :
              addResourceModal.type === "flow" ?
                <FlowchartSelector flowId={flowId} dismiss={dismissSelectionDrawer}/> :
                addResourceModal.type === "text" ?
                  <TextSelector flowId={flowId} dismiss={dismissSelectionDrawer}/> :
                  <></>
        }
      </IonModal>
      <IonModal ref={settingsModal} trigger="open-settings-modal" initialBreakpoint={0.5} breakpoints={[0, 0.5]}
                draggable={false} handle={false}>
        <IonContent className={"ion-padding"}>
          <IonRow className={"ion-justify-content-center"}><IonButton color={"danger"}
                                                                      id="present-deletion-confirmation">Delete</IonButton></IonRow>
          <IonAlert
            header="Delete Confirmation"
            subHeader="Permanently delete this flowchart?"
            trigger="present-deletion-confirmation"
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  handleDeleteFlow();
                },
              },
            ]}
          ></IonAlert>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default FlowMain;
