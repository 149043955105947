import {DBMedia} from "./MediaType";

export interface Thumbnail {
  url: string;
  webp_url?: string;
}

export interface ResourceItem {
  id: string;
  title: string;
  type: string;
  subtitle?: string;
  subtype?: string;
  createdAt: string;
  modifiedAt: string;
  thumbnail?: Thumbnail;
  icon?: string;
  metadata?: string; // This will be used for global search
}

function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export const generateImageTitle = (media: DBMedia): string => {
  return `Image_${formatTimestamp(media.$createdAt)}`;
}

// This is temporary until we set up a function to pull the video name
export const generateYoutubeTitle = (media: DBMedia): string => {
  return `YouTube_${formatTimestamp(media.$createdAt)}`;
}