import {
  CheckboxCustomEvent,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar
} from "@ionic/react";
import {useHistory, useParams} from "react-router";
import "./TagCreation.css";
import {useEffect, useState} from "react";
// import AuthContext from "../context/AuthContext";
// import NodeContext, { NodeProviderState } from "../context/NodeContext";
// import { System, Tag, TagColors, TagReference, UntaggedTag, UserLibrary } from "../types/SystemTypes";
import {setColor} from "../utils/TagUtils";
import {add, informationCircle} from "ionicons/icons";
import {GroupedTag, Tag, TagColors, UNTAGGED_TAG} from "../types/TagTypes";
import {useGetTags} from "../hooks/TagHooks";
import {useGetUserNotes} from "../hooks/NotesHooks";
import {useGetUserFlows} from "../hooks/FlowHooks";
import { useAuthContext } from "../providers/AuthProvider";

interface TagCheckbox {
  value: string;
  color: string;
  checked: boolean;
}

const TagCreation = () => {
  const {authUser} = useAuthContext();
  const groupedTags: Map<string, GroupedTag> = useGetTags(authUser?.$id);

  const notesQuery = useGetUserNotes(authUser?.$id);
  const flowsQuery = useGetUserFlows(authUser?.$id);

  const {id} = useParams<{ id: string; }>();
  // conditionally check the path

  const history = useHistory();


  // TODO: id & type in params
  // flows/tags/
  // flows/tags/{id}

  const [allTags, setAllTags] = useState<Array<Tag>>([]);
  const [tagCheckbox, setTagCheckbox] = useState<Array<TagCheckbox>>([]);

  const [showNewTagModal, setShowNewTagModal] = useState<boolean>(false);
  const [newTagName, setNewTagName] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [toastNotification, setToastNotification] = useState({show: false, message: "", color: ""});

  let originalTagCheckbox: Array<TagCheckbox> = [];

  const openDrawer = () => {
    setShowNewTagModal(true);
  };

  const dismissDrawer = () => {
    setNewTagName("");
    setSelectedColor("");
    setShowNewTagModal(false);
  };

  const hideToastNotification = () => {
    setToastNotification({
      show: false,
      message: "",
      color: "",
    });
  };

  useEffect(() => {
    // Set the list of tags that we have globally
    const allTagsData = Array.from(groupedTags.values()).map(v => v.tag);
    setAllTags(allTagsData);


    originalTagCheckbox = allTagsData.filter((t: Tag) => t.name !== UNTAGGED_TAG.name).map((t: Tag) => {
      const tagCheckbox: TagCheckbox = {
        value: t.name,
        color: t.color,
        checked: false// t.name in groupedTagsData,
      };
      return tagCheckbox;
    });

    setTagCheckbox(originalTagCheckbox);
  }, [groupedTags]);

  /* 
  useEffect(() => {
    // eslint-disable-next-line
    //console.log(user.uid);
    //console.log(systemId);

    dataHandler.doGetUserLibrary(user.uid, true).then((userLibrary: UserLibrary) => {
      // Set up the list of tags that we have globally
      let allTagsData: Array<Tag> = [];
      if (userLibrary !== null && userLibrary !== undefined) {
        allTagsData = getTags(userLibrary.systems, true);
        setAllTags(allTagsData);
      }

      // Determine what tags already exist for the given system
      // TODO: this can be entirely functional; the null check can be written with flatMap perhaps?
      const currentSystemData: System = userLibrary.systems.find((s: System) => s.systemId === systemId);
      setCurrentSystem(currentSystemData);

      let groupedTagsData: TagReference = {};
      if (currentSystemData.tags !== null) {
        groupedTagsData = groupTags(currentSystemData.tags);
      }

      originalTagCheckbox = allTagsData.filter((t: Tag) => t.name !== UntaggedTag.name).map((t: Tag) => {
        const tagCheckbox: TagCheckbox = {
          value: t.name,
          color: t.color,
          checked: t.name in groupedTagsData,
        };
        return tagCheckbox;
      });

      setTagCheckbox(originalTagCheckbox);
    });
  }, [user.uid, systemId]);
  */

  const tagExists = (tagName: string) => {
    const existsInSystem = allTags.map((t: Tag) => t.name.toLowerCase()).includes(tagName.toLowerCase());
    const existsLocally = tagCheckbox.map((tcb: TagCheckbox) => tcb.value.toLowerCase()).includes(tagName.toLowerCase());

    return existsInSystem || existsLocally;
  };

  // Add tag to existing list
  const handleAdd = () => {

    let hasError = false;
    let message = "";
    if (newTagName === "") {
      message = "A tag name is required";
      hasError = true;
    } else if (tagExists(newTagName)) {
      message = `A tag of the name "${newTagName}" already exists`;
      hasError = true;
    } else if (newTagName.length > 20) {
      message = `Tag names need to be under 20 characters in length`;
      hasError = true;
    } else if (selectedColor === "") {
      message = "A color needs to be selected";
      hasError = true;
    }

    if (hasError) {
      setToastNotification({
        show: true,
        message: message,
        color: "danger"
      });
    }

    if (!hasError) {
      const newTagCheckbox: TagCheckbox = {
        value: newTagName,
        color: selectedColor,
        checked: true
      };

      setTagCheckbox(tagCheckbox.concat([newTagCheckbox]));
      dismissDrawer();
    }
  };

  // Store tag remotely
  /*
  const handleSubmit = () => {
    // TODO: warn users that not adding a new tag will not persist

    const newTags: Array<Tag> = tagCheckbox.filter((tcb: TagCheckbox) => tcb.checked).map((tcb: TagCheckbox) => {
      return {
        name: tcb.value,
        color: tcb.color,
      };
    });

    const updatedSystem = currentSystem;
    updatedSystem.tags = newTags;

    dataHandler.doUpdateSystem(systemId, updatedSystem).then(() =>
      history.goBack()
    ).finally(() =>
      setCurrentSystem(updatedSystem)
    );
  };
  */

  const setChecked = (e: CheckboxCustomEvent, tagName: string) => {
    // @ts-ignore - THIS IS TEMPORARY WHILE WE GET THIS PAGE WORKING
    tagCheckbox.find((tcb: TagCheckbox) => tcb.value === tagName).checked = e.detail.checked;
  };

  const getColorPickerStyle = (color: string) => {
    const pickerStyle: any = setColor(color);

    // Highlight the selected color
    if (selectedColor === color) {
      pickerStyle["outline"] = "2px dashed gray";
    }

    return pickerStyle;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => {
              history.back();
            }}>Cancel</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={() => console.log("handleSave TODO")}>Save</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={true}>
        <IonCard className="connection-card">
          <IonCardContent>
            <IonList>
              {tagCheckbox.map(({value, checked, color}, i) => (
                <IonItem key={value} lines="none">
                  <IonLabel>
                    <div className="color-box" style={setColor(color)}></div>
                    {value}</IonLabel>
                  <IonCheckbox checked={checked} value={value} onIonChange={(e: any) => {
                    setChecked(e, value);
                  }}></IonCheckbox>
                </IonItem>
              ))}
              <IonItem key="add-new-tag" onClick={() => openDrawer()}>
                <IonIcon icon={add}/><IonLabel>&nbsp;Add New Tag</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
      {/* Create new tags*/}
      <IonModal
        isOpen={showNewTagModal}
        onDidDismiss={() => dismissDrawer()}
        breakpoints={[0, 0.6, 1]}
        initialBreakpoint={0.6}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton onClick={() => handleAdd()}>Add</IonButton>
            </IonButtons>
            <IonButtons slot="secondary">
              <IonButton onClick={() => dismissDrawer()}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="creation-content">
          <IonCard className="creation-card">
            <IonCardContent>
              <IonLabel position="stacked">Tag Name</IonLabel>
              <IonInput
                required
                autocapitalize="on"
                type="text"
                value={newTagName}
                placeholder="Tag Name"
                onIonChange={e => {
                  // @ts-ignore
                  setNewTagName(e.detail.value!);
                }}
                maxlength={20}
              ></IonInput>
            </IonCardContent>
          </IonCard>
          <IonCard className="creation-card">
            <IonCardContent>
              <IonLabel position="stacked">Color</IonLabel>
              <IonList>
                <IonGrid>
                  <IonRow>
                    {TagColors.map((v: string) => (
                      <IonCol size="3" key={v}>
                        <IonItem lines="none" onClick={(e) => setSelectedColor(v)}>
                          <div className="color-box-picker" style={getColorPickerStyle(v)}/>
                        </IonItem>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              </IonList>
            </IonCardContent>
          </IonCard>
          <IonToast
            isOpen={toastNotification.show}
            onDidDismiss={() => hideToastNotification()}
            icon={informationCircle}
            message={toastNotification.message}
            color={toastNotification.color}
            duration={1500}
          />
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default TagCreation;