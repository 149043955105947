import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/globals.css';

import FlowMain from './pages/FlowMain';
import { ReactFlowProvider } from 'reactflow';
import Tabs from './pages/Tabs';
import { QueryClient, QueryClientProvider } from 'react-query';
import NoteDetail from './pages/NoteDetail';
import TagCreation from './pages/TagCreation';
import MediaDetail from "./pages/MediaDetail";
import { AuthProvider, useAuthContext } from "./providers/AuthProvider";
import Login from "./pages/auth/Login";
import Registration from "./pages/auth/Registration";
import Verification from "./pages/auth/Verification";
import PasswordReset from "./pages/auth/PasswordReset";
import Landing from "./pages/landing/Main";

// Sentry Dependencies
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import UserAgreement from "./pages/landing/UserAgreement";
import PrivacyPolicy from "./pages/landing/PrivacyPolicy";

setupIonicReact({
  scrollAssist: false,
});

export const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

if (!window.location.href.includes("8100") && !window.location.href.includes("staging")) {
  Sentry.init({
    dsn:
      "https://71cf68e3ad0807af69e3d77a80351492@o4506482826280960.ingest.sentry.io/4506482828574720",
    release: "grappleflows@" + process.env.npm_package_version,
    integrations: [
      new Integrations.BrowserTracing({
        // @ts-ignore
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsole({ levels: ["warning", "error"] }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Performance Monitoring
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.debug("Running locally, don't initialize Sentry.");
}

const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <IonApp>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false}/> */}
        {/* <AppwriteProvider> */}
        <IonReactRouter>
          <AuthProvider>
            <IonSplitPane contentId="main" when="lg">
              <AppRoutes/>
            </IonSplitPane>
          </AuthProvider>
        </IonReactRouter>
        {/* </AppwriteProvider> */}
      </QueryClientProvider>
    </IonApp>
  );
};

const AppRoutes = () => {
  const {authUser} = useAuthContext();

  // @ts-ignore
  return (
    <IonRouterOutlet id="main">
      <SentryRoute exact path="/home" render={() =>
        <Landing/> //authUser ? <Redirect to={"/tabs"}/> : <Landing/>
      }/>
      <SentryRoute path="/tabs" render={() =>
        authUser ? <Tabs/> : <Redirect to={"/login"}/>
      }/>
      <SentryRoute exact path="/" render={() =>
        authUser ? <Tabs/> : <Redirect to={"/home"}/>
      }/>
      <SentryRoute exact path="/main">
        <Redirect to="/tabs"/>
      </SentryRoute>
      {/*
      <SentryRoute exact path={"/media"}>
        <Media/>
      </SentryRoute>
      */}
      <SentryRoute exact path={"/media/:id"} render={(props) =>
        // @ts-ignore
        authUser ? <MediaDetail {...props}/> : <Redirect to={"/login"}/>
      }/>
      <SentryRoute exact path="/tags/create">
        authUser ? <TagCreation/> : <Redirect to={"/login"}/>
      </SentryRoute>
      <SentryRoute exact path="/notes/create">
        authUser ? <NoteDetail/> : <Redirect to={"/login"}/>
      </SentryRoute>
      <SentryRoute exact path="/notes/:id/tags">
        authUser ? <TagCreation/> : <Redirect to={"/login"}/>
      </SentryRoute>
      <SentryRoute exact path="/tags">
        <Redirect to={"/tabs"}/>
      </SentryRoute>
      <SentryRoute exact path="/search">
        <Redirect to={"/tabs/search"}/>
      </SentryRoute>
      <SentryRoute exact path="/notes/item/:noteId/" render={(props) =>
        // @ts-ignore
        authUser ? <NoteDetail {...props}/> : <Redirect to={"/login"}/>
      }/>
      <SentryRoute exact path="/flows/:id/tags" render={(props) =>
        // @ts-ignore
        authUser ? <TagCreation {...props}/> : <Redirect to={"/login"}/>
      }/>
      <SentryRoute exact path="/s/:flowId">
        <Redirect to="/flows/item/:flowId"/>
      </SentryRoute>
      <SentryRoute exact path="/flows/item/:flowId/" render={(props) =>
        // @ts-ignore
        authUser ? <ReactFlowProvider><FlowMain {...props}/></ReactFlowProvider> : <Redirect to={"/login"}/>
      }/>
      <SentryRoute exact path={"/login"}>
        {/* TODO: use redirect link in the component itself, not in here */}
        {authUser ?
          authUser.emailVerification ?
            <Redirect to={"/tabs"}/>
            : <Redirect to={`/verify?userId=${authUser.$id}`}/>
          : <Login/>
        }
      </SentryRoute>
      <SentryRoute exact path={"/register"}>
        {authUser ?
          authUser.emailVerification ?
            <Redirect to={"/tabs"}/>
            : <Redirect to={`/verify?userId=${authUser.$id}`}/>
          : <Registration/>
        }
      </SentryRoute>
      <SentryRoute exact path={"/verify"}>
        <Verification/>
      </SentryRoute>
      <SentryRoute exact path={"/reset-password"}>
        <PasswordReset/>
      </SentryRoute>
      <SentryRoute exact path={"/user-agreement"}>
        <UserAgreement/>
      </SentryRoute>
      <SentryRoute exact path={"/privacy-policy"}>
        <PrivacyPolicy/>
      </SentryRoute>
    </IonRouterOutlet>
  )
}

export default App;
